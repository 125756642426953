import React from 'react';
import {Alert, HStack, Icon, IconButton, Text, useToast, VStack} from 'native-base';
import {AntDesign} from '@native-base/icons';

const ToastAlert = ({toast, id, status, variant, title, description, isClosable, ...rest}: any) => (
  <Alert maxWidth="100%" alignSelf="center" flexDirection="row" status={status || 'info'} variant={variant} {...rest}>
    <VStack space={1} flexShrink={1} w="100%">
      {title && (
        <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
          <HStack space={2} flexShrink={1} alignItems="center">
            <Alert.Icon />
            <Text fontSize="md" fontWeight="medium" flexShrink={1} color={variant === 'solid' ? 'lightText' : variant !== 'outline' ? 'darkText' : null}>
              {title}
            </Text>
          </HStack>
          {isClosable ? (
            <IconButton
              variant="unstyled"
              icon={<Icon as={AntDesign} name={'close'} size="3" />}
              _icon={{
                color: variant === 'solid' ? 'lightText' : 'darkText',
              }}
              onPress={() => toast.close(id)}
            />
          ) : null}
        </HStack>
      )}
      <Text px="6" color={variant === 'solid' ? 'lightText' : variant !== 'outline' ? 'darkText' : null}>
        {description}
      </Text>
    </VStack>
  </Alert>
);

export enum TOAST_VARIANT {
  SOLID = 'solid',
  SUBTLE = 'subtle',
  LEFT_ACCENT = 'left-accent',
  TOP_ACCENT = 'top-accent',
  OUTLINE = 'outline',
}

export enum TOAST_STATUS {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

type Props = {
  id?: string;
  title: string;
  description: string;
  status?: TOAST_STATUS;
  variant?: TOAST_VARIANT;
  isClosable?: boolean;
  isUnique?: boolean;
};

export function useSharedToast() {
  const toast = useToast();
  return ({id, title, description, status = TOAST_STATUS.INFO, variant = TOAST_VARIANT.SOLID, isClosable = false, isUnique = false}: Props) => {
    if (isUnique) {
      const idToast = id || 'shared-toast';
      if (!toast.isActive(idToast)) {
        toast.show({
          id: idToast,
          render: ({id}: any) => {
            return (
              <ToastAlert id={id} toast={toast} title={title || undefined} description={description || undefined} variant={variant} status={status} isClosable={isClosable} />
            );
          },
        });
      }
    } else {
      toast.show({
        render: ({id}: any) => {
          return (
            <ToastAlert id={id} toast={toast} title={title || undefined} description={description || undefined} variant={variant} status={status} isClosable={isClosable} />
          );
        },
      });
    }
  };
}
