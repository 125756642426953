import {FC, useEffect, useState} from 'react';
import DatePickerReact, {ReactDatePickerProps} from 'react-datepicker';

type PickerProps = ReactDatePickerProps<never, true>;

export type DateRange = {from: Date | null; to: Date | null};
export type OnChangeRange = (range: DateRange | null) => void;

type BaseProps = {
  range?: DateRange;
  onChange?: OnChangeRange;
};

export type RangeProps = Omit<PickerProps, 'selectsRange' | 'onChange'> & BaseProps;

const DatePickerRange: FC<RangeProps> = ({range: inputRange, onChange, ...props}) => {
  const [dateRange, setDateRange] = useState<DateRange | null>(inputRange || {from: new Date(), to: new Date()});

  const changeRange = (range: [Date | null, Date | null]) => {
    const [from, to] = range;
    const newRange = from || to ? {from, to} : null;

    setDateRange({from: range[0], to: range[1]});
    onChange && onChange(newRange);
  };

  useEffect(() => setDateRange(inputRange as DateRange | null), [inputRange]);

  return <DatePickerReact {...props} selectsRange={true} startDate={dateRange?.from} endDate={dateRange?.to} onChange={range => changeRange(range)} />;
};

export default DatePickerRange;
