import {FC} from 'react';
import {QrReader} from 'react-qr-reader';
import {StyleProp, ViewStyle} from 'react-native';

type Props = {
  style?: StyleProp<ViewStyle>;
  interval?: number;
  onQrScanned?: (qr: string) => void;
  camera?: 'user' | 'environment';
};

export const QrScanner: FC<Props> = props => {
  return (
    <QrReader
      constraints={{
        facingMode: props.camera || 'environment',
      }}
      scanDelay={props.interval || 1000}
      onResult={(result, error) => {
        if (result && result.getText()) {
          if (props.onQrScanned) {
            props.onQrScanned(result.getText());
          }
        }
      }}
      videoContainerStyle={{height: '100%', paddingTop: 0}}
      videoStyle={{height: '100%', objectFit: 'cover'}}
      containerStyle={props.style}
    />
  );
};
