import React, {FC, useEffect, useState} from 'react';
import {Button, FormControl, Heading, Icon, Input, VStack} from 'native-base';
import {FontAwesome} from '@native-base/icons';
import {useUserService} from 'services';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {IUserBase} from '@bri/shared-core';
import {USER_ROLE} from '../../constants';
import {useSharedToast} from '../../components';

type values = {
  userParam: string;
  icon: string;
  validation: any;
  isOptional?: boolean;
};

type Props = {
  userId?: string;
  fields: values[];
  editHandler?: () => void;
};

function removeEmptyOptionalProperties<T>(obj: T extends unknown ? any : any): T {
  const result = {} as T;
  Object.keys(obj).forEach(prop => {
    const key = prop as keyof T; // Address first problem

    if (obj[prop] || obj[prop] === false || obj[prop] === 0 || obj[prop] === Array.isArray) {
      result[key] = obj[prop] as T[typeof key]; // Address second problem
    }
  });
  return {...result};
}

export const FormUser: FC<Props> = props => {
  const {t} = useTranslation();
  const userService = useUserService();
  const [user, setUser] = useState<IUserBase | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [inputType, setInputType] = useState<'password' | 'text' | undefined>('password');
  const [passwordIcon, setPasswordIcon] = useState('eye-slash');
  const sharedToast = useSharedToast();
  useEffect(() => {
    if (props.userId) {
      userService
        .get({id: props.userId!})
        .response(response => {
          setUser(response.user);
          setIsEdit(true);
        })
        .error(console.error);
    }
  }, []);

  const objSchema: any = [];
  props.fields.forEach(item => {
    (objSchema as any)[item.userParam] = item.validation;
  });
  const LoginSchema = Yup.object().shape({...objSchema});

  const submitForm = (values: any) => {
    // modify value object, for matching the IUser of shared-core
    const password = values.password;
    const username = values.username;
    delete values.password;
    delete values.username;
    values = removeEmptyOptionalProperties(values);
    let objUser: IUserBase = {...values, local: {password, username}};

    if (user) {
      if (objUser.local.password === '') {
        objUser = removeEmptyOptionalProperties(objUser);
      }
      userService
        .edit({...objUser, id: user._id, role: USER_ROLE.USER})
        .response(response => {
          console.log('Finish Editing');
          console.log(response);
        })
        .error(error => {
          console.error(error);
          sharedToast({
            title: t('Validation error'),
            description: t('Something went wrong trying to edit the user'),
          });
        });
    } else {
      userService
        .create({...objUser, role: USER_ROLE.USER, active: true})
        .response(response => {
          console.log('Finish Create');
          console.log(response);
        })
        .error(error => {
          console.error(error);
          sharedToast({
            title: t('Validation error'),
            description: t('Something went wrong trying to create a new user'),
          });
        });
    }
    props.editHandler?.();
  };

  if (props.userId && !user) return null;

  let formTitle = t('Create User');
  let formSubmitText = t('Create');
  if (props.userId) {
    formTitle = t('Edit User');
    formSubmitText = t('Edit');
  }

  const initialValues: {[key: string]: any} = {};
  props.fields.forEach(field => {
    if (user) {
      for (const param in user) {
        if (param !== 'local' && param === field.userParam) {
          initialValues[param] = (user as any)[param];
        } else if (param === 'local') {
          initialValues.username = user.local.username;
          initialValues.password = '';
        }
      }
    } else {
      initialValues[field.userParam] = '';
    }

    if (initialValues[field.userParam] === '' || initialValues[field.userParam] === undefined) {
      initialValues[field.userParam] = '';
    }
  });

  const handlePassword = () => {
    if (inputType === 'text') {
      setInputType('password');
      setPasswordIcon('eye');
    } else {
      setInputType('text');
      setPasswordIcon('eye-slash');
    }
  };

  return (
    <VStack space={4} alignItems="center">
      <Heading size="lg">{formTitle}</Heading>
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          submitForm(values);
        }}
        validationSchema={LoginSchema}>
        {({handleBlur, handleChange, handleSubmit, values, errors}) => (
          <>
            {props.fields.map(field => {
              return (
                <FormControl isRequired={!field.isOptional} isInvalid={field.userParam in errors} key={field.userParam}>
                  <FormControl.Label _text={{bold: true}}>{t(field.userParam)}</FormControl.Label>
                  <Input
                    InputLeftElement={<Icon as={FontAwesome} name={field.icon} size={5} ml="2" color="muted.400" />}
                    InputRightElement={
                      field.userParam === 'password' ? (
                        <Icon
                          as={FontAwesome}
                          name={field.userParam === 'password' ? passwordIcon : ''}
                          size={5}
                          ml="2"
                          color="muted.400"
                          onPress={() => {
                            handlePassword();
                          }}
                        />
                      ) : undefined
                    }
                    placeholder={t(field.userParam) as string}
                    onBlur={handleBlur(field.userParam)}
                    onChangeText={handleChange(field.userParam)}
                    value={values[field.userParam]}
                    type={field.userParam === 'password' ? inputType : 'text'}
                  />
                  {field.userParam in errors && <FormControl.ErrorMessage>{errors[field.userParam as any]}</FormControl.ErrorMessage>}
                </FormControl>
              );
            })}
            <Button onPress={() => handleSubmit()} style={{marginTop: 20}}>
              {formSubmitText}
            </Button>
          </>
        )}
      </Formik>
    </VStack>
  );
};
