export enum ICertificationStatus {
  PENDING_VALIDATION = 'PENDING_VALIDATION',
  VALID = 'VALID',
}

export type ICertification = {
  hash: string;
  status: ICertificationStatus;
  root: string;
  blockchain: {
    transactionHash: string;
    address: string;
  };
};
