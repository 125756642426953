export enum PROCESS_IDENTIFICATION_TYPE {
  D0101 = 'Depósito sobre el suelo',
  D0102 = 'Depósito en el interior del suelo',
  D0201 = 'Tratamiento en medio terrestre',
  D0301 = 'Inyección en profundidad',
  D0401 = 'Embalse superficial',
  D0501 = 'Depósito en vertederos de residuos inertes',
  D0502 = 'Depósito en vertederos de residuos no peligrosos',
  D0503 = 'Depósito en vertederos de residuos peligrosos',
  D0601 = 'Vertido en medio acuático salvo en el mar',
  D0701 = 'Vertido en el mar, incluida la inserción en el lecho marino',
  D0801 = 'Tratamiento Biológico aerobio',
  D0802 = 'Tratamiento Biológico anaerobio',
  D0803 = 'Tratamiento Biológico de suelos contaminados excavados, de lodos u otros residuos biodegradables',
  D0901 = 'Tratamiento físico-químico de residuos líquidos, sólidos y pastosos por filtración, cribado, coagulación/floculación, oxidación/reducción, precipitación, decantación/centrifugación, neutralización, destilación, extracción',
  D0902 = 'Inmovilización (incluyendo la estabilización fisicoquímica y la solidificación)',
  D0903 = 'Esterilización',
  D0904 = 'Evaporación',
  D0905 = 'Secado térmico',
  D0906 = 'Desorción térmica',
  D0907 = 'Otros tratamientos fisicoquímicos distintos de los especificados en las operaciones numeradas D0901 a D0906',
  D1001 = 'Incineración en tierra',
  D1201 = 'Almacenamiento permanente',
  D1301 = 'Clasificación de residuos',
  D1302 = 'Separación de los distintos componentes de los residuos, incluida la retirada de sustancias peligrosas',

  D1303 = 'Tratamiento mecánico (trituración, fragmentación, corte, compactación, etc.)',
  D1304 = 'Peletización',
  D1305 = 'Otros tratamientos de combinación o mezcla distintos de los anteriores',
  D1401 = 'Reenvasado de residuos previo a su eliminación mediante cualquiera de las operaciones numeradas D1 a D13',
  D1501 = 'Almacenamiento, en el ámbito de la recogida',
  D1502 = 'Almacenamiento, en el ámbito del tratamiento',
  R0101 = 'Utilización principal como combustible en instalaciones de incineración de residuos (combustión)',
  R0102 = 'Utilización principal como combustible en instalaciones de gasificación, pirolisis, plasma, y otras tecnologías similares',
  R0103 = 'Utilización principal como combustible en instalaciones de co-incineración: cementeras',
  R0104 = 'Utilización principal como combustible en instalaciones de co-incineración: combustión',
  R0105 = 'Utilización principal como combustible en otras instalaciones de co-incineración',
  R0201 = 'Recuperación o regeneración de disolventes',
  R0202 = 'Regeneración de disolventes (Ej. por destilación,...)',
  R0301 = 'Compostaje',
  R0302 = 'Digestión anaerobia',
  R0303 = 'Valorización de aceites de cocina usados, grasas animales y otros aceites vegetales para la producción de biocarburantes',
  R0304 = 'Reciclado de residuos de papel para la producción de pasta para la fabricación de papel',
  R0305 = 'Reciclado de residuos orgánicos en la fabricación de nuevos productos',
  R0306 = 'Reciclado de residuos orgánicos mediante gasificación, pirólisis, y otras tecnologías similares, siempre que los compuestos obtenidos se utilicen como elementos químicos en un proceso posterior de producción de nuevas sustancias(2). No se incluye la obtención de combustibles.',
  R0307 = 'Reciclado de residuos orgánicos para la producción de materiales o sustancias',
  R0308 = 'Valorización de residuos orgánicos para la obtención de fracciones combustibles en operaciones diferentes al código R0303',
  R0309 = 'Preparación para la reutilización de sustancias orgánicas',
  R0310 = 'Recuperación de sustancias orgánicas contenidas en los residuos mediante tratamientos diferentes a los anteriores',
  R0401 = 'Reciclado de chatarra y residuos metálicos en hornos de fundición',

  R0402 = 'Recuperación de metales a partir de residuos que contengan metales',
  R0403 = 'Reciclado de residuos metálicos para la obtención de chatarra',
  R0404 = 'Preparación para la reutilización de residuos de metales y compuestos metálicos',
  R0405 = 'Recuperación de metales a partir de catalizadores usados',
  R0406 = 'Recuperación de metales a partir de otros residuos que contengan metales',
  R0501 = 'Reciclado de ácidos o bases para la obtención de otras sustancias químicas que se utilicen posteriormente en otros procesos',
  R0502 = 'Descontaminación de suelos excavados que dé como resultado la valorización del suelo',
  R0503 = 'Reciclado de residuos de vidrio (calcín) para la fabricación de vidrio u otros productos',
  R0504 = 'Reciclado de residuos de vidrio para la producción de calcín',
  R0505 = 'Reciclado de residuos inorgánicos en sustitución de materias primas para la fabricación de cemento',
  R0506 = 'Valorización de residuos inorgánicos para la producción de áridos',
  R0507 = 'Reciclado de residuos inorgánicos en sustitución de materias primas en otros procesos de fabricación',
  R0508 = 'Valorización de materiales inorgánicos en operaciones de relleno (backfilling)',
  R0509 = 'Valorización de materiales inorgánicos en operaciones distintas a las de relleno',
  R0510 = 'Recuperación de sustancias inorgánicas contenidas en los residuos mediante operaciones diferentes a las anteriores',
  R0511 = 'Preparación para la reutilización de residuos inorgánicos',
  R0601 = 'Regeneración de ácidos o bases',
  R0701 = 'Regeneración de carbón activo',
  R0702 = 'Regeneración de resinas de intercambio iónico',
  R0703 = 'Regeneración de otros componentes utilizados para reducir la contaminación',
  R0801 = 'Valorización de componentes procedentes de catalizadores',
  R0901 = 'Regeneración de aceites usados para la obtención de aceites base lubricantes',
  R0902 = 'Reciclado de aceite usado para otros usos',
  R0903 = 'Valorización de aceites industriales usados para la obtención de fracciones combustibles',
  R1001 = 'Valorización de residuos en suelos agrícolas y en jardinería',
  R1002 = 'Valorización de residuos para restauración de suelos degradados',

  R1101 = 'Utilización de residuos obtenidos a partir de cualquiera de las operaciones numeradas de R1 a R10',
  R1201 = 'Clasificación de residuos',
  R1202 = 'Desmontaje y separación de los distintos componentes de los residuos, incluida la retirada de sustancias peligrosas',
  R1203 = 'Tratamiento mecánico (trituración, fragmentación, corte, compactación, etc.)',
  R1204 = 'Mezclas para obtener una materia homogénea y estable de residuos para su valorización posterior',
  R1205 = 'Combinación de residuos líquidos con residuos líquidos o residuos sólidos',
  R1206 = 'Reenvasado, para agrupar los residuos en envases adecuados para preparar los residuos para tratamientos posteriores',
  R1207 = 'Secado, desorción térmica y evaporación previo a la valorización del residuo',
  R1208 = 'Acondicionamiento de residuos para la obtención de fracciones combustibles',
  R1209 = 'Acondicionamiento fisicoquímico de residuos para la valorización de sus componentes',
  R1210 = 'Esterilización, pasteurización, higienización',
  R1211 = 'Estabilización biológica aerobia',
  R1212 = 'Estabilización biológica anaerobia',
  R1213 = 'Peletización',
  R1214 = 'Esterilización, pasteurización',
  R1301 = 'Almacenamiento de residuos, en el ámbito de la recogida',
  R1302 = 'Almacenamiento de residuos, en el ámbito de tratamiento',
  R1401 = 'Preparación para la reutilización',
  R1501 = 'Relleno',
}
