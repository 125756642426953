import {IUserBase} from '@bri/shared-core';

export enum USER_ROLE {
  NO = -1,
  USER = 2,
  ADMIN = 10,
}

export enum USER_TYPE {
  SCHOLAR = 'scholar',
  VISITOR = 'visitor',
}

export type IUser = Omit<IUserBase, 'role'> & {role: USER_ROLE};
