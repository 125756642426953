import {HStack, Input, VStack, Text, Button, ScrollView, IButtonProps} from 'native-base';
import {ComponentProps, FC, useEffect, useState} from 'react';

export type MultilanguageTab = {
  tabProps?: Partial<IButtonProps>;
  tabHoverProps?: Partial<IButtonProps>;
  textProps?: ComponentProps<typeof Text>;
};

type Props = {
  languages: {
    codeName: string;
    displayName?: string;
    tab?: MultilanguageTab; // Overrides 'tab' prop
    tabSelected?: MultilanguageTab; // Overrides 'tabSelected' prop
  }[];
  tab?: MultilanguageTab;
  tabSelected?: MultilanguageTab;
  tabBar?: {
    paddingLeft?: any;
    paddingRight?: any;
    align?: 'flex-start' | 'flex-end' | 'center' | undefined;
  };
  setSelectedTab?: number;

  // Usage from parent: setGetValuesMethod={(newMethod: any) => {getTexts = newMethod}} (where "getTexts" is the parent function called to retrieve the texts)
  setGetTextsMethod?: any;
  onChange?: any;
  value?: {[key: string]: string};
  inputProps?: Omit<ComponentProps<typeof Input>, 'value' | 'onChangeText'>;
  textsAsArray?: boolean; // When true, setGetTextsMethod and onChange return texts as array ["hola", "hello"] instead of object {es: "hola", en: "hello"}
};

export const MultilanguageField: FC<Props> = props => {
  const [textsInitialized, setTextsInitialized] = useState(false);
  const [texts, setTexts] = useState<string[]>([]);
  const [fieldText, setFieldText] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);

  // Return ex: ["hola", "hello"]
  function getTextsArray() {
    if (textsInitialized) {
      const newTexts = texts;
      newTexts[selectedTab] = fieldText;
      return newTexts;
    }
    return texts;
  }

  function onTabPress(index: number) {
    setTexts(getTextsArray());
    setSelectedTab(index);
    setFieldText(texts[index]);
  }

  // Return ex: {es: "hola", en: "hello"}
  function getTextsObject() {
    const currentTexts: string[] = getTextsArray();
    const returnTexts: {[key: string]: string} = {};
    props.languages.forEach((lang, index) => {
      if (currentTexts && currentTexts.length > 0 && currentTexts.length >= index && currentTexts[index] && currentTexts[index] !== '') {
        returnTexts[lang.codeName] = currentTexts[index];
      }
    });
    return returnTexts;
  }

  useEffect(() => {
    if (!textsInitialized) {
      const newTexts = props.languages.map(language => {
        return props.value && Object.hasOwn(props.value, language.codeName) ? props.value[language.codeName] : '';
      });

      if (props.onChange) {
        props.onChange(props.textsAsArray ? getTextsArray() : getTextsObject());
      }
      if (props.setGetTextsMethod) {
        props.setGetTextsMethod(props.textsAsArray ? getTextsArray : getTextsObject);
      }
      const newSelectedTab = props.setSelectedTab ?? 0;
      if (props.setSelectedTab) {
        setSelectedTab(newSelectedTab);
      }
      setTexts(newTexts);
      setFieldText(newTexts[newSelectedTab]);
      setTextsInitialized(true);
    }
  }, []);

  // Change texts values by props
  useEffect(() => {
    if (textsInitialized) {
      if (props.value) {
        const newTexts = props.languages.map(language => {
          return props.value && Object.hasOwn(props.value, language.codeName) ? props.value[language.codeName] : '';
        });
        setTexts(newTexts);
        setFieldText(newTexts[selectedTab]);
      }
    }
  }, [props.value]);

  // Change selected tab by props
  useEffect(() => {
    if (props.setSelectedTab) {
      onTabPress(props.setSelectedTab);
    }
  }, [props.setSelectedTab]);

  useEffect(() => {
    if (textsInitialized) {
      if (props.setGetTextsMethod) {
        props.setGetTextsMethod(props.textsAsArray ? getTextsArray : getTextsObject);
      }
      if (props.onChange) {
        props.onChange(props.textsAsArray ? getTextsArray() : getTextsObject());
      }
    }
  }, [fieldText]);

  return (
    <>
      <VStack>
        <ScrollView horizontal contentContainerStyle={{flexGrow: 1, justifyContent: props.tabBar?.align}}>
          <HStack pl={props.tabBar?.paddingLeft || 0} pr={props.tabBar?.paddingRight || 0} alignItems={'end'}>
            {props.languages.map((lang, index) => {
              return (
                <Button
                  overflow={'hidden'}
                  rounded={8}
                  roundedBottom={0}
                  borderWidth={'1px'}
                  borderBottomWidth={0}
                  borderColor={'primary.700'}
                  h={9}
                  key={index}
                  bgColor={selectedTab === index ? 'primary.300' : 'primary.100'}
                  {...(selectedTab === index ? props.tabSelected?.tabProps : props.tab?.tabProps)}
                  {...(selectedTab === index ? lang.tabSelected?.tabProps : lang.tab?.tabProps)}
                  _hover={{
                    bgColor: selectedTab === index ? 'primary.300' : 'primary.200',
                    ...(selectedTab === index ? props.tabSelected?.tabHoverProps : props.tab?.tabHoverProps),
                    ...(selectedTab === index ? lang.tabSelected?.tabHoverProps : lang.tab?.tabHoverProps),
                  }}
                  onPress={() => onTabPress(index)}>
                  <Text
                    {...(selectedTab === index ? lang.tabSelected?.textProps ?? props.tabSelected?.textProps : lang.tab?.textProps ?? props.tab?.textProps)}
                    overflow={'hidden'}
                    backgroundColor={'amber.100'}>
                    {lang.displayName ? lang.displayName : lang.codeName}
                  </Text>
                </Button>
              );
            })}
          </HStack>
        </ScrollView>

        <Input {...props.inputProps} value={fieldText} onChangeText={text => setFieldText(text)}></Input>
      </VStack>
    </>
  );
};
