export enum COOKIES_TYPES {
  TECHNICAL = 'FUNCTIONAL',
  PERSONALISATION = 'USER_EXPERIENCE',
  ANALYSIS = 'SOCIAL_NETWORK_AND_PROMO',
  ADVERTISING = 'ADVERTISING',
  BEHAVIOURAL_ADVERTISING = 'BEHAVIOURAL_ADVERTISING',
}

export enum SHARED_CLIENT_ERROR_TYPE {
  GENERIC = 'GENERIC',
  NOT_FOUND = 'NOT_FOUND',
}

export enum INTERNAL_EVENTS {
  LOGOUT = 'LOGOUT',
  REDIRECT = 'REDIRECT',
}

export enum USER_ROLE {
  NO = -1,
  USER = 2,
  ADMIN = 10,
}
