import React from 'react';
import {Heading} from 'native-base';
import {useTranslation} from 'react-i18next';

type Props = {};

export function Contact(props: Props) {
  const {t} = useTranslation();

  return (
    <>
      <Heading>Contact</Heading>
    </>
  );
}
