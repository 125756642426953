export enum OrganicClaimAgencyCode {
  BFA = 'Biological Farmers of Australia',
  BIOLAND = 'Bioland',
  DEMETER_BUND = 'Demeter-Bund',
  DEMETER_INTERNATIONAL = 'Demeter International',
  ECOLAND = 'Ecoland',
  ECOVIN = 'Ecovin',
  EPA = 'US Environmental Protection Agency',
  FDA = 'FDA US Food and Drug Agency',
  FSA = 'FSA',
  GAA = 'GAA',
  IFOA = 'International Foundation for Organic Agriculture',
  IFOAM = 'International Federation of Organic Agriculture Movements.',
  NATURLAND = 'Naturland',
  QAI = 'Quality Assurance International',
  SUDITIROL = 'Sudtirol Bioland',
  USDA = 'US Department of Agriculture',
}

export type OrganicClaimDetails = {
  organicClaimAgency: Partial<OrganicClaimAgencyCode>;
  organicPercentClaim: number;
};
