import {LangString} from '../rdf/langString';
import {AdditionalProductClassificationDetails} from './additionalProductClassificationDetails';
import {IProduct} from './iproduct';
import {Organization} from './organization';
import {PaymentMethod} from './paymentMethod';
import {Place} from './place';
import {PriceSpecification} from './priceSpecification';
import {QuantitativeValue} from './quantitativeValue';
import {ReferencedFileDetails} from './referencedFileDetails';
import {WarrantyPromise} from './warrantyPromise';

export enum DiscountTypeCode {
  BOGO = 'BOGO',
  DISCOUNTED_ITEM = 'Discounted Item',
  FREE_GIFT = 'Free Gift',
  FREE_SHIPPING = 'Free Shipping',
  OTHER = 'Other',
  PERCENTAGE_OFF = 'Percentage Off',
}

export enum TradeChannelCode {
  INSTORE_ONLY = 'Instore Only',
  OMNICHANNEL = 'Omni-channel',
  ONLINE_ONLY = 'Online Only',
}

export enum OfferRedemptionTypeCode {
  COUPON_CODE = 'Coupon Code',
  GIFT_CARD = 'Gift Card',
  REBATE = 'Rebate',
  SALE = 'Sale',
  SINGLE_USE_CODE = 'Single Use Code',
}

export type Discount = {
  discountRepeatsPerMultipleMinimum: boolean;
  discountType: Partial<DiscountTypeCode>;
  eligibleTradeChannel: Partial<TradeChannelCode>;
  exactDiscountAmount: Partial<PriceSpecification>;
  exactDiscountPercentage: number;
  exclusionDescription: Partial<LangString>;
  isOnlyAvailableThroughRetailer: boolean;
  isOnlyWithMailingListSignup: boolean;
  isOnlyWithPaymentCard: Partial<PaymentMethod>;
  isOnlyWithRetailerLoyaltyCard: boolean;
  isOnlyWithRetailerPaymentCard: boolean;
  isPromoterExclusiveOffer: boolean;
  maximumDiscountAmount: Partial<PriceSpecification>;
  maximumDiscountPercentage: number;
  maximumQualifyingItems: number;
  maximumQualifyingSpend: Partial<PriceSpecification>;
  minimumDiscountAmount: Partial<PriceSpecification>;
  minimumDiscountPercentage: number;
  minimumQualifyingItems: number;
  minimumQualifyingSpend: Partial<PriceSpecification>;
  offerRedemptionType: Partial<OfferRedemptionTypeCode>;
  offerRestrictionDescription: Partial<LangString>;
  qualifyingBrandName: Partial<LangString>;
  qualifyingGPCs: string;
  qualifyingProductCategoryDescription: Partial<LangString>;
  qualifyingProductClassificationCode: Partial<AdditionalProductClassificationDetails>;
  qualifyingProductGTINs: string;
  qualifyingSubBrandName: Partial<LangString>;
  selectedProductsOnly: boolean;
};

export type Offer = {
  acceptedPaymentMethod: Partial<PaymentMethod>;
  availabilityEnds: Date;
  availabilityStarts: Date;
  availableAtOrFrom: Partial<Place>;
  eligibleQuantity: Partial<QuantitativeValue>;
  eligibleQuantityMaximum: Partial<QuantitativeValue>;
  eligibleQuantityMinimum: Partial<QuantitativeValue>;
  itemOffered: Partial<IProduct>;
  offerDescription: Partial<LangString>;
  offerDiscount: Partial<Discount>;
  offerRedemptionURL: Partial<ReferencedFileDetails>;
  paymentTerms: string;
  priceSpecification: Partial<PriceSpecification>;
  seeker: Partial<Organization>;
  seller: Partial<Organization>;
  warranty: Partial<WarrantyPromise>;
};
