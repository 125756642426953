import React, {FC, useState} from 'react';
import {VStack, Text, Heading, Switch, HStack, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {NOTIFICATION_CATEGORY, NOTIFICATION_CHANNEL} from '@bri/shared-core';

type Props = {
  channels: NOTIFICATION_CHANNEL[];
  categories: NOTIFICATION_CATEGORY[];
  channelsEnabled?: NOTIFICATION_CHANNEL[];
  categoriesEnabled?: NOTIFICATION_CATEGORY[];
  onNotificationsChange?: (notifications: {channels: NOTIFICATION_CHANNEL[]; categories: NOTIFICATION_CATEGORY[]}) => void;
};
export const NotificationSettings: FC<Props> = ({
  channels,
  categories,
  channelsEnabled = NOTIFICATION_CHANNEL,
  categoriesEnabled = NOTIFICATION_CATEGORY,
  onNotificationsChange,
}) => {
  const {t} = useTranslation();
  const createInitialNotificationState = () => {
    const notificationStates: Record<string, boolean> = {};
    for (const channel of Object.values(channelsEnabled)) {
      notificationStates[channel] = channels.includes(channel);
    }
    for (const category of Object.values(categoriesEnabled)) {
      notificationStates[category] = categories.includes(category);
    }
    return notificationStates;
  };
  const [notificationStates, setNotificationStates] = useState<Record<string, boolean>>(createInitialNotificationState());

  const updateNotificationState = (key: string, value: boolean) => {
    setNotificationStates(prevStates => {
      const updatedStates = {...prevStates};
      updatedStates[key] = value;
      if (onNotificationsChange) {
        const updatedChannelsEnabled = Object.values(NOTIFICATION_CHANNEL).filter(channel => updatedStates[channel]);
        const updatedCategoriesEnabled = Object.values(NOTIFICATION_CATEGORY).filter(category => updatedStates[category]);
        onNotificationsChange({
          channels: updatedChannelsEnabled,
          categories: updatedCategoriesEnabled,
        });
      }
      return updatedStates;
    });
  };

  const data = {
    channels: Object.values(channelsEnabled).map(channel => ({
      title: t(channel),
      value: notificationStates[channel] || false,
      onValueChange: async (value: boolean) => {
        updateNotificationState(channel, value);
      },
      message: t('Do you want to receive {{channel}} Notifications?', {channel}),
    })),
    scopes: Object.values(categoriesEnabled).map(category => ({
      title: t(category),
      value: notificationStates[category] || false,
      onValueChange: async (value: boolean) => {
        updateNotificationState(category, value);
      },
      message: t('Do you want to receive {{category}} Notifications?', {category}),
    })),
  };

  return (
    <>
      <VStack mr={2} ml={2}>
        <View mt={7} mb={3}>
          <Heading size="md">{t("Notification's Channels: ")}</Heading>
        </View>
        {data.channels.map(item => (
          <VStack key={item.title} mt={4} ml={4}>
            <Text my={3} textAlign="justify">
              {item.message}
            </Text>
            <HStack alignItems="center" justifyContent="space-between">
              <Heading size="md">{item.title}</Heading>
              <Switch size="md" isChecked={item.value} onValueChange={item.onValueChange} />
            </HStack>
          </VStack>
        ))}
        <View mt={7} mb={3}>
          <Heading size="md">{t("Notification's Categories: ")}</Heading>
        </View>
        {data.scopes.map(item => (
          <VStack key={item.title} mt={4} ml={4}>
            <Text my={3} textAlign="justify">
              {item.message}
            </Text>
            <HStack alignItems="center" justifyContent="space-between">
              <Heading size="md">{item.title}</Heading>
              <Switch size="md" isChecked={item.value} onValueChange={item.onValueChange} />
            </HStack>
          </VStack>
        ))}
      </VStack>
    </>
  );
};
