import {AnyURI} from '../xsd/anyURI';
import {QuantitativeValue} from './quantitativeValue';

export enum ReferencedFileTypeCode {
  DIET_CERTIFICATE = 'Diet Certificate',
  DOCUMENT = 'Document',
  GROUP_CHARACTERISTIC_SHEET = 'Group Characteristics Sheet',
  LOGO = 'Logo',
  MARKETING_INFORMATION = 'Marketing Information',
  OUT_OF_PACKAGE_IMAGE = 'Out of Package Image',
  PLANOGRAM = 'Planogram',
  PRODUCT_LABEL_IMAGE = 'Product Label Image',
  SAFETY_DATA_SHEET = 'Safety Data Sheet',
  SAFETY_SUMMARY_SHEET = 'Safety Summary Sheet',
  TRADE_ITEM_DESCRIPTION = 'Trade Item Description',
  VIDEO = 'Video',
  WARRANTY_INFORMATION = 'Warranty Information',
  WEBSITE = 'Website',
}

export type ReferencedFileDetails = {
  fileLanguageCode: string;
  filePixelHeight: number;
  filePixelWidth: number;
  referencedFileEffectiveEndDateTime: Date;
  referencedFileEffectiveStartDateTime: Date;
  referencedFileSize: Partial<QuantitativeValue>;
  referencedFileType: Partial<ReferencedFileTypeCode>;
  referencedFileURL: Partial<AnyURI>;
};
