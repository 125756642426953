import {FC} from 'react';
import {ISelectProps, Select} from 'native-base';
import {COUNTRIES} from './countries';
import {partition} from 'lodash';

type Props = ISelectProps & {
  firstCountry?: string;
};

export const CountrySelector: FC<Props> = props => {
  let items = COUNTRIES.slice();

  if (props.firstCountry) {
    const [firstCountry, otherCountries] = partition(items, item => item.value === props.firstCountry);

    if (firstCountry?.length) {
      otherCountries.unshift(firstCountry[0]);
      items = otherCountries;
    }
  }

  return (
    <Select {...props}>
      {items.map(item => (
        <Select.Item key={item.value} label={item.title} value={item.value} />
      ))}
    </Select>
  );
};
