let storage: Storage;

export const StorageService = {
  /**
   * Set 'value' by 'key'
   * @param key
   * @param value
   */
  setStorage(storageParam: any) {
    storage = storageParam;
  },

  /**
   * Set 'value' by 'key'
   * @param key
   * @param value
   */
  async setItem(key: string, value: string) {
    await storage.setItem(key, value);
  },

  /**
   * Get 'value' by 'key'
   * @param key
   */
  async getItem(key: string) {
    return storage.getItem(key) || null;
  },

  /**
   * Check if 'key' exists
   * @param key
   */
  async existItem(key: string) {
    const result = await this.getItem(key);
    if (result) {
      return true;
    }
    return false;
  },

  /**
   * Remove 'value' by 'key'
   * @param key
   */
  async removeItem(key: string) {
    if (await this.existItem(key)) {
      await storage.removeItem(key);
    }
  },

  /**
   * Clear
   */
  async clear() {
    await storage.clear();
  },
};
