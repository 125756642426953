import {BaseClientError} from '../BaseClientError';
import {CLIENT_ERROR_CODES, CLIENT_ERROR_TYPES} from '../../constants';

export class CredentialsClientError extends BaseClientError {
  code: number;
  type: string;
  data: any;

  constructor(type: CLIENT_ERROR_TYPES = CLIENT_ERROR_TYPES.CREDENTIALS, message?: string, data?: any) {
    super(message, data);
    this.code = CLIENT_ERROR_CODES.CREDENTIALS;
    this.type = type;
  }
}
