import React from 'react';
import {Button, HStack, Icon, Text} from 'native-base';
import {AntDesign, FontAwesome, Ionicons} from '@native-base/icons';
import {useRecoilState} from 'recoil';
import {WsUsersAtom} from '../modules/recoil/atoms/UsersAtoms';

type Props = {
  name: string;
};

export function TestButton(props: Props) {
  const [value, setWsUsersState] = useRecoilState(WsUsersAtom);

  const test = async () => {
    // await StorageService.setItem('TEST', 'ADRI');
    // authService.login({username: 'adri'}).response(console.log).error(console.error);
    // setWsUsersState(current => {
    //   return {...current, data: [{name: 'Adri'}] as any};
    // });
  };

  return (
    <>
      <Text>Hello {props.name} 3</Text>
      <Button colorScheme="secondary" onPress={test}>
        My Button Library Yalc 4
      </Button>
      <HStack alignItems="center">
        <Icon as={FontAwesome} name="user" color="secondary.600" size={20} />
        <FontAwesome name="twitter" />
        <AntDesign name="addusergroup" />
        <Icon as={Ionicons} name="add" color="primary.600" size={20} />
      </HStack>
    </>
  );
}
