import {useEffect, useRef, useState, useMemo, useCallback} from 'react';
import DataTable, {ConditionalStyles, TableColumn as TableColumnBase} from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import {useTranslation} from 'react-i18next';
import {Button, HStack, Icon, Input, Pressable, Stack, Text, View, useBreakpointValue} from 'native-base';
import {AntDesign, FontAwesome} from '@native-base/icons';
import {ModalDeleteUser} from './ModalDeleteUser';
import {FilterOperator, Filters, SortCriteria, SortDirection} from '@bri/shared-core';
import Collapsible from './Collapsible';
import {isFilterForFields} from 'utils';

export type FilterOptions = {
  fields?: string | string[];
  operator?: FilterOperator;
  caseSensitive?: boolean;
  label?: string;
  placeholder?: string;
};

export type TableColumn<T> = TableColumnBase<T> & {
  filterable?: boolean;
  filterOptions?: FilterOptions;
};

export type DataRowRequiredData = {
  _id: string;
  [key: string]: string | number;
};

type Props<T extends DataRowRequiredData> = {
  columns: TableColumn<T>[];
  data: T[];
  title?: string;
  export: boolean;
  dense: boolean;
  striped: boolean;
  highlightOnHover: boolean;
  showExtensions: boolean;
  selectableRows: boolean;
  expandableRows: boolean;
  pagination?: boolean;
  progressPending?: boolean;
  defaultSortField?: string;
  style?: any;
  className?: string;
  sortServer?: boolean;
  paginationServer?: boolean;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (page: number) => void;
  onSort?: (sort: SortCriteria) => void;
  onFilter?: (filters: Filters) => void;
  totalRows?: number;
  rowsPerPage?: number;
  conditionalRowsStyle?: ConditionalStyles<any>[] | undefined;
  defaultActions: boolean;
  customStyles?: any;
  editHandler?: (id: string) => void;
  deleteHandler?: () => void;
  defaultFilterOperator: FilterOperator;
};

const ExpandedComponent = ({data}: any) => <pre>{JSON.stringify(data, null, 2)}</pre>;

export function CustomDataTable<T extends DataRowRequiredData>(props: Props<T>) {
  const {t} = useTranslation();
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [data, setData] = useState<T[]>(props.data);
  const filtersRef = useRef<Filters>([]);
  const [showModal, setShowModal] = useState(false);
  const [showUser, setUser] = useState('');
  const [showID, setID] = useState('');

  const filterInputWidth: string = useBreakpointValue({base: '100%', md: '50%', lg: '25%'});
  const filterInputsContainerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(t('Are you sure you want to delete: ') + `\r${selectedRows.map(r => Object.values(r)[1])}?`)) {
        setToggleClearRows(!toggledClearRows);
        setData(data.filter(x => !selectedRows.includes(x)));
      }
    };

    return (
      <Button
        key="delete"
        style={{backgroundColor: 'red'}}
        onPress={() => {
          handleDelete();
        }}>
        Delete
      </Button>
    );
  }, [data, selectedRows, toggledClearRows, t]);

  const customColumns = (): TableColumn<T>[] => {
    if (!props.defaultActions) return props.columns;

    const b = Object.assign(props.columns);
    return b.concat({
      name: t('Actions'),
      button: true,
      cell: item => {
        return (
          <>
            <HStack style={{flex: 1}} alignItems="center" justifyContent="space-evenly">
              <Pressable
                onPress={() => {
                  props.editHandler && props.editHandler(item._id);
                }}>
                <Icon as={AntDesign} name="edit" color="secondary.600" size={5} />
              </Pressable>
              <Pressable
                onPress={() => {
                  const objectItem = JSON.parse(JSON.stringify(item));
                  setUser(objectItem.local.username);
                  setID(objectItem._id);
                  setShowModal(true);
                }}>
                <Icon as={AntDesign} name="delete" color="secondary.600" size={5} />
              </Pressable>
            </HStack>
          </>
        );
      },
    } as TableColumn<T>) as TableColumn<T>[];
  };

  const filterableColumns = (): TableColumn<T>[] => props.columns.filter(column => column.filterable);

  // createTheme('test', {
  //   text: {
  //     primary: '#268bd2',
  //     secondary: '#2aa198',
  //     fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  //   },
  // });

  const updateFilter = (column: TableColumn<T>, value: string) => {
    const fields = column.filterOptions!.fields!;
    const operator = column.filterOptions!.operator || props.defaultFilterOperator;
    const newFilters = filtersRef.current.filter(filter => !isFilterForFields(filter.fields, fields));

    newFilters.push({fields, operator, value});
    filtersRef.current = newFilters;
  };

  const clearFilters = () => {
    filtersRef.current = [];
    filterInputsContainerRef.current!.querySelectorAll('input').forEach(input => (input.value = ''));
    props.onFilter && props.onFilter([]);
  };

  const filterInputs = () => {
    const columns = filterableColumns();

    if (!columns.length) {
      return '';
    }

    return (
      <View my={1} alignItems={'start'} w={'100%'}>
        <Collapsible toggler={{label: t('Filters')}}>
          <View>
            <Stack direction={'row'} w={'100%'} flexWrap={'wrap'} ref={filterInputsContainerRef}>
              {columns.map((column, index) => (
                <Stack w={filterInputWidth} p={2} key={index}>
                  <Text>{column.filterOptions?.label || column.name}:</Text>
                  <Input placeholder={column.filterOptions?.placeholder || ''} onChangeText={value => updateFilter(column, value)} />
                </Stack>
              ))}
            </Stack>
            <Stack direction={'row'} alignSelf={'end'} mx={2} mb={2} space={1}>
              <Button onPress={() => props.onFilter && props.onFilter(filtersRef.current)} leftIcon={<Icon as={FontAwesome} name="filter" />}>
                {t('Filter')}
              </Button>
              <Button onPress={clearFilters} leftIcon={<Icon as={FontAwesome} name="eraser" />}>
                {t('Clear')}
              </Button>
            </Stack>
          </View>
        </Collapsible>
      </View>
    );
  };

  const dataTable = (
    <>
      {filterInputs()}
      <DataTable
        columns={customColumns()}
        data={data}
        title={props.title}
        dense={props.dense}
        defaultSortAsc={false}
        defaultSortFieldId={props.defaultSortField}
        progressPending={props.progressPending}
        pagination={props.pagination}
        expandableRowsComponent={ExpandedComponent}
        highlightOnHover={props.highlightOnHover}
        striped={props.striped}
        // progressComponent={
        //   <svg className="splash-spinner ml-0 mb-5" viewBox="0 0 50 50">
        //     <circle className="path text-primary" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
        //   </svg>
        // }
        onChangePage={page => {
          props.onChangePage && props.onChangePage(page);
        }}
        onChangeRowsPerPage={page => {
          props.onChangeRowsPerPage && props.onChangeRowsPerPage(page);
        }}
        onSort={(column, direction) => {
          if (!column.sortField || !props.onSort) {
            return;
          }

          const sort: SortCriteria = {[column.sortField]: direction === 'asc' ? SortDirection.ASC : SortDirection.DESC};
          props.onSort(sort);
        }}
        paginationPerPage={props.rowsPerPage}
        paginationTotalRows={props.totalRows}
        sortServer={props.sortServer}
        paginationServer={props.paginationServer}
        paginationComponentOptions={{
          rowsPerPageText: t('Rows per page')!,
          rangeSeparatorText: t('of')!,
        }}
        noDataComponent={t('There are no records to display')}
        className={props.className}
        conditionalRowStyles={props.conditionalRowsStyle}
        expandableRows={props.expandableRows}
        selectableRows={props.selectableRows}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggledClearRows}
        contextActions={contextActions}
        customStyles={props.customStyles}
        // theme={'test'}
      />
      <ModalDeleteUser
        open={showModal}
        showModalParent={setShowModal}
        username={showUser}
        _id={showID}
        message={t('Are you sure you want to delete?') as string}
        deleteHandler={props.deleteHandler}
      />
    </>
  );

  if (props.showExtensions) {
    return (
      <DataTableExtensions {...{columns: customColumns(), data}} print={false} exportHeaders export={props.export} style={props.style} filter={false}>
        {dataTable}
      </DataTableExtensions>
    );
  }

  return dataTable;
}

CustomDataTable.defaultProps = {
  striped: true,
  highlightOnHover: true,
  dense: false,
  export: false,
  expandableRows: false,
  selectableRows: false,
  defaultActions: true,
  defaultFilterOperator: FilterOperator.CONTAINS,
};
