export enum DANGEROUS_INDICATOR_TYPE {
  HP1 = 'Explosivo',
  HP2 = 'Comburente',
  HP3 = 'Inflamable',
  HP4 = 'Irritante - Irritación cutánea y lesiones oculares',

  HP5 = 'Toxicidad específica en determinados órganos (STOT) - Toxicidad por aspiración',
  HP6 = 'Toxicidad aguda',
  HP7 = 'Carcinógeno',
  HP8 = 'Corrosivo',
  HP9 = 'Infeccioso',
  HP10 = 'Tóxico para la reproducción',
  HP11 = 'Mutágeno',
  HP12 = 'Liberación de un gas de toxicidad aguda',
  HP13 = 'Sensibilizante',
  HP14 = 'Ecotóxico',
  HP15 = 'Residuos que pueden presentar una de las características de peligrosidad antes mencionadas que el residuo original no presentaba directamente',
}
