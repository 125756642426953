import {ApexOptions} from 'apexcharts';
import moment from 'moment';
import {STATISTIC_PERIODIC} from './Graph';

const numberFormat = new Intl.NumberFormat('de-DE', {maximumFractionDigits: 3});

const formatDatetimeLabels = (value: string, period: STATISTIC_PERIODIC) => {
  let result;
  switch (period) {
    case STATISTIC_PERIODIC.WEEK: {
      const today = moment();
      const startDate = moment(value).startOf('isoWeek');
      let endDate = moment(value).endOf('isoWeek');

      if (endDate.isAfter(today)) {
        endDate = today.subtract(1, 'day');
      }
      result = `${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}`;
      break;
    }
    case STATISTIC_PERIODIC.MONTH: {
      const today = moment();
      const startDate = moment(value).startOf('month');
      let endDate = moment(value).endOf('month');

      if (endDate.isAfter(today)) {
        endDate = today.subtract(1, 'day');
        result = `${moment(value).format('MMMM')} (` + `${startDate.format('DD')} - ${endDate.format('DD')}` + `) ${moment(value).format("YY'")}`;
      } else {
        result = moment(value).format("MMMM YY'");
      }
      break;
    }
    case STATISTIC_PERIODIC.YEAR: {
      const today = moment();
      const startDate = moment(value).startOf('year');
      let endDate = moment(value).endOf('year');

      if (endDate.isAfter(today)) {
        endDate = today.subtract(1, 'day');
        result = `${moment(value).format('YYYY')} (` + `${startDate.format('DD MMM')} - ${endDate.format('DD MMM')}` + `)`;
      } else {
        result = moment(value).format('YYYY');
      }
      break;
    }
    default:
      result = moment(value).format('DD MMM');
  }
  return result;
};

export function getChartOption(
  t: any,
  layoutProps: any,
  type: ApexChart['type'],
  chartData: ApexOptions['series'],
  chartLabels: ApexOptions['labels'],
  showAxis: boolean,
  period: STATISTIC_PERIODIC,
  yUnit = '',
  readonly?: boolean,
  exportOption?: boolean,
  title?: string,
  onReady?: () => void,
  stacked = false
) {
  // Si solo hay un valor, pasaremos el gráfico a 'bar' para que se vea mejor
  let calculatedType = type;
  if (chartData && chartData.length === 1 && (chartData as ApexAxisChartSeries)[0].data.length === 1) {
    calculatedType = 'bar';
  }

  // Si no es de tipo 'dia' pasaremos a category para poder gestionar manualment los labels del eje X
  let xAxisType: ApexXAxis['type'] = 'datetime';
  if (period !== STATISTIC_PERIODIC.DAY) {
    xAxisType = 'category';
  }

  const optionsBase: ApexOptions = {
    series: chartData,

    chart: {
      defaultLocale: moment.locale(),
      locales: [
        {
          name: moment.locale(),
          options: {
            months: moment.months(),
            shortMonths: moment.monthsShort(),
            days: moment.weekdays(),
            shortDays: moment.weekdaysShort(),
            toolbar: {
              download: t('Download SVG'),
              selection: t('Selection'),
              selectionZoom: t('Zoom Selection'),
              zoomIn: t('Zoom In'),
              zoomOut: t('Zoom Out'),
              pan: t('Browse'),
              reset: t('Reset Zoom'),
            },
          },
        },
      ],
      stacked,
      type: calculatedType,
      height: layoutProps.height,
      offsetY: onReady ? 0 : 30,
      toolbar: {
        show: !readonly || exportOption,
        tools: {
          download: true,
        },
        export: {
          csv: {
            filename: title || 'data',
            columnDelimiter: ';',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return moment(timestamp).format('DD/MM/YYYY');
            },
          },
        },
      },
      zoom: {
        // type: 'x',
        enabled: !readonly,
        // autoScaleYaxis: true,
      },
      sparkline: {
        enabled: !showAxis,
      },
      events: {
        animationEnd() {
          setTimeout(() => {
            if (onReady) {
              onReady();
            }
          }, 250);
        },
      },
    },
    legend: {
      show: showAxis,
      showForSingleSeries: true,
      position: 'bottom',
      offsetY: 25,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    xaxis: {
      // type: 'datetime',
      type: xAxisType,
      categories: chartLabels,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: showAxis,
        datetimeUTC: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: '12px',
          fontFamily: layoutProps.fontFamily,
        },
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm',
        },
        // period === STATISTIC_PERIODIC.WEEK ?
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        // formatter: undefined,
        offsetY: 30,
        style: {
          fontSize: '12px',
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      decimalsInFloat: 2,
      labels: {
        formatter(value) {
          const formatYAxis = Intl.NumberFormat('de-DE', {maximumFractionDigits: 2});
          return formatYAxis.format(value) + yUnit;
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: layoutProps.fontFamily,
      },
      // y: {
      //   formatter(val: any) {
      //     return val;
      //   },
      // },
    },
    colors: layoutProps.colorsThemeBaseSuccess,
  };

  if (calculatedType === 'area') {
    optionsBase.fill = {
      type: 'solid',
      opacity: 0.35,
    };
    optionsBase.stroke = {
      curve: 'smooth',
      show: true,
      width: 3,
    };
    optionsBase.chart!.stacked = false;
  } else if (calculatedType === 'bar') {
    // optionsBase.plotOptions = {
    //   bar: {
    //     columnWidth: '40%',
    //   },
    // };
  } else if (calculatedType === 'line') {
    optionsBase.stroke = {
      // curve: 'smooth',
      width: 3,
    };
    // optionsBase.dataLabels = {
    //   enabled: true,
    //   background: {
    //     enabled: false,
    //     dropShadow: {enabled: false},
    //   },
    //   style: {
    //     colors: ['black'],
    //     // fontSize: "10px",
    //   },
    //   offsetY: -5,
    //   // enabledOnSeries: [0], // Aqui per aplicar-ho només a una serie en concret
    //   formatter(val, opts) {
    //     // if (opts.seriesIndex === 1) {
    //     //   return `${val}%`;
    //     // }
    //     const formatted = numberFormat.format(val as number);
    //
    //     return formatted;
    //   },
    // };
    optionsBase.plotOptions = {
      bar: {
        dataLabels: {
          position: 'bottom',
        },
      },
    };
    optionsBase.yaxis = [
      {
        labels: {
          formatter(value) {
            const formatted = numberFormat.format(value);
            return `${formatted}%`;
          },
        },
      },
      // {
      //   opposite: true,
      //   labels: {
      //     formatter(value) {
      //       return `${numberFormat.format(value)}%`;
      //     },
      //   },
      // },
    ];
    optionsBase.colors = layoutProps.colorsThemeKPMGLine;
  }

  if (optionsBase.xaxis && optionsBase.xaxis.labels && period !== STATISTIC_PERIODIC.DAY) {
    optionsBase.xaxis.labels.formatter = function (value, timestamp, opts) {
      return formatDatetimeLabels(value, period);
    };
  }

  return optionsBase;
}
