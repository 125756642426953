import {FC} from 'react';
import {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es'; // register it with the name you want
import DatePickerSingle, {SingleProps as SinglePropsBase, OnChangeDate} from './DatePicker/DatePickerSingle';
import DatePickerRange, {RangeProps as RangePropsBase, OnChangeRange} from './DatePicker/DatePickerRange';
import {Input} from 'native-base';
registerLocale('es', es);

type BaseProps = {
  topPlacement?: boolean;
  fullScreen?: boolean;
};

type SingleProps = SinglePropsBase & {
  isRange?: false;
};

type RangeProps = RangePropsBase & {
  isRange: true;
};

type Props = BaseProps & (SingleProps | RangeProps);

export const DatePicker: FC<Props> = ({isRange = false, onChange, topPlacement = false, fullScreen = false, customInput, ...props}) => {
  const pickerProps = {
    ...props,
    locale: props.locale || 'es',
    dateFormat: props.dateFormat || 'dd/MM/yyy',
    popperPlacement: (topPlacement ? 'top' : 'auto') as any,
    calendarStartDay: 1,
    popperProps: {positionFixed: true},
    portalId: 'root',
    customInput: customInput || <Input w={250} />,
    withPortal: fullScreen,
  };

  if (isRange) {
    return <DatePickerRange onChange={onChange as OnChangeRange} {...pickerProps} />;
  }

  return <DatePickerSingle onChange={onChange as OnChangeDate} {...pickerProps} />;
};
