export type PaginatedRequest = {
  page: number;
  pageSize: number;
};

export type PaginatedResponse<T> = {
  results: T[];
  total: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
};

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type SortCriteria = Record<string, SortDirection>;

export type SortedRequest = {
  sort?: SortCriteria;
};

export type SortedPaginatedRequest = PaginatedRequest & SortedRequest;

export enum FilterOperator {
  EQUALS = 'equals',
  NOT_EQUALS = 'not_equals',
  CONTAINS = 'contains',
  GREATER = 'greater',
  LESS = 'less',
  GREATER_OR_EQUAL = 'greater_or_equal',
  LESS_OR_EQUAL = 'less_or_equal',
  EMPTY = 'empty',
  NOT_EMPTY = 'not_empty',
  IN = 'in',
  NOT_IN = 'not_in',
  EXISTS = 'exists',
  NOT_EXISTS = 'not_exists',
}

export type Filter = {
  fields: string[] | string;
  operator: FilterOperator;
  value?: any;
  caseSensitive?: boolean;
};

export type Filters = Filter[];

export type FilteredRequest = {
  filters?: Filters;
};

export type SortedFilteredPaginatedRequest = SortedPaginatedRequest & FilteredRequest;

export type MultilanguageStringGeneric = {[key: string]: string};
