import {useEffect, useState} from 'react';
import {Button, HStack, Modal, Text, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {useUserService} from '../services';

type Props = {
  open: boolean;
  username: string;
  _id: string;
  showModalParent: React.Dispatch<React.SetStateAction<boolean>>;
  message?: string;
  deleteHandler?: () => void;
};

export function ModalDeleteUser(props: Props) {
  const [showUser, setUser] = useState('');
  const [showID, setID] = useState('');
  const userService = useUserService();
  const {t} = useTranslation();

  useEffect(() => {
    setUser(props.username);
    setID(props._id);
  }, [props.username, props._id]);

  const onDeleteUser = (usernameID: string) => {
    if (usernameID) {
      userService
        .remove({id: usernameID})
        .response(response => {
          console.log('Finish Deliting');
          console.log('Response: ', response);
          props.deleteHandler?.();
        })
        .error(console.error);
    } else {
      console.log('User Not Found');
    }
  };

  return (
    <Modal
      isOpen={props.open}
      onClose={() => {
        props.showModalParent(false);
      }}
      size="lg">
      <Modal.Content maxWidth="350">
        <Modal.CloseButton />
        <Modal.Header> {props.message} </Modal.Header>
        <Modal.Body>
          <VStack space={3}>
            <HStack alignItems="center" justifyContent="space-between">
              <Text fontWeight="medium"> {t('User: ')} </Text>
              <Text color="blueGray.400"> {t('{{showUser}}', {showUser})} </Text>
            </HStack>
          </VStack>
        </Modal.Body>
        <Modal.Footer>
          <VStack space={3} justifyContent="space-between">
            <HStack space={3} alignItems="center" justifyContent="space-between">
              <Button
                flex="1"
                onPress={() => {
                  onDeleteUser(showID);
                  props.showModalParent(false);
                }}>
                {t('Yes') as string}
              </Button>

              <Button
                flex="1"
                onPress={() => {
                  props.showModalParent(false);
                }}>
                {t('No') as string}
              </Button>
            </HStack>
          </VStack>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
