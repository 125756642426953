import {QuantitativeValue} from './quantitativeValue';

export type ReturnablePackageDepositDetails = {
  returnablePackageDepositAmount: any;
  returnablePackageDepositRegion: any;
};

export enum PackagingFeatureCode {
  BASE = 'Base',
  BEAM = 'Beam',
  BUNG_SEAL = 'Bung Seal',
  CAP = 'Cap',
  CARRIER = 'Carrier',
  CONSUMPTION_UTENSIL = 'Consumption Utensil',
  CORE = 'Core',
  CREEL = 'Creel',
  EDGE_PROTECTION = 'Edge Protection',
  HANDLE = 'Handle',
  INNER_CONTAINER = 'Inner Container',
  INTERNAL_DIVIDER = 'Internal Divider',
  LABEL = 'Label',
  LID = 'Lid',
  LINER = 'Liner',
  LUG = 'Lug',
  NESTING_EDGE = 'Nesting Edge',
  PEG = 'Peg',
  PULL_OFF_TAB = 'Pull Off Tab',
  RING_HOLDER = 'Ring Holder',
  RIVET = 'Rivet',
  SLEEVE = 'Sleeve',
  SPOUT = 'Spout',
  TAG = 'Tag',
  WICKER_OUTER_CONTAINER = 'Wicker Outer Container',
  WRAP = 'Wrap',
}

export enum PackagingFunctionCode {
  ANTISEPTIC = 'Antiseptic',
  ANTI_TAMPERING = 'Anti-tampering',
  COATED = 'Coated',
  COMPRESSED = 'Compressed',
  DISPENSER = 'Dispenser',
  GIFT_WRAPPED = 'Gift Wrapped',
  ISOTHERMIC = 'Isothermic',
  MODIFIED_ATMOSPHERE = 'Modified Atmosphere',
  OXYGEN_INFUSED = 'Oxygen Infused',
  PEEL_OFF = 'Peel Off',
  PINPACK = 'Pinpack',
  PROTECTED = 'Protected',
  REINFORCED = 'Reinforced',
  SIFT_PROOF = 'Sift Proof',
  TAMPER_EVIDENT = 'Tamper Evident',
  WATER_RESISTANT = 'Water Resistant',
}

export enum PackagingMaterialTypeCode {
  CERAMIC = 'Ceramic',
  CLOTH_OR_FABRIC = 'Cloth of Fabric',
  COMPOSITE = 'Composite',
  CORRUGATED_BOARD_DOUBLE_WALL = 'Corrugated Board Double Wall',
  CORRUGATED_BOARD_OTHER = 'Corrugated Board Other',
  CORRUGATED_BOARD_SINGLE_WALL = 'Corrugated Board Single Wall',
  CORRUGATED_BOARD_TRIPLE_WALL = 'Corrugated Board Triple Wall',
  FIBRE_BURLAP = 'Fibre Burlap',
  FIBRE_COTTON = 'Fibre Cotton',
  FIBRE_FLAX = 'Fibre Flax',
  FIBRE_HEMP = 'Fibre Hemp',
  FIBRE_JUTE = 'Fibre Jute',
  FIBRE_OTHER = 'Fibre Other',
  FOAM = 'Foam',
  GLASS = 'Glass',
  GLASS_COLOURED = 'Glass Coloured',
  LAMINATED_CARTON = 'Laminated Carton',
  METAL_ALUMINUM = 'Metal Aluminum',
  METAL_BRASS = 'Metal Brass',
  METAL_IRON = 'Metal Iron',
  METAL_LEAD = 'Metal Lead',
  METAL_OTHER = 'Metal Other',
  METAL_STAINLESS_STEEL = 'Metal Stainless Steel',
  METAL_STEEL = 'Metal Steel',
  METAL_TIN = 'Metal Tin',
  MINERAL_CALCIUM_CARBONATE = 'Mineral Calcium Carbonate',
  MINERAL_OTHER = 'Mineral Other',
  MINERAL_TALC = 'Mineral Talc',
  NATURAL_RUBBER = 'Natural Rubber',
  OTHER = 'Other',
  PAPER_CORRUGATED = 'Paper Corrugated',
  PAPER_MOLDED_PULP = 'Paper Molded Pulp',
  PAPER_OTHER = 'Paper Other',
  PAPER_PAPER = 'Paper',
  PAPER_PAPERBOARD = 'Paper Paperboard',
  PAPER_RAYON = 'Paper Rayon',
  PLASTIC_BIOPLASTIC = 'Plastic Bioplastic',
  PLASTIC_OTHER = 'Plastic Other',
  PLASTIC_THERMOPLASTICS = 'Plastic Thermoplastics',
  POLYMER_CELLULOSE_ACETATE = 'Polymer Cellulose Acetate',
  POLYMER_EPOXY = 'Polymer Epoxy',
  POLYMER_EVA = 'Polymer Eva',
  POLYMER_EVOH = 'Polymer EVOH',
  POLYMER_HDPE = 'Polymer HDPE',
  POLYMER_LDPE = 'Polymer LDPE',
  POLYMER_LLDPE = 'Polymer LLDPE',
  POLYMER_MDPE = 'Polymer MDPE',
  POLYMER_NYLON = 'Polymer Nylon',
  POLYMER_OTHER = 'Polymer Other',
  POLYMER_PAN = 'Polymer PAN',
  POLYMER_PC = 'Polymer PC',
  POLYMER_PCL = 'Polymer PCL',
  POLYMER_PE = 'Polymer PE',
  POLYMER_PEN = 'Polymer PEN',
  POLYMER_PET = 'Polymer PET',
  POLYMER_PHA = 'Polymer PHA',
  POLYMER_PLA = 'Polymer PLA',
  POLYMER_PP = 'Polymer PP',
  POLYMER_PS = 'Polymer PS',
  POLYMER_PU = 'Polymer PU',
  POLYMER_PVA = 'Polymer PVA',
  POLYMER_PVC = 'Polymer PVC',
  POLYMER_PVDC = 'Polymer PVDC',
  POLYMER_TPS = 'Polymer TPS',
}

export enum PackagingRecyclingProcessTypeCode {
  COMPOSTABLE = 'Compostable',
  ENERGY_RECOVERABLE = 'Energy Recoverable',
  RECYCLABLE = 'Recyclable',
  REUSABLE = 'Reusable',
}

export enum PackagingRecyclingSchemeCode {
  ALKO = 'Alko Inc',
  A_PULLO = 'A Pullo',
  EKO_PULLO = 'EKO Pullo',
  PALPA = 'PALPA',
}

export enum PackagingShapeCode {
  BAR = 'Bar',
  COIL = 'Coil',
  CONE = 'Cone',
  CYLINDRICAL = 'Cylindrical',
  POLYGON = 'Polygon',
  RECTANGULAR = 'Rectangular',
  SPHERICAL = 'Spherical',
  TABLET = 'Tablet',
  UNSPECIFIED = 'Unspecified',
}

export enum PackagingMarkedLabelAccreditationCode {
  AMA_ORGANIC_SEAL = 'Austria Ministry of Agriculture Organic Label',
  AUS_KAUP_ESTONIA = 'Aus Kaup Estonia',
  BDIH_LOGO = 'BDIH Germany guidelines for Certified Natural Cosmetics',
  BETER_LEVEN_1_STER = 'Beter Leven One Star Mark',
  BETER_LEVEN_2_STER = 'Beter Leven Two Star Mark',
  BETER_LEVEN_3_STER = 'Beter Leven Three Star Mark',
  BIO_AUSTRIA_LABEL = 'Bio Austria',
  BIO_LABEL_GERMAN = 'German national organic certification label (Bio-Siegel)',
  BIO_SUISSE_BUD_SEAL = 'Represents interests of Swiss organic farmers',
  BLUE_ANGEL = 'Blue Angel',
  BORD_BIA_APPROVED = 'Bord Bia Approved Logo',
  BORD_BIA_APPROVED_MEAT = 'Bord Bia Approved - Meat Content Only',
  BRA_MILJOVAL_LABEL_SWEDISH = 'Bra Miljoval',
  CROSSED_GRAIN_SYMBOL = 'Crossed grain logo',
  DEMETER_LABEL = 'Demeter International Trademark',
  ECOCERT_CERTIFICATE = 'ECOCERT Certification',
  ECO_LABEL_LADYBUG = 'Eco Label Ladybug',
  EC_NATIONAL_HEALTH_MARK = 'EC National Health Mark',
  EESTI_OKOMARK = 'EESTI OKOMARK (Label of Organic Food)',
  EESTI_PARIM_TOIDUAINE = 'Best Food Association of Estonia Food Industry',
  EKO = 'Skal EKO Label',
  EU_ECO_LABEL = 'European Union Eco Label',
  EU_ORGANIC_FARMING = 'EU Organic Farming Logo',
  FAIR_TRADE_MARK = 'The Fair Trade Mark',
  FALKEN = 'Falken',
  FINNISH_HEART_SYMBOL = 'Finnish Heart Symbol',
  FOREST_STEWARDSHIP_COUNCIL_LABEL = 'Forest Stewardship Council Label',
  GMO_MARKED = 'GMO Marked',
  GOODS_FROM_FINLAND_BLUE_SWAN = 'Finfood Suomen Ruokatieto Ry',
  GREEN_DOT = 'Green Dot',
  GREEN_RESTAURANT_ASSOCIATION_ENDORSED = 'Green Restaurant Association Endorsed symbol',
  GREEN_SEAL_CERTIFIED = 'Green Seal Certified Symbol',
  GUARANTEED_IRISH = 'Guaranteed Irish Symbol',
  KRAV_MARK = 'KRAV Mark',
  LABEL_OF_THE_ALLERGY_AND_ASTHMA_FEDERATION = 'Allergy and Asthma Federation',
  LEAPING_BUNNY = 'Leaping Bunny Program',
  LOODUSSOBRALIK_TOODE_ESTONIA = 'Loodussobralik Toode Estonia',
  LOVE_IRISH_FOOD = 'Love Irish Food',
  MADE_IN_FINLAND_FLAG_WITH_KEY = 'Made in Finland',
  MARINE_STEWARDSHIP_COUNCIL_LABEL = 'Marine Stewardship Council label',
  MAX_HAVELAAR = 'Max Havelaar',
  NATRUE_LABEL = 'NATRUE Label',
  NYCKELHALET = 'Green Key Hole',
  OEKO_TEX_LABEL = 'Confidence in Textiles Oeko-Tex',
  OFFICIAL_ECO_LABEL_SUN = 'Luomo Sun Sign',
  PEFC = 'Programme for Endorsement of Forest Certification',
  PROTECTED_DESIGNATION_OF_ORIGIN = 'Protected Designation of Origin',
  PROTECTED_GEOGRAPHICAL_INDICATION = 'Protected Geographical Indication',
  PROTECTED_HARVEST_CERTIFIED = 'Protected Harvest Certified Symbol',
  QUALITY_MARK_IRELAND = 'Quality Mark Ireland',
  RAINFOREST_ALLIANCE = 'Rainforest Alliance Certified Symbol',
  SCHARRELVLEES = 'Scharrelvlees',
  SOIL_ASSOCIATION_ORGANIC_SYMBOL = 'Soil Association Organic Symbol',
  SUSTAINABLE_PALM_OIL_RSPO = 'Sustainably Produced Palm Oil',
  SVANEN = 'Svanen',
  SWEDISH_SEAL_OF_QUALITY = 'Swedish Seal of Quality Label',
  TRADITIONAL_SPECIALTY_GUARANTEED = 'Traditional Specialty Guaranteed',
  TUNNUSTATUD_EESTI_MAITSE = 'Approved Estonian Taste',
  TUNNUSTATUD_MAITSE = 'Tunnustatud Maitse',
  UNIQUELY_FINNISH = 'Uniquely Finnish',
  USDA = 'US Department of Agriculture',
  UTZ_CERTIFIED = 'UTZ Certified',
  VAELG_FULDKORN_FORST = 'Vaelg Fuldkorn forst',
  WWF_PANDA_LABEL = 'World Wildlife Federation Panda',
}

export type PackagingMaterialDetails = {
  packagingMaterialCompositionQuantity: Partial<QuantitativeValue>;
  packagingMaterialThickness: Partial<QuantitativeValue>;
  packagingMaterialType: Partial<PackagingMaterialTypeCode>;
};

export type PackagingDetails = {
  hasReturnablePackageDeposit: Partial<ReturnablePackageDepositDetails>;
  packagingFeature: Partial<PackagingFeatureCode>;
  packagingFunction: Partial<PackagingFunctionCode>;
  packagingMaterial: Partial<PackagingMaterialDetails>;
  packagingRecyclingProcessType: Partial<PackagingRecyclingProcessTypeCode>;
  packagingRecyclingScheme: Partial<PackagingRecyclingSchemeCode>;
  packagingShape: Partial<PackagingShapeCode>;
  packagingType: string;
};
