import React from 'react';
import {Button, Modal} from 'native-base';
import {useTranslation} from 'react-i18next';

type Props = {
  content: React.ReactNode;
  okButton: {onPress: () => void; label?: string; variant?: string};
  onClose: () => void;
  title?: string;
  cancelButton?: {onPress: () => void; label?: string; variant?: string};
  visible?: boolean;
  closeOnOverlayClick?: boolean;
  closeButton?: boolean;
};

const ConfirmationDialog: React.FC<Props> = ({title, content, okButton = {}, cancelButton = {}, visible, onClose, closeOnOverlayClick = true, closeButton = true}) => {
  const {t} = useTranslation();

  return (
    // @ts-ignore
    <Modal isOpen={visible} onClose={onClose} closeOnOverlayClick={closeOnOverlayClick} _overlay={{style: {position: 'fixed'}}}>
      <Modal.Content>
        {closeButton && <Modal.CloseButton />}
        {title && <Modal.Header>{title}</Modal.Header>}
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer style={{borderTopWidth: 0}}>
          <Button.Group space={2}>
            <Button key={'cancel_button'} colorScheme="coolGray" onPress={cancelButton.onPress || onClose} variant={cancelButton.variant || 'unstyled'} px={5}>
              {cancelButton.label || t('Cancel')}
            </Button>
            <Button key={'ok_button'} onPress={okButton.onPress} variant={okButton.variant} px={5}>
              {okButton.label || t('OK')}
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmationDialog;
