import {BaseServerError} from '../BaseServerError';

export class RPCCallError extends BaseServerError {
  code: number;
  type: string;
  data: any;

  constructor(message: string, data?: any) {
    super(message, data);
    this.code = 311;
    this.type = 'RPC';
  }
}
