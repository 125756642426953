export enum GrowingMethodCode {
  CAGE_FREE = 'Cage Free',
  CLONED_FOODS = 'Cloned Food',
  CONVENTIONAL = 'Conventional',
  FARM_RAISED = 'Farm Raised',
  FIELD_GROWN = 'Field Grown',
  FLY_FREE = 'Fly Free',
  FREE_RANGE = 'Free Range',
  GRASS_FED = 'Grass Fed',
  GREENHOUSE = 'Greenhouse',
  HUMANELY_RAISED = 'Humanely Raised',
  HYDROPONIC = 'Hydroponic',
  INTEGRATED_PEST_MANAGEMENT = 'Integrated Pest Management',
  ORGANIC = 'Organic',
  SHADE_GROWN = 'Shade Grown',
  SUSTAINABLE = 'Sustainable',
  WILD = 'Wild',
}
