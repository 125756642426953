import {Heading, HStack, Link, Text} from 'native-base';
import {Trans, useTranslation} from 'react-i18next';

type Props = {
  company: {
    name: string;
    id: string;
    address: string;
    contactEmail: string;
  };
  projectName: string;
  appOrUrl: string;
  titleFontSize?: string;
};

export function TermsAndConditions(props: Props) {
  const {t} = useTranslation();

  return (
    <>
      <Heading fontSize={props.titleFontSize || '3xl'}>{t('Terms and Conditions')}</Heading>

      <Text mb={2}>
        {t(
          '{{companyName}}, responsible for the {{appOrUrl}}, hereinafter RESPONSIBLE, makes available to users this document, which aims to comply with the obligations set out in Law 34/2002, of July 11, on Services of the Information Society and Electronic Commerce (LSSICE), as well as to inform all users of the website about the terms of use.',
          {
            companyName: props.company.name,
            appOrUrl: props.appOrUrl,
          }
        )}
      </Text>
      <Text mb={2}>
        {t(
          'Anyone who accesses this website assumes the role of user, committing to the strict observation and compliance with the provisions set out here, as well as to any other legal provision that may be applicable.'
        )}
      </Text>
      <Text mb={2}>
        {t(
          '{{companyName}}, reserves the right to modify any type of information that may appear on {{appOrUrl}}, without the obligation to give prior notice or inform users of these obligations, understanding as sufficient with the publication on the {{companyName}} website,',
          {
            companyName: props.company.name,
            appOrUrl: props.appOrUrl,
          }
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('1. IDENTIFICATION DATA')}
      </Heading>
      <Text mb={2}>{t('Corporate name: {{companyName}}', {companyName: props.company.name})}</Text>
      <Text mb={2}>{t('Trade name: {{projectName}}', {projectName: props.projectName})}</Text>
      <Text mb={2}>{t('TAX ID: {{companyId}}', {companyId: props.company.id})}</Text>
      <Text mb={2}>{t('Address: {{companyAddress}}', {companyAddress: props.company.address})}</Text>
      <Text mb={2}>{t('E-mail: {{companyEmail}}', {companyEmail: props.company.contactEmail})}</Text>

      <Heading mt={5} mb={2} size="md">
        {t('2. OBJECT')}
      </Heading>
      <Text mb={2}>{t('Through {{appOrUrl}}, we offer Users the possibility to access information about our services.', {appOrUrl: props.appOrUrl})}</Text>

      <Heading mt={5} mb={2} size="md">
        {t('3. PRIVACY AND DATA PROCESSING')}
      </Heading>
      <Text mb={2}>
        {t(
          'When access to certain content or services requires providing personal data, Users will guarantee their truthfulness, accuracy, authenticity, and validity. The company will give such data the automated treatment corresponding to its nature or purpose, in the terms indicated in the Privacy Policy section.'
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('4. INDUSTRIAL AND INTELLECTUAL PROPERTY')}
      </Heading>
      <Text mb={2}>
        {t(
          'The User recognizes and accepts that all content displayed on {{appOrUrl}} and especially, designs, texts, images, logos, icons, buttons, software, trade names, brands, or any other signs susceptible to industrial and/or commercial use are subject to Intellectual Property rights and all brands, trade names or distinctive signs, all industrial and intellectual property rights, on the contents and/or any other elements inserted in the page, which are the exclusive property of {{companyName}} and/or third parties, who have the exclusive right to use them in economic traffic.',
          {
            companyName: props.company.name,
            appOrUrl: props.appOrUrl,
          }
        )}
      </Text>
      <Text mb={2}>
        {t(
          'Therefore, the User undertakes not to reproduce, copy, distribute, make available or in any other way publicly communicate, transform or modify such contents keeping the company harmless from any claim arising from the breach of such obligations. In no case does access to {{appOrUrl}} imply any kind of waiver, transmission, license or total or partial assignment of such rights, unless expressly stated otherwise. These General Conditions of Use of {{appOrUrl}} do not grant Users any other right of use, alteration, exploitation, reproduction, distribution or public communication of {{appOrUrl}} and/or its Contents other than those expressly provided for herein. Any other use or exploitation of any rights will be subject to the prior and express authorization specifically granted for this purpose by {{companyName}}, or the third party owner of the rights affected.',
          {
            companyName: props.company.name,
            appOrUrl: props.appOrUrl,
          }
        )}
      </Text>
      <Text mb={2}>
        {t(
          'The contents, texts, photographs, designs, logos, images, computer programs, source codes and, in general, any intellectual creation existing in this Space, as well as the site itself as a whole, as a multimedia artistic work, are protected as copyright by legislation on intellectual property.'
        )}
      </Text>
      <Text mb={2}>
        {t(
          'The company or third party owns the elements that make up the graphic design of {{appOrUrl}}, the menus, navigation buttons, the HTML code, the texts, images, textures, graphics and any other content of {{appOrUrl}} or, in any case has the corresponding authorization for the use of such elements. The content provided in {{appOrUrl}} may not be reproduced either in whole or in part, nor transmitted, nor registered by any information retrieval system, in any form or by any means, unless prior written authorization from the Entity is available.',
          {
            appOrUrl: props.appOrUrl,
          }
        )}
      </Text>
      <Text mb={2}>
        {t(
          'Likewise, it is prohibited to delete, evade and/or manipulate the "copyright" as well as the technical protection devices, or any information mechanisms that could contain the contents.'
        )}
      </Text>
      <Text mb={2}>
        {t(
          'The User of {{appOrUrl}} undertakes to respect the rights stated and to avoid any action that could harm them, reserving in all cases the company the exercise of whatever legal means or actions correspond to it in defense of its legitimate intellectual and industrial property rights.',
          {
            appOrUrl: props.appOrUrl,
          }
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('5. OBLIGATIONS AND RESPONSIBILITIES OF THE USER OF {{appOrUrl}}', {appOrUrl: props.appOrUrl.toUpperCase()})}
      </Heading>
      <Text mb={2}>
        {t('The User of {{appOrUrl}} must be at least 14 years old to register on {{projectName}}.', {
          appOrUrl: props.appOrUrl,
          projectName: props.projectName,
        })}
      </Text>
      <Text mb={2}>{t('The User agrees to:')}</Text>
      <Text mb={2} ml={5}>
        1.{' '}
        {t(
          'Make appropriate and lawful use of {{appOrUrl}} as well as the contents and services, in accordance with: (i) the legislation applicable at any time; (ii) the General Conditions of Use of {{appOrUrl}}; (iii) generally accepted morals and good customs and (iv) public order.',
          {nsSeparator: false, appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2} ml={5}>
        2. {t('Provide all the means and technical requirements needed to access {{appOrUrl}}.', {appOrUrl: props.appOrUrl})}
      </Text>
      <Text mb={2} ml={5}>
        3.{' '}
        {t(
          'Provide truthful information when filling in their personal data in the forms contained in the Web Space and keep them updated at all times so that they respond, at each moment, to the actual situation of the User. The User will be the only one responsible for the false or inaccurate statements made and the damages caused to the company or third parties for the information provided.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2}>{t('Notwithstanding the provision in the previous paragraph, the User must also refrain from:')}</Text>
      <Text mb={2} ml={5}>
        1.{' '}
        {t(
          'Making unauthorized or fraudulent use of {{appOrUrl}} and/or the contents for illegal purposes or effects, prohibited in these General Conditions of Use, harmful to the rights and interests of third parties, or that in any way could damage, disable, overload, deteriorate or prevent the normal use of services or documents, files and all kinds of content stored in any computer equipment.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2} ml={5}>
        2.{' '}
        {t('Accessing or attempting to access resources or restricted areas of {{appOrUrl}}, without meeting the conditions required for such access.', {
          appOrUrl: props.appOrUrl,
        })}
      </Text>
      <Text mb={2} ml={5}>
        3. {t('Causing damage to the physical or logical systems of {{appOrUrl}}, its suppliers, or third parties.', {appOrUrl: props.appOrUrl})}
      </Text>
      <Text mb={2} ml={5}>
        4.{' '}
        {t(
          'Introducing or spreading computer viruses or any other physical or logical systems on the network that are likely to cause damage to the physical or logical systems of the company, suppliers or third parties.'
        )}
      </Text>
      <Text mb={2} ml={5}>
        5. {t("Attempting to access, use and/or manipulate the company's data, third-party suppliers and other Users.")}
      </Text>
      <Text mb={2} ml={5}>
        6.{' '}
        {t(
          'Reproducing or copying, distributing, allowing public access through any form of public communication, transforming or modifying the contents, unless the authorization of the owner of the corresponding rights is available or it is legally permitted.'
        )}
      </Text>
      <Text mb={2} ml={5}>
        7.{' '}
        {t(
          "Deleting, hiding or manipulating the notes on intellectual property rights or industrial and other identifying data of the company's rights or third parties incorporated into the contents, as well as the technical protection devices or any information mechanisms that may be inserted into the contents."
        )}
      </Text>
      <Text mb={2} ml={5}>
        8.{' '}
        {t(
          'To acquire or attempt to acquire content using means or procedures other than those that, as the case may be, have been made available for this purpose or have been expressly indicated on the web pages where the content is found or, in general, those normally used on the Internet that do not pose a risk of damage or disabling of {{appOrUrl}} and/or its content.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2} ml={5}>
        9.{' '}
        {t(
          'In particular, and merely as an example and not exhaustively, the User agrees not to transmit, disseminate or make available to third parties information, data, content, messages, graphics, drawings, sound and/or image files, photographs, recordings, software, and in general, any type of material that:'
        )}
      </Text>
      <Text ml={8}>
        {'\u2022 '}
        {t(
          'In any way contradicts, undermines or infringes upon fundamental rights and public freedoms constitutionally recognized, in International Treaties and in the rest of the current legislation.'
        )}
      </Text>
      <Text ml={8}>
        {'\u2022 '}
        {t(
          'Induces, incites or promotes criminal, denigratory, defamatory, violent actions, or in general, contrary to the law, morals, generally accepted good customs, or public order.'
        )}
      </Text>
      <Text ml={8}>
        {'\u2022 '}
        {t('Induces, incites or promotes actions, attitudes or thoughts discriminatory by sex, race, religion, beliefs, age or condition.')}
      </Text>
      <Text ml={8}>
        {'\u2022 '}
        {t(
          'Incorporates, makes available, or allows access to products, elements, messages and/or services that are criminal, violent, offensive, harmful, degrading, or in general, contrary to the law, morals and generally accepted good customs, or public order.Induces or may induce an unacceptable state of anxiety or fear.'
        )}
      </Text>
      <Text ml={8}>
        {'\u2022 '}
        {t('Induces or incites involvement in dangerous, risky or harmful practices for health and mental balance.')}
      </Text>
      <Text ml={8}>
        {'\u2022 '}
        {t('Is protected by legislation on intellectual or industrial protection belonging to the company or to third parties without the intended use being authorized.')}
      </Text>
      <Text ml={8}>
        {'\u2022 '}
        {t("Is contrary to honor, personal and family privacy or people's own image.")}
      </Text>
      <Text ml={8}>
        {'\u2022 '}
        {t('Constitute any type of advertising.')}
      </Text>
      <Text ml={8} mb={2}>
        {'\u2022 '}
        {t('Include any type of virus or program that prevents the normal functioning of {{appOrUrl}}.', {appOrUrl: props.appOrUrl})}
      </Text>
      <Text mb={2}>
        {t(
          'If a password is provided to access some of the services and/or content of {{appOrUrl}}, you are obliged to use it diligently, keeping it confidential at all times. Consequently, you will be responsible for its appropriate custody and confidentiality, committing not to transfer it to third parties, temporarily or permanently, nor to allow access to the mentioned services and/or content by outsiders. Likewise, you are obliged to notify the company of any fact that may imply improper use of your password, such as, theft, loss or unauthorized access, in order to proceed to its immediate cancellation. Consequently, until you make the above notification, the company will be exempt from any liability that may arise from the misuse of your password, being your responsibility any illegal use of the content and/or services of {{appOrUrl}} by any illegitimate third party. If you negligently or willfully breach any of the obligations established in these General Conditions of Use, you will be liable for all damages and losses that may arise from such breach for the company.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('6. RESPONSIBILITIES')}
      </Heading>
      <Text mb={2}>
        {t(
          'Continuous access, correct visualization, downloading, or utility of the elements and information contained in {{appOrUrl}} that may be impeded, hindered or interrupted by factors or circumstances beyond its control is not guaranteed. The company is not responsible for the decisions that could be taken as a result of access to the content or information offered.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2}>
        {t(
          'The service may be interrupted, or the relationship with the User may be terminated immediately, if it is detected that a use of {{appOrUrl}}, or any of the services offered in it, is contrary to these General Conditions of Use. The company is not responsible for damages, losses, claims, or expenses derived from the use of {{appOrUrl}}.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2}>
        {t(
          'The company is only responsible for removing, as soon as possible, content that could generate such damage, as long as it is notified. Specifically, the company will not be responsible for damages that may derive, among others, from:',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text ml={5}>
        1.{' '}
        {t(
          "Interferences, interruptions, failures, omissions, telephone breakdowns, delays, blockages, or disconnections in the operation of the electronic system, caused by deficiencies, overloads, and errors in the lines and networks of telecommunications, or by any other cause beyond the company's control."
        )}
      </Text>
      <Text ml={5}>
        2. {t('Illegitimate intrusions through the use of malicious programs of any kind and through any means of communication, such as computer viruses or any other.')}
      </Text>
      <Text ml={5}>3. {t('Improper or inappropriate use of {{appOrUrl}}.', {appOrUrl: props.appOrUrl})}</Text>
      <Text ml={5} mb={2}>
        4.{' '}
        {t(
          'Security or navigation errors produced by a malfunction of the browser or by the use of outdated versions of it. The administrator of {{appOrUrl}} reserves the right to withdraw, totally or partially, any content or information present in {{appOrUrl}}.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2}>
        {t(
          'The company excludes any liability for damages of any nature that may be due to misuse of freely available and usable services by the users of {{appOrUrl}}. Likewise, it is exempted from any responsibility for the content and information that may be received as a result of data collection forms, these are solely for the provision of query and doubt services. On the other hand, in case of causing damages by illegal or incorrect use of such services, the User may be claimed for damages caused.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2}>
        {t(
          'You will keep the company harmless from any damages that may arise from claims, actions or demands of third parties as a result of your access or use of {{appOrUrl}}. You also agree to indemnify for any damages that may result from your use of "robots", "spiders", "crawlers" or similar tools used to collect or extract data or any other action on your part that imposes an unreasonable load on the operation of {{appOrUrl}}.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('7. HYPERLINKS')}
      </Heading>
      <Text mb={2}>
        {t(
          'The User undertakes not to reproduce in any way, even through a hyperlink, {{appOrUrl}}, or any of its contents, without express written authorization from the file manager.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2}>
        {t(
          "{{appOrUrl}} may include links to other websites, managed by third parties, in order to facilitate the User's access to information from partner companies and/or sponsors. In accordance with this, the company is not responsible for the content of such websites, nor does it place itself in a position of guarantor or offering party of the services and/or information that may be offered to third parties through third-party links.",
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2}>
        {t(
          'The User is granted a limited, revocable and non-exclusive right to create links to the main page of {{appOrUrl}} exclusively for private and non-commercial use. The websites that include a link to our website (i) may not misrepresent their relationship or claim that such a link has been authorized, nor include trademarks, names, trade names, logos or other distinctive signs of our company; (ii) may not include content that may be considered in bad taste, obscene, offensive, controversial, inciting violence or discrimination based on sex, race or religion, contrary to public order or illegal; (iii) may not link to any page of {{appOrUrl}} other than the main page; (iv) must link to the address of {{appOrUrl}}, without allowing the website that makes the link to reproduce the website as part of its website or within one of its "frames" or create a "browser" on any of the pages of {{appOrUrl}}. The company may request, at any time, that you remove any link to {{appOrUrl}}, after which you must proceed immediately to its removal.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2}>
        {t('The company cannot control the information, contents, products or services provided by other websites that have established links to {{appOrUrl}}.', {
          appOrUrl: props.appOrUrl,
        })}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('8. DATA PROTECTION')}
      </Heading>
      <Text mb={2}>
        {t(
          'To use some of the Services, the User must previously provide certain personal data. The company will automatically process this data and apply the corresponding security measures, all in compliance with the GDPR, LOPDGDD and LSSI.'
        )}
      </Text>
      <Text mb={2}>
        {t(
          'The User can access the policy followed in the processing of personal data, as well as the establishment of the previously established purposes, under the conditions defined in the Privacy Policy.'
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('9. COOKIES')}
      </Heading>
      <Text mb={2}>
        {t(
          'The company reserves the right to use the "cookie" technology on {{appOrUrl}}, in order to recognize you as a frequent User and personalize your use of {{projectName}} by preselecting your language, or most desired or specific content.',
          {appOrUrl: props.appOrUrl, projectName: props.projectName}
        )}
      </Text>
      <Text mb={2}>
        {t(
          "Cookies are files sent to a browser, through a Web server, to record the User's navigation on the website, when the User allows their reception. If you wish, you can configure your browser to be notified on screen of the receipt of cookies and to prevent the installation of cookies on your hard drive. Please consult the instructions and manuals of your browser to expand this information."
        )}
      </Text>
      <Text mb={2}>
        {t(
          'Thanks to cookies, it is possible to recognize the browser of the computer used by the User in order to provide content and offer navigation or advertising preferences to the User, to the demographic profiles of the Users, as well as to measure visits and traffic parameters, control the progress and number of entries.'
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('10. STATEMENTS AND WARRANTIES')}
      </Heading>
      <Text mb={2}>
        {t(
          'In general, the content and services offered on {{appOrUrl}} are purely informative. Therefore, by offering them, no warranty or statement is given in relation to the content and services offered on {{appOrUrl}}, including, but not limited to, warranties of legality, reliability, usefulness, truthfulness, accuracy, or merchantability, except to the extent that such declarations and warranties cannot be excluded by law.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('11. FORCE MAJEURE')}
      </Heading>
      <Text mb={2}>
        {t(
          'The company will not be responsible in all in case of inability to provide service, if this is due to prolonged interruptions of electricity supply, telecommunications lines, social conflicts, strikes, rebellion, explosions, floods, acts and omissions of the Government, and in general all cases of force majeure or fortuitous case.'
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('12. RESOLUTION OF DISPUTES. APPLICABLE LAW AND JURISDICTION')}
      </Heading>
      <Text mb={2}>
        {t(
          'These General Conditions of Use, as well as the use of {{appOrUrl}}, will be governed by Spanish law. For the resolution of any controversy, the parties will submit to the Courts and Tribunals of the registered office of the person responsible for {{appOrUrl}}.',
          {appOrUrl: props.appOrUrl}
        )}
      </Text>
      <Text mb={2}>
        {t(
          'In the event that any stipulation of these General Conditions of Use becomes unenforceable or null and void under applicable law or as a result of a judicial or administrative resolution, such unenforceability or nullity will not make these General Conditions of Use unenforceable or null and void as a whole. In such cases, the company will proceed to modify or replace such stipulation with another that is valid and enforceable and that, as far as possible, achieves the objective and claim reflected in the original stipulation.'
        )}
      </Text>
    </>
  );
}
