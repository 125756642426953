import React, {FC, useEffect, useState} from 'react';
import {Button, FormControl, Icon, Input, VStack, Text, IButtonProps} from 'native-base';
import {useAuthService} from 'services';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {Message} from 'yup';
import {Formik} from 'formik';
import {useConfig} from '../../core';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '../../components/SharedToast';
import {AntDesign} from '@native-base/icons';

type Props = {
  view: 'client' | 'backoffice';
  buttonText?: string;
  labelText?: string;
  buttonStyle?: Record<string, unknown>;
  buttonStyleText?: Record<string, unknown>;
};

export const RecoverEmail: FC<Props> = props => {
  const {t} = useTranslation();
  const authService = useAuthService();
  const [isLoading, setIsLoading] = useState(false);
  const [config] = useConfig();
  const sharedToast = useSharedToast();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('Invalid email') as Message)
      .required(t('Required') as Message),
  });

  const submitRecoveryMail = (email: string) => {
    if (!config.oauth || !config.oauth.clientId || !config.oauth.clientSecret) {
      console.error('OAuth Module not configured properly.');
      return;
    }

    setIsLoading(true);
    authService
      .resetPassword({
        email,
        view: props.view,
      })
      .response(() => {
        setIsLoading(false);
        sharedToast({
          title: t('Email Sent!'),
          description: '',
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
      })
      .error(() => {
        setIsLoading(false);
        sharedToast({
          title: t('Email Sent!'),
          description: '',
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
      });
  };

  return props.view === 'backoffice' ? (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={values => {
        submitRecoveryMail(values.email);
      }}
      validationSchema={LoginSchema}>
      {({handleBlur, handleChange, handleSubmit, values, errors}) => (
        <>
          <FormControl justifyContent="center" alignContent="center" isRequired isInvalid={'email' in errors} key="email" mt={2}>
            <FormControl.Label variant="register" _text={{fontSize: 'md'}}>
              {props.labelText || t('e-mail')}
            </FormControl.Label>
            <Input
              placeholder={t('example@mail.com') as string}
              onBlur={handleBlur('email')}
              onChangeText={handleChange('email')}
              value={values.email}
              variant="register"
              InputRightElement={
                values.email !== null && values.email !== '' ? (
                  <Icon
                    as={AntDesign}
                    name="closecircleo"
                    size={5}
                    color="secondary.500"
                    marginRight={2}
                    onPress={() => {
                      values.email = '';
                    }}
                  />
                ) : undefined
              }
            />
            {'email' in errors && <FormControl.ErrorMessage>{errors.email}</FormControl.ErrorMessage>}
          </FormControl>
          <Button
            variant="register"
            mt={5}
            px={4}
            borderRadius={7}
            {...props.buttonStyle}
            onPress={() => handleSubmit()}
            isLoading={isLoading}
            isLoadingText={t('Submitting')!}>
            <Text color="primary.500" {...props.buttonStyleText}>
              {props.buttonText || t('Send recovery email')}
            </Text>
          </Button>
        </>
      )}
    </Formik>
  ) : (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={values => {
        submitRecoveryMail(values.email);
      }}
      validationSchema={LoginSchema}>
      {({handleBlur, handleChange, handleSubmit, values, errors}) => (
        <>
          <FormControl justifyContent="center" alignContent="center" isRequired isInvalid={'email' in errors} key="email">
            <FormControl.Label variant="register">{t('E-mail')}</FormControl.Label>
            <Input
              placeholder={t('example@mail.com') as string}
              onBlur={handleBlur('email')}
              onChangeText={handleChange('email')}
              value={values.email}
              variant="register"
              InputRightElement={
                values.email !== null && values.email !== '' ? (
                  <Icon
                    as={AntDesign}
                    name="closecircleo"
                    size={5}
                    color="secondary.500"
                    marginRight={2}
                    onPress={() => {
                      values.email = '';
                    }}
                  />
                ) : undefined
              }
            />
            {'email' in errors && <FormControl.ErrorMessage>{errors.email}</FormControl.ErrorMessage>}
          </FormControl>
          <VStack alignItems="end">
            <Button variant="register" mt={5} px={4} {...props.buttonStyle} onPress={() => handleSubmit()} isLoading={isLoading} isLoadingText={t('Submitting')!}>
              <Text color="primary.500" {...props.buttonStyleText}>
                {props.buttonText || t('Send recovery email')}
              </Text>
            </Button>
          </VStack>
        </>
      )}
    </Formik>
  );
};
