import {CLIENT_ERROR_CODES, CLIENT_ERROR_TYPES} from '../constants';

export class BaseClientError extends Error {
  code: number;
  type: string;
  data: any;
  mute = false;

  constructor(message: string, data?: any) {
    super(message);
    this.code = CLIENT_ERROR_CODES.BASE;
    this.type = CLIENT_ERROR_TYPES.BASE;
    this.message = message;
    this.data = data;
  }

  setMute(mute: boolean) {
    this.mute = mute;
  }
}
