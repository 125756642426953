import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {COOKIES_TYPES, INTERNAL_EVENTS, SHARED_CLIENT_ERROR_TYPE} from '../../constants';
import {EventBus, useEventBus} from './EventBus';
import {InternalEventsTypes, SharedComponentsSettings} from '../../types';
import {useConfig} from '../../core';
import {ApiResponse, BaseServiceInterface, CLIENT_ERROR_CODES, CLIENT_ERROR_TYPES, CustomAxiosError, ServiceDefinition} from '@bri/shared-core';
import {CookiesService} from '../cookies';

type AxiosMethods = 'POST' | 'GET' | 'DELETE' | 'PUT';

let isRefreshing = false;

const sleep = function (ms: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

/**
 * Each petition is a new instance of this class
 */
class BaseService<D extends ServiceDefinition, A extends keyof D> implements BaseServiceInterface<D, A> {
  config: SharedComponentsSettings['baseService'];
  eventBus: EventBus<InternalEventsTypes, INTERNAL_EVENTS>;

  needAuth = false;
  autoRedirect = true;
  petition?: Promise<any>;

  onApiResponse: any;
  onResponse: any;
  onError: any;
  onFinally: any;

  customToken?: string;

  static new<D extends ServiceDefinition, A extends keyof D>(
    config: SharedComponentsSettings['baseService'],
    eventBus: EventBus<InternalEventsTypes, INTERNAL_EVENTS>
  ): BaseService<D, A> {
    return new BaseService(config, eventBus);
  }

  constructor(_config: SharedComponentsSettings['baseService'], _eventBus: EventBus<InternalEventsTypes, INTERNAL_EVENTS>) {
    this.config = _config;
    this.eventBus = _eventBus;
  }

  auth(): BaseService<D, A> {
    this.needAuth = true;
    return this;
  }

  noRedirect(): BaseService<D, A> {
    this.autoRedirect = false;
    return this;
  }

  get<T extends ApiResponse>(url: string, params?: any): BaseService<D, A> {
    this.petition = this.makePetition<T>('GET', url, params);
    return this;
  }

  delete<T extends ApiResponse>(url: string, params?: any): BaseService<D, A> {
    this.petition = this.makePetition<T>('DELETE', url, params);
    return this;
  }

  post<T extends ApiResponse>(url: string, params?: any): BaseService<D, A> {
    this.petition = this.makePetition<T>('POST', url, params);
    return this;
  }

  put<T extends ApiResponse>(url: string, params?: any): BaseService<D, A> {
    this.petition = this.makePetition<T>('PUT', url, params);
    return this;
  }

  postFile<T extends ApiResponse>(url: string, params?: any, fileFields?: string[]): BaseService<D, A> {
    const formData = new FormData();
    if (params) {
      for (const field of Object.keys(params)) {
        if (fileFields?.includes(field)) {
          for (let index = 0; index < params[field].length; index++) {
            formData.append(field, params[field][index]);
          }
        } else {
          formData.append(field, params[field]);
        }
      }
    }

    const options = {headers: {'Content-Type': 'multipart/form-data'}};
    this.petition = this.makePetition<T>('POST', url, formData, options);
    return this;
  }

  async handleUnauthorized(apiResponse: AxiosResponse, petition: {method: AxiosMethods; url: string; params?: any; configp?: AxiosRequestConfig}) {
    if (apiResponse.data?.error?.type === CLIENT_ERROR_TYPES.ACCESS_TOKEN_EXPIRED) {
      // Other petition is refreshing the access token, wait for it
      if (isRefreshing) {
        const start = Date.now();
        while (isRefreshing && Date.now() - start < 10 * 1000) {
          await sleep(500);
        }
        return this.makePetition(petition.method, petition.url, petition.params, petition.configp);
      }
      // Refresh the access token
      isRefreshing = true;
      const refreshToken = await CookiesService.getType(COOKIES_TYPES.TECHNICAL, 'refresh_token');
      const needAuthAux = this.needAuth;
      this.needAuth = false;
      try {
        const result = await this.makePetition('POST', '/api/v1/oauth/token', {
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        });
        this.needAuth = needAuthAux;
        if (result.response) {
          if (result.response.access_token) {
            await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'access_token', result.response.access_token);
          }
          if (result.response.refresh_token) {
            await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'refresh_token', result.response.refresh_token);
          }

          isRefreshing = false;
          return this.makePetition(petition.method, petition.url, petition.params, petition.configp);
        } else {
          // Remove tokens because is invalid
          await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'access_token');
          await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'refresh_token');
        }
      } catch (err) {
        this.needAuth = needAuthAux;
        // Remove tokens because is invalid
        await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'access_token');
        await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'refresh_token');
      }
    } else {
      // Remove tokens because is invalid
      await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'access_token');
      await CookiesService.removeKey(COOKIES_TYPES.TECHNICAL, 'refresh_token');
    }
  }

  async makePetition<T extends ApiResponse>(method: AxiosMethods, url: string, params?: any, configp?: AxiosRequestConfig): Promise<T> {
    let fullUrl = this.getFullUrl(url);

    if (configp?.headers?.authorization) {
      delete configp.headers.authorization;
    }

    const config = configp || {};
    const authHeaders = await this.buildAuthHeaders();
    config.headers = {...authHeaders, ...(config.headers ? config.headers : {})};

    if (method === 'GET' && params) {
      fullUrl += this.encodeQueryData(params);
    }

    // Do the petition
    const apiResponse = await this.axiosCall<T>(method, fullUrl, params, config);

    // Check for HTML errors
    if (apiResponse.status === CLIENT_ERROR_CODES.LOGOUT) {
      // Unauthorized
      this.eventBus.fireEvent(INTERNAL_EVENTS.LOGOUT);
    } else if (apiResponse.status === CLIENT_ERROR_CODES.UNAUTHORIZED) {
      // Unauthorized
      const responseData = await this.handleUnauthorized(apiResponse as AxiosResponse, {method, url, params, configp});

      if (responseData) {
        return responseData as any;
      }
    } else if (apiResponse.status === 501) {
      // Server api key invalid
      this.eventBus.fireEvent(INTERNAL_EVENTS.REDIRECT, {errorType: SHARED_CLIENT_ERROR_TYPE.GENERIC, message: 'Server api key invalid'});
    } else if (apiResponse.status === 600) {
      // Server not running
      this.eventBus.fireEvent(INTERNAL_EVENTS.REDIRECT, {errorType: SHARED_CLIENT_ERROR_TYPE.GENERIC, message: 'Server not running', data: JSON.stringify(apiResponse)});
    } else if (apiResponse.status >= 400 && apiResponse.status < 500 && apiResponse.status !== 422) {
      // 4XX error
      if (apiResponse.status === 404) {
        this.eventBus.fireEvent(INTERNAL_EVENTS.REDIRECT, {errorType: SHARED_CLIENT_ERROR_TYPE.NOT_FOUND, message: 'Not found error'});
      } else {
        this.eventBus.fireEvent(INTERNAL_EVENTS.REDIRECT, {errorType: SHARED_CLIENT_ERROR_TYPE.GENERIC, message: `${apiResponse.status} Error`});
      }
    } else if (apiResponse.status >= 500) {
      // Internal server error
      this.eventBus.fireEvent(INTERNAL_EVENTS.REDIRECT, {errorType: SHARED_CLIENT_ERROR_TYPE.GENERIC, message: 'Internal server error'});
    }

    // Check Api Response not empty
    if (!apiResponse.data) {
      this.eventBus.fireEvent(INTERNAL_EVENTS.REDIRECT, {errorType: SHARED_CLIENT_ERROR_TYPE.GENERIC, message: 'Empty server response'});
    }

    // Check for APP errors
    if (apiResponse.data && apiResponse.data.error) {
      if (apiResponse.data.error.type === 'INTERNAL') {
        // TODO CLIENT ERROR TYPE INTERNAL
        // Internal server error
        this.eventBus.fireEvent(INTERNAL_EVENTS.REDIRECT, {
          errorType: SHARED_CLIENT_ERROR_TYPE.GENERIC,
          message: 'Internal server error',
          data: JSON.stringify({error: apiResponse.data.error, request: apiResponse.data.request}),
        });
      }
    }

    return apiResponse.data;
  }

  async axiosCall<T>(method: AxiosMethods, url: string, params: any, config: AxiosRequestConfig): Promise<AxiosResponse<T> | CustomAxiosError> {
    try {
      let response: AxiosResponse<T>;
      switch (method) {
        case 'POST':
          response = await axios.post<T>(`${this.config.baseUrl}${url}`, params, config);
          break;
        case 'GET':
          response = await axios.get<T>(`${this.config.baseUrl}${url}`, config);
          break;
        case 'DELETE':
          response = await axios.delete<T>(`${this.config.baseUrl}${url}`, {params, ...config});
          break;
        case 'PUT':
          response = await axios.put<T>(`${this.config.baseUrl}${url}`, params, config);
          break;
      }
      return response;
    } catch (error) {
      if ((error as any).response) {
        return (error as any).response;
      }
      return {
        data: error,
        status: 600,
        statusText: 'ERR_CONNECTION_REFUSED',
      };
    }
  }

  async buildAuthHeaders(): Promise<Record<string, string>> {
    let accessToken;
    if (this.customToken) {
      accessToken = this.customToken;
    } else {
      accessToken = await CookiesService.getType(COOKIES_TYPES.TECHNICAL, 'access_token');
    }
    return {
      authorization: `Bearer ${accessToken}`,
    };
  }

  getFullUrl(url: string): string {
    let fullUrl = url;

    // Must start with /
    if (!fullUrl.startsWith('/')) {
      fullUrl = `/${fullUrl}`;
    }

    // Must start with /api
    if (!fullUrl.startsWith('/api')) {
      fullUrl = `/api${fullUrl}`;
    }

    // Auth must start with /api/secure
    if (this.needAuth) {
      if (!fullUrl.startsWith('/api/secure')) {
        fullUrl = `/api/secure${fullUrl.slice(4)}`;
      }
    }
    // No auth must not have /secure
    else if (fullUrl.startsWith('/api/secure')) {
      fullUrl = `/api${fullUrl.slice(11)}`;
    }
    return fullUrl;
  }

  encodeQueryData(params: any): string {
    const ret = [];
    for (const d in params) {
      if (d) {
        if (typeof params[d] === 'object' && typeof params[d].length) {
          // Is an array param
          for (const item of params[d]) {
            ret.push(`${encodeURIComponent(d)}[]=${item}`);
          }
        } else if (params[d]) {
          ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(params[d])}`);
        }
      }
    }
    return `?${ret.join('&')}`;
  }

  /**
   * Error and Response Callbacks
   */
  apiResponse(onApiResponse: any) {
    this.onApiResponse = onApiResponse;
    return this;
  }

  error(onError: any) {
    this.onError = onError;
    this.execute();
    return this;
  }

  response(onResponse: any) {
    this.onResponse = onResponse;
    this.execute();
    return this;
  }

  finally(onFinally: any) {
    this.onFinally = onFinally;
    return this;
  }

  setCustomToken(token: string): BaseService<D, A> {
    this.customToken = token;
    return this;
  }

  /**
   * Execute the call
   */
  execute(force?: boolean) {
    if (force || (this.onError && this.onResponse)) {
      Promise.resolve(this.petition)
        .then(async apiResponse => {
          if (this.onApiResponse) {
            await this.onApiResponse(apiResponse);
          }
          if (apiResponse.error) {
            if (this.onError) await this.onError(apiResponse.error, apiResponse.request);
          } else if (apiResponse.response) {
            if (this.onResponse) await this.onResponse(apiResponse.response);
          } else {
            console.error('Empty data from server response');
          }
        })
        .catch(ignored => {
          console.error(ignored);
        })
        .finally(async () => {
          if (this.onFinally) await this.onFinally();
        });
    }
  }
}

export function useBaseService() {
  const [config] = useConfig();
  const eventBus = useEventBus();

  return () => BaseService.new(config.baseService, eventBus);
}
