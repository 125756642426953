import {IQuantitativeValue} from './iquantitative.value';

export enum Availability {
  IN_STOCK = 'http://schema.org/InStock',
  OUT_OF_STOCK = 'http://schema.org/OutOfStock',
}

export declare type IOffer = {
  '@type': string /** Offer */;
} & Partial<{
  availabiity: Availability;
  availabilityEnds: Date;
  eligibleQuantity: IQuantitativeValue;
}>;
