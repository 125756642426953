import React, {useEffect} from 'react';
import {Button, Heading, HStack, Switch, Text, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import {COOKIES_TYPES} from '../../constants';
import {CookiesService} from './CookiesService';

type Props = {
  closable?: boolean;
  onClose?: () => void;
  onAnalysisChange?: (active: boolean) => void;
  onPersonalisationChange?: (active: boolean) => void;
  onAdvertisingChange?: (active: boolean) => void;
  onBehaviouralAdvertisingChange?: (active: boolean) => void;
  onRemoveAllCookies?: () => void;
  titleFontSize?: string;
};

export function CookiesConsent(props: Props) {
  const {t} = useTranslation();
  const [analysisSwitch, setAnalysisSwitch] = React.useState(false);
  const [personalisationSwitch, setPersonalisationSwitch] = React.useState(false);
  const [advertisingSwitch, setAdvertisingSwitch] = React.useState(false);
  const [behaviouralAdvertisingSwitch, setBehaviouralAdvertisingSwitch] = React.useState(false);

  async function setCookie(type: COOKIES_TYPES, value: boolean) {
    if (value) {
      await CookiesService.setType(type, type, 'true');
    } else {
      await CookiesService.removeType(type);
    }
  }

  const data = [
    {
      title: t('Technical'),
      message: t(
        'Allow you to browse our website and use the different options or services it ' +
          'offers. With them we can, for example, monitor traffic and data communication, ' +
          'identify you during a session, let you access restricted areas, and use security ' +
          'elements while browsing'
      ),
      value: true,
      disabled: true,
    },
    {
      title: t('Personalisation'),
      message: t(
        'These cookies are used to store information so that users can access our service ' +
          'with certain characteristics that can differentiate their experience from that of other users,' +
          ' such as, for example, the language, the number of search results to display, etc.'
      ),
      value: personalisationSwitch,
      onValueChange: async (value: boolean) => {
        setPersonalisationSwitch(value);
        setCookie(COOKIES_TYPES.PERSONALISATION, value);
        if (props.onPersonalisationChange) props.onPersonalisationChange(value);
      },
    },
    {
      title: t('Analysis'),
      message: t(
        'These cookies are used to monitor and analyse the behaviour of different users on our website ' +
          'and improve it based on our analysis of data on how our products and/or services are used.'
      ),
      value: analysisSwitch,
      onValueChange: (value: boolean) => {
        setAnalysisSwitch(value);
        setCookie(COOKIES_TYPES.ANALYSIS, value);
        if (props.onAnalysisChange) props.onAnalysisChange(value);
      },
    },
    {
      title: t('Advertising'),
      message: t(
        'They allow us to manage the advertising spaces included in our application, based on criteria such as ' +
          'the content displayed or the frequency with which the ads are served.'
      ),
      value: advertisingSwitch,
      onValueChange: (value: boolean) => {
        setAdvertisingSwitch(value);
        setCookie(COOKIES_TYPES.ADVERTISING, value);
        if (props.onAdvertisingChange) props.onAdvertisingChange(value);
      },
    },
    {
      title: t('Behavioural Advertising'),
      message: t(
        'They store information on user behaviour based on their browsing habits, and they allow us to develop a specific profile and show them advertising based on their tastes and interests.'
      ),
      value: behaviouralAdvertisingSwitch,
      onValueChange: (value: boolean) => {
        setBehaviouralAdvertisingSwitch(value);
        setCookie(COOKIES_TYPES.BEHAVIOURAL_ADVERTISING, value);
        if (props.onBehaviouralAdvertisingChange) props.onBehaviouralAdvertisingChange(value);
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setPersonalisationSwitch((await CookiesService.getType(COOKIES_TYPES.PERSONALISATION, COOKIES_TYPES.PERSONALISATION)) === 'true');
      setAnalysisSwitch((await CookiesService.getType(COOKIES_TYPES.ANALYSIS, COOKIES_TYPES.ANALYSIS)) === 'true');
      setAdvertisingSwitch((await CookiesService.getType(COOKIES_TYPES.ADVERTISING, COOKIES_TYPES.ADVERTISING)) === 'true');
      setBehaviouralAdvertisingSwitch((await CookiesService.getType(COOKIES_TYPES.BEHAVIOURAL_ADVERTISING, COOKIES_TYPES.BEHAVIOURAL_ADVERTISING)) === 'true');
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <>
      <VStack>
        <Heading fontSize={props.titleFontSize || '3xl'}>
          <HStack width="100%" justifyContent="space-between">
            <Text>{t('Cookies policy')}</Text>
            {props.closable ? (
              <Button variant="link" p="0" onPress={props.onClose}>
                <Text>X</Text>
              </Button>
            ) : null}
          </HStack>
        </Heading>
        <Button
          my={5}
          onPress={async () => {
            await CookiesService.removeAll();
            props.onRemoveAllCookies && props.onRemoveAllCookies();
          }}>
          {t('Remove all cookies')}
        </Button>
        {data.map(item => (
          <VStack key={item.title} mt={4}>
            <HStack alignItems="center" justifyContent="space-between">
              <Heading size="md">{item.title}</Heading>
              <Switch size="md" isChecked={item.value} onValueChange={item.onValueChange} disabled={item.disabled} />
            </HStack>
            <Text my={3} textAlign="justify">
              {item.message}
            </Text>
          </VStack>
        ))}
      </VStack>
    </>
  );
}
