import React from 'react';
import {Heading, Link, Text, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

// IMPORTANT: Canviar aquest valor quan es facin modificacions al text!
const defaultLastUpdated = new Date(2023, 8, 28);

type Props = {
  company: {
    name: string;
    id: string;
    address: string;
    contactEmail: string;
  };
  projectName: string;
  appOrUrl: string;
  lastUpdated?: Date;
  titleFontSize?: string;
};

export function PrivacyPolicy(props: Props) {
  const {t} = useTranslation();

  return (
    <>
      <Text mb={2} italic>
        {t('Esta Política de Privacidad fue actualizada el {{lastUpdated}}.', {
          lastUpdated: moment(props.lastUpdated || defaultLastUpdated).format('DD/MM/YYYY'),
        })}
      </Text>

      <Heading mb={2} fontSize={props.titleFontSize || '3xl'}>
        {t('Política de Privacidad')}
      </Heading>
      <Text mb={2}>
        {t('{{companyName}}, en adelante LA CONSULTORA, recopila, trata y almacena información personal a través de la plataforma de su propiedad {{projectName}}:', {
          companyName: props.company.name,
          projectName: props.projectName,
        })}
      </Text>
      <Text mb={2}>
        {t('{{appOrUrl}}', {
          appOrUrl: props.appOrUrl,
        })}
      </Text>
      <Text mb={2}>
        {t(
          'Esta información será relativa a los usuarios de la web. La información se recopilará, tratará y almacenará conforme a la presente Política de Privacidad y cumpliendo con lo establecido en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR) relativo a la protección de personas físicas en l que respeta al tratamiento de datos personales y a la libre circulación de estos. De conformidad con lo dispuesto en el GDPR, facilitamos la siguiente información sobre el tratamiento:'
        )}
      </Text>
      <Heading mt={5} mb={2} size="md">
        {t('Responsable legal')}
      </Heading>
      <Text mb={2}>
        {t('{{companyName}} ({{companyId}}) tiene su sede fiscal en {{companyAddress}}. Correo electrónico de contacto:', {
          companyName: props.company.name,
          companyId: props.company.id,
          companyAddress: props.company.address,
        })}
      </Text>
      <Text mb={2}>
        {t('{{companyEmail}}', {
          companyEmail: props.company.contactEmail,
        })}
      </Text>
      <Heading mt={5} mb={2} size="md">
        {t('Datos recopilados y uso de éstos')}
      </Heading>
      <Text mb={2}>
        {t(
          'Únicamente se recopilarán los datos estrictamente necesarios para llevar a cabo la normal actividad del servicio. Ajustándose al principio de minimización de datos (Art.5.b GDPR).'
        )}
      </Text>
      <Text mb={2}>
        {t(
          'Los datos recopilados serán en todo caso de mayores de 16 años (Art. 8.1 GDPR). La Consultora se reserva el derecho a tomar las medidas oportunas para comprobar la veracidad de la edad (Art. 8.2 GDPR).'
        )}
      </Text>
      <Text mb={2}>{t('En todo caso estos datos serán de carácter personal identificativos y no sensibles. Podrán ser, sin carácter limitativo:')}</Text>
      <VStack mb={2} ml={5}>
        <Text>
          {'\u2022 '}
          {t('Correo electrónico')}
        </Text>
        <Text>
          {'\u2022 '}
          {t('Teléfono')}
        </Text>
        <Text>
          {'\u2022 '}
          {t('Nombre y apellidos')}
        </Text>
        <Text>
          {'\u2022 '}
          {t('Posición o cargo')}
        </Text>
        <Text>
          {'\u2022 '}
          {t('Empresa')}
        </Text>
      </VStack>
      <Text mb={2}>
        {t(
          'La recopilación y uso de datos se realiza con el objetivo de enviar información sobre La Consultora, eventos, iniciativas, promociones y actualidad del sector en el que La Consultora desarrolla su negocio (innovación, tecnología y otros relacionados con los proyectos y ámbitos de expertise de La Consultora) en formato newsletter o mailing.'
        )}
      </Text>
      <Text mb={2}>
        {t(
          'Adicionalmente, el equipo de La Consultora podrá ponerse en contacto con los usuarios que hayan facilitado los datos a través de los formularios de la web. Ésto se hará por vía telefónica o por email. Estos contactos irán especialmente destinados a resolver dudas o facilitar información que se haya solicitado o cuestionada previamente a través de los diferentes formularios de la web.'
        )}
      </Text>
      <Text mb={2}>{t('La Consultora se compromete a no utilizar los datos obtenidos con una finalidad distinta a esta.')}</Text>
      <Heading mt={5} mb={2} size="md">
        {t('Métodos de recopilación')}
      </Heading>
      <Text mb={2}>
        {t(
          'Los datos personales se recopilarán a través de la web de La plataforma {{projectName}}, {{appOrUrl}} , cuando introduzcas información en alguno de los campos destinados a tal efecto en la web. Estos campos están debidamente señalizados y no recopilarán ningún dato hasta que no aceptes expresamente la cesión y gestión conforme a esta Política de Privacidad.',
          {
            projectName: props.projectName,
            appOrUrl: props.appOrUrl,
          }
        )}
      </Text>
      <Heading mt={5} mb={2} size="md">
        {t('Plazo de conservación de los datos')}
      </Heading>
      <Text mb={2}>
        {t(
          'Los datos se conservarán hasta que se cumpla el objetivo por el cual se recopilaron estos datos (Art.5. e. GDPR) o hasta que se ejerza el derecho a la supresión o modificación de estos. Siempre y cuando esto no entre en conflicto con la necesidad por motivos legales o fiscales de almacenar los mismos.'
        )}
      </Text>
      <Heading mt={5} mb={2} size="md">
        {t('Protección de datos')}
      </Heading>
      <Text mb={2}>
        {t(
          'La Consultora garantiza que se han implementado las políticas técnicas y organizativas adecuadas llevar a cabo las medidas de seguridad establecidas en el GDPR con el fin de proteger los derechos y libertades de los usuarios.'
        )}
      </Text>
      <Text mb={2}>
        {t('Además, La Consultora se compromete a mantener la confidencialidad de los datos y no comunicará ni permitirá el acceso a terceros no autorizados previamente.')}
      </Text>
      <Heading mt={5} mb={2} size="md">
        {t('Tus derechos como a usuario')}
      </Heading>
      <Text mb={2}>{t('La legislación le reconoce unos derechos como usuario que ha cedido sus datos personales:')}</Text>
      <VStack mb={2} ml={5}>
        <Text>
          {'\u2022 '}
          {t('Acceso a los datos personales.')}
        </Text>
        <Text>
          {'\u2022 '}
          {t('Rectificación o supresión.')}
        </Text>
        <Text>
          {'\u2022 '}
          {t('Oponerse al tratamiento o limitarlo.')}
        </Text>
        <Text>
          {'\u2022 '}
          {t('Portabilidad de los datos.')}
        </Text>
        <Text>
          {'\u2022 '}
          {t('Limitación de su tratamiento.')}
        </Text>
      </VStack>
      <Text mb={2}>{t('El ejercicio de estos derechos es personal. Por lo que solo puedes solicitarlos para los datos de los cuales se es propietario.')}</Text>
      <Text mb={2}>
        {t(
          'En el caso de que quieras ejercer alguno de estos derechos puedes hacerlos a online través de cualquiera de los formularios de contacto disponibles en la web, mediante un mail dirigido a {{companyEmail}}  o mediante una carta dirigida a {{companyName}} dirección {{companyAddress}}. Debiendo indicar los derechos que se quieren ejercer y una prueba de identidad.',
          {
            companyEmail: props.company.contactEmail,
            companyName: props.company.name,
            companyAddress: props.company.address,
          }
        )}
      </Text>
      <Text mb={2}>{t('Nos comprometemos a responder a tu solicitud en un plazo máximo de 30 días hábiles.')}</Text>
      <Heading mt={5} mb={2} size="md">
        {t('Legitimación para el tratamiento de datos')}
      </Heading>
      <Text mb={2}>
        {t(
          'La base legal para el tratamiento de tus datos personales es la aceptación explícita del tratamiento, gestión y almacenamiento de estos según la presente Política de Privacidad.'
        )}
      </Text>
      <Heading mt={5} mb={2} size="md">
        {t('Consecuencias de no aceptar la política de privacidad')}
      </Heading>
      <Text mb={2}>
        {t(
          'En el caso de que no aceptes la gestión de tus datos según la presente Política de Privacidad, no se procederá a la recopilación de estos, lo que puede suponer que no puedas seguir adelante con el envío de los diferentes formularios de contacto disponibles en el sitio web.'
        )}
      </Text>
      <Heading mt={5} mb={2} size="md">
        {t('Autoridad de protección de datos en España')}
      </Heading>
      <Text mb={2}>
        {t(
          'En el caso de que quieras hacer valer tus derechos en cuanto a protección de datos y consideras que no los estamos respetando puedes dirigirte a la autoridad española responsable'
        )}
      </Text>
      <VStack mb={2} ml={5}>
        <Text>
          {'\u2022 '}
          {t('Web de la autoridad en materia de protección de datos: ')}
        </Text>
        <Link href={t('http://www.aedp.es/', {nsSeparator: false})!} isExternal _text={{wordBreak: 'break-word'} as any}>
          {t('http://www.aedp.es/', {nsSeparator: false})}
        </Link>
        <Text>
          {'\u2022 '}
          {t('Email de la autoridad en materia de protección de datos: ')}
        </Text>
        <Link href={t('mailto:internacional@aedp.es', {nsSeparator: false})!} isExternal _text={{wordBreak: 'break-word'} as any}>
          {t('internacional@aedp.es', {nsSeparator: false})}
        </Link>
      </VStack>
      <Heading mt={5} mb={2} size="md">
        {t('Cambios en la política de privacidad')}
      </Heading>
      <Text mb={2}>
        {t(
          'La Consultora se reserva el derecho a modificar la presente Política de Privacidad, estas modificaciones se harán conforme a la legislación y la jurisprudencia y quedarán reflejadas en la presente Política de Privacidad'
        )}
      </Text>
    </>
  );
}
