import {IBase} from './ibase';

export enum NOTIFICATION_CATEGORY {
  TECHNICAL = 'TECHNICAL',
  PROMOTIONS = 'PROMOTIONS',
  NEWS = 'NEWS',
  INFORMATION_OF_INTEREST = 'INFORMATION_OF_INTEREST',
  OTHER = 'OTHER',
}

export enum NOTIFICATION_CHANNEL {
  IN_APP = 'IN_APP',
  PUSH = 'PUSH',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum NOTIFICATION_PLATFORM {
  ANDROID = 'android',
  IOS = 'ios',
}

export type NotificationToken = {
  type: NOTIFICATION_PLATFORM;
  token: string;
  date: Date;
  expiration: Date;
};

export type NotificationDispatch = {
  id?: string;
  channel: NOTIFICATION_CHANNEL;
  sentAt?: Date;
};

export type INotificationBase = IBase & {
  category: NOTIFICATION_CATEGORY;
  type: string;
  title: string;
  description: string;
  readAt?: Date;
  dispatches: NotificationDispatch[];
  owner: string;
  data?: any;
  active: boolean;
};
