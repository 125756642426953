export enum PaymentMethod {
  BANKERS_DRAFT = 'Bankers Draft',
  BANK_CHEQUE = 'Bank Cheque',
  BANK_GIRO = 'Bank Giro',
  BOOKENTRY_CREDIT = 'Book Entry Credit',
  BOOKENTRY_DEBIT = 'Book Entry Debit',
  BOP = 'BOP',
  CASH = 'Cash',
  CERTIFIED_CHEQUE = 'Certified Cheque',
  CHEQUE = 'Cheque',
  CREDIT_CARD = 'Credit Card',
  CREDIT_CARD_AMEX = 'American Express Credit Card',
  CREDIT_CARD_DINERS_CLUB = 'Diners Club Credit Card',
  CREDIT_CARD_DISCOVER = 'Discover Credit Card',
  CREDIT_CARD_MASTERCARD = 'Mastercard Credit Card',
  CREDIT_CARD_VISA = 'Visa Credit Card',
  DEBIT_CARD = 'Debit Card',
  DEBIT_CARD_MAESTRO = 'Maestro Debit Card',
  DEBIT_CARD_MASTERCARD = 'Mastercard Debit Card',
  DEBIT_CARD_VISA = 'Visa Debit Card',
  DEBIT_CARD_VISA_ELECTRON = 'Visa Electron Debit Card',
  ELECTRONIC_CREDIT_ACH = 'Electronic Credit ACH',
  ELECTRONIC_DEBIT_ACH = 'Electronic Debit ACH',
  FED_WIRE_NON_REPETITIVE = 'Fedwire Non-repetitive',
  FED_WIRE_REPETITIVE = 'Fedwire Repetitive',
  INTERNATIONAL_WIRE = 'International Wire',
  LETTER_OF_CREDIT = 'Letter of Credit',
  ONLINE_PAYMENT = 'Online Payment',
  ONLINE_PAYMENT_APPLE_PAY = 'Apple Pay',
  ONLINE_PAYMENT_GOOGLE_WALLET = 'Google Wallet',
  ONLINE_PAYMENT_PAYPAL = 'PayPal',
  POSTGIRO = 'Postgiro',
  WIRE_TRANSFER_CREDIT = 'Wire Transfer Credit',
  WIRE_TRANSFER_DEBIT = 'Wire Transfer Debit',
}
