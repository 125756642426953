import translationCa from './ca/translation.json';
import translationEs from './es/translation.json';
import translationEn from './en/translation.json';
import translationFr from './fr/translation.json';

export const SharedComponentsTranslations = {
  ca: translationCa,
  es: translationEs,
  en: translationEn,
  fr: translationFr,
};
