import {FC, useEffect, useState} from 'react';
import DatePickerReact, {ReactDatePickerProps} from 'react-datepicker';

type PickerProps = ReactDatePickerProps<never, false>;

export type OnChangeDate = (date: Date | null) => void;

type BaseProps = {
  date?: Date;
  onChange?: OnChangeDate;
};

export type SingleProps = Omit<PickerProps, 'selectsRange' | 'onChange'> & BaseProps;

const DatePickerSingle: FC<SingleProps> = ({date: inputDate, onChange, ...props}) => {
  const [date, setDate] = useState<Date | null>(inputDate || new Date());

  const changeDate = (newDate: Date | null) => {
    setDate(newDate);
    onChange && onChange(newDate);
  };

  useEffect(() => setDate(inputDate as Date | null), [inputDate]);

  return <DatePickerReact {...props} selectsRange={false} selected={date} onChange={date => changeDate(date)} />;
};

export default DatePickerSingle;
