import {CLIENT_ERROR_CODES, CLIENT_ERROR_TYPES} from '../constants';

export class BaseServerError extends Error {
  code: number;
  type: string;
  data: any;

  constructor(message: string, data?: any) {
    super(message);
    this.code = CLIENT_ERROR_CODES.BASE;
    this.type = CLIENT_ERROR_TYPES.BASE;
    this.data = data;
  }
}
