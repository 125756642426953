import React, {useContext} from 'react';
import {SharedComponentsSettings} from '../types';

export function configReducer(state: any, action: any) {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        config: action.config,
      };
    default:
      return state;
  }
}

export const SharedContext = React.createContext({
  state: {},
  dispatch: () => null,
});

export function useConfig(): [SharedComponentsSettings, (c: SharedComponentsSettings) => void] {
  const [state, dispatch] = useContext<any>(SharedContext);

  return [
    state.config,
    (configp: SharedComponentsSettings) => {
      dispatch({type: 'set', config: configp});
    },
  ];
}
