import React, {createContext, useState} from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import {useTranslation} from 'react-i18next';

type ButtonData = {
  onPress: () => void;
  label?: string;
  variant?: string;
};

type ConfirmationDialogContextType = {
  showConfirmationDialog: (title: string, content: React.ReactNode, okButton: ButtonData, cancelButton?: ButtonData, closeOnOverlayClick?: boolean) => void;
  hideConfirmationDialog: () => void;
};

type Props = {
  children: React.ReactNode;
};

export const ConfirmationDialogContext = createContext<ConfirmationDialogContextType | undefined>(undefined);

export const ConfirmationDialogProvider: React.FC<Props> = ({children}) => {
  const {t} = useTranslation();
  const [isConfirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const [confirmationDialogData, setConfirmationDialogData] = useState<{
    title: string;
    content: React.ReactNode;
    okButton: ButtonData;
    cancelButton?: ButtonData | undefined;
    closeOnOverlayClick?: boolean;
  }>({
    title: '',
    content: '',
    okButton: {onPress: () => {}},
    cancelButton: undefined,
    closeOnOverlayClick: true,
  });

  const showConfirmationDialog = (title: string, content: React.ReactNode, okButton: ButtonData, cancelButton?: ButtonData, closeOnOverlayClick?: boolean) => {
    setConfirmationDialogData({title, content, okButton, cancelButton, closeOnOverlayClick});
    setConfirmationDialogVisible(true);
  };

  const hideConfirmationDialog = () => {
    setConfirmationDialogVisible(false);
  };

  return (
    <>
      <ConfirmationDialogContext.Provider value={{showConfirmationDialog, hideConfirmationDialog}}>
        {children}
        <ConfirmationDialog
          title={confirmationDialogData.title}
          content={confirmationDialogData.content}
          okButton={confirmationDialogData.okButton}
          cancelButton={confirmationDialogData.cancelButton}
          visible={isConfirmationDialogVisible}
          onClose={hideConfirmationDialog}
          closeOnOverlayClick={confirmationDialogData.closeOnOverlayClick}
        />
      </ConfirmationDialogContext.Provider>
    </>
  );
};
