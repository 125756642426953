import {useEffect, useRef, useState} from 'react';
import {GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs} from 'react-google-maps';
import {google} from 'google-maps';
import PropTypes from 'prop-types';
import {View} from 'native-base';
import {ResponsiveValue} from 'native-base/lib/typescript/components/types';

type Props = {
  locations?: Array<{
    coords: {lat: number; lng: number};
    icon?: {symbol: google.maps.Symbol; anchor: {x: number; y: number}};
    info?: {title: string; description: string};
  }>;
  zoom?: number;
  center?: {coords: {lat: number; lng: number}};
  rounded?: string;
  apikey: string;
  iconsHidden?: boolean;
};

const styles: Record<string, google.maps.MapTypeStyle[]> = {
  default: [],
  hide: [
    {
      featureType: 'poi.business',
      stylers: [{visibility: 'off'}],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}],
    },
  ],
};

const MyMapComponent = withScriptjs(
  withGoogleMap((props: Props) => {
    const map = useRef<GoogleMap | null>(null);

    const [selectedMarker, setSelectedMarker] = useState<any>(null);

    useEffect(() => {
      if (!props.zoom) {
        const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
        props.locations?.forEach(location => bounds.extend(location.coords));
        map.current?.fitBounds(bounds);
      }
    }, [map, props.locations]);
    const handleMarkerClick = (location: any) => {
      setSelectedMarker(location);
    };

    const handleInfoWindowClose = () => {
      setSelectedMarker(null);
    };
    return (
      <GoogleMap
        zoom={props.zoom ? props.zoom : undefined}
        ref={map}
        defaultCenter={
          props.center
            ? props.center.coords
            : props.locations
            ? props.locations.length > 0
              ? props.locations[0].coords
                ? props.locations[0].coords
                : undefined
              : undefined
            : undefined
        }
        defaultOptions={{
          disableDefaultUI: true,
          styles: props.iconsHidden ? styles.hide : [],
        }}>
        {props.locations?.map((location, index) => (
          // todo: Spiderfy
          <Marker
            key={index}
            position={location?.coords}
            icon={location?.icon ? {...location?.icon.symbol, anchor: new google.maps.Point(location.icon.anchor.x, location.icon.anchor.y)} : undefined}
            onClick={() => handleMarkerClick(location)}>
            {selectedMarker && selectedMarker.info && selectedMarker === location && (
              <InfoWindow position={selectedMarker.position} onCloseClick={handleInfoWindowClose}>
                <div style={{color: 'rgb(0, 89, 167)'}}>
                  <h3>{selectedMarker.info.title}</h3>
                  <p>{selectedMarker.info.description}</p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    );
  })
);

export const Map: React.FC<Props> = props => (
  <MyMapComponent
    googleMapURL={props.apikey}
    loadingElement={<View flex={1} />}
    containerElement={<View flex={1} />}
    mapElement={<View flex={1} rounded={props.rounded ? props.rounded : 'lg'} />}
    {...props}
  />
);

Map.propTypes = {
  apikey: PropTypes.string.isRequired,
  rounded: PropTypes.string,
};
