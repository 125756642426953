import {useBaseService} from '../modules';
import {ServiceFunctionsType, UsersServiceTypes} from '@bri/shared-core';

export function useUserService() {
  const newBaseService = useBaseService();

  const userServiceFunctions: ServiceFunctionsType<UsersServiceTypes> = {
    create: params => newBaseService().auth().post('/v1/users/create', params),
    edit: params => newBaseService().auth().put('/v1/users/edit', params),
    get: params => newBaseService().auth().get('/v1/users/get', params),
    list: () => newBaseService().auth().get('/v1/users/list'),
    remove: params => newBaseService().auth().delete('/v1/users/remove', params),
  };

  return userServiceFunctions;
}
