import {COOKIES_TYPES} from '../../constants';
import {StorageService} from '../../services/StorageService';

export const CookiesService = {
  /**
   * Set Cookie by type
   */
  async setType(type: COOKIES_TYPES, key: string, value: string | number | boolean | string[]) {
    const jsonCookies: any = {};

    if ((await this.existType(type)) || type === key) {
      const result = await StorageService.getItem(type);
      if (result) {
        const jsonObject: any = JSON.parse(result);
        jsonObject[key] = value;
        await StorageService.setItem(type, JSON.stringify(jsonObject));
        return;
      }

      jsonCookies[key] = value;
      await StorageService.setItem(type, JSON.stringify(jsonCookies));
    }
  },

  /**
   * Has cookie key by type
   */
  async hasKey(type: COOKIES_TYPES, key: string) {
    return (await this.getType(type, key)) != null;
  },

  /**
   * Get Cookie by type
   */
  async getType(type: COOKIES_TYPES, key: string) {
    const result = await StorageService.getItem(type);
    if (result) {
      const jsonObject: any = JSON.parse(result);
      if (jsonObject[key]) {
        return jsonObject[key];
      }
    }
    return null;
  },

  /**
   * Exists Cookie Type
   */
  async existType(type: COOKIES_TYPES) {
    const result = await this.getType(type, type);
    return !!result;
  },

  /**
   * Remove cookie type
   */
  async removeType(type: COOKIES_TYPES) {
    await StorageService.removeItem(type);
  },

  /**
   * Remove cookie by type
   */
  async removeKey(type: COOKIES_TYPES, key: string) {
    const result = await StorageService.getItem(type);
    if (result) {
      const jsonObject: any = JSON.parse(result);
      if (jsonObject[key]) {
        delete jsonObject[key];
        await StorageService.setItem(type, JSON.stringify(jsonObject));
      }
    }
  },

  /**
   * Remove cookie by type
   */
  async removeAll() {
    await StorageService.clear();
  },
};
