import {IBase} from './ibase';

export enum EVENT_TYPES {
  MINT = 'MINT',
  TRANSFER = 'TRANSFER',
  RECEIVED_NFT = 'RECEIVED_NFT',
  RECEIVED_ERC20 = 'RECEIVED_ERC20',
  DIGITAL_TWIN_GENERATED = 'DIGITAL_TWIN_GENERATED',
  TRANSFER_ERC20 = 'TRANSFER_ERC20',
  TOKEN_DEPLOYED = 'TOKEN_DEPLOYED',
  TRANSFER_CHILD = 'TRANSFER_CHILD',
  TRANSFER_NFT = 'TRANSFER_NFT',
}

export type IBlockchainCommonTransactionBase = IBase & {
  tokenId: string;
  sender: string;
  txHash: string;
  timestamp: string | Date;
  metadataModel: string;
  metadataId: string;
};

export type IBlockchainMintTransaction = IBlockchainCommonTransactionBase & {
  type: EVENT_TYPES.MINT;
  owner: string;
};

export type IBlockchainTransferTransaction = IBlockchainCommonTransactionBase & {
  type: EVENT_TYPES.TRANSFER;
  owner: string;
  from: string;
  to: string;
};

export type IBlockchainReceivedErc20Transaction = IBlockchainCommonTransactionBase & {
  type: EVENT_TYPES.RECEIVED_ERC20;
  erc20Contract: string;
  amount: number;
  accumulatedAmount: number;
  fromAddress: string;
};

export type IBlockchainReceivedNftTransaction = IBlockchainCommonTransactionBase & {
  type: EVENT_TYPES.RECEIVED_NFT;
  nftId: number;
};

export type IBlockchainTransferErc223Transaction = IBlockchainCommonTransactionBase & {
  type: EVENT_TYPES.TRANSFER_ERC20;
  erc20Contract: string;
  toAddress: string;
  toTokenId: string;
  amount: number;
  remainingAmount: number;
};

export type IBlockchainSwapTransaction = IBlockchainCommonTransactionBase & {
  type: EVENT_TYPES.DIGITAL_TWIN_GENERATED;
  generatedTokenId: string;
  generatedTokenOwner: string;
};

export type IBlockchainTransferChildTransaction = IBlockchainCommonTransactionBase & {
  type: EVENT_TYPES.TRANSFER_CHILD;
  nftContract: string;
  nftId: string;
  toAddress: string;
};

export type IBlockchainTransferNFTTransaction = IBlockchainCommonTransactionBase & {
  type: EVENT_TYPES.TRANSFER_NFT;
  nftId: string;
  fromAddress: string;
  toAddress: string;
};

export type IBlockchainTransactionBase =
  | IBlockchainMintTransaction
  | IBlockchainTransferTransaction
  | IBlockchainReceivedErc20Transaction
  | IBlockchainTransferErc223Transaction
  | IBlockchainReceivedNftTransaction
  | IBlockchainSwapTransaction
  | IBlockchainTransferChildTransaction
  | IBlockchainTransferNFTTransaction;
