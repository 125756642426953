import React, {FC, useRef, useState} from 'react';
import {HStack, View, Text, useTheme} from 'native-base';
import {TouchableOpacity} from 'react-native';
import {useTranslation} from 'react-i18next';
export type Props = {
  content: string;
  maxLines?: number;
  options?: string[];
  fontSize?: number | string;
  justify?: boolean;
};
const CHARWIDTH: number = 0.465;
export const DynamicText: FC<Props> = ({content, maxLines = 3, options = ['Read Less', 'Read More'], fontSize = 'sm', justify = false}) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const {fontSizes} = theme;
  const sizeChar: number = typeof fontSize === 'string' ? fontSizes[fontSize as keyof typeof fontSizes] : fontSize;
  const [expanded, setExpanded] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const handleOnLayout = (event: any) => {
    const nChar = content.length * (CHARWIDTH * sizeChar);
    const nLines = nChar / event.nativeEvent.layout.width;
    setShowMoreButton(nLines > maxLines);
  };
  const handleToggleText = () => {
    setExpanded(!expanded);
  };

  return (
    <View>
      <Text
        fontSize={fontSize}
        numberOfLines={expanded ? undefined : maxLines}
        ellipsizeMode="clip"
        textAlign={justify ? 'justify' : 'left'}
        onLayout={event => {
          handleOnLayout(event);
        }}>
        {content}
      </Text>
      {showMoreButton && (
        <HStack mt={4}>
          <TouchableOpacity onPress={handleToggleText} accessibilityRole="button">
            <Text style={{fontWeight: '300', textDecorationLine: 'underline', color: 'blue'}}>{expanded ? t(options[0]) : t(options[1])}</Text>
          </TouchableOpacity>
        </HStack>
      )}
    </View>
  );
};
