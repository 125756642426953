type CodeDescriptions = Record<string, string>;

// Function to get a description by code
export function getMARPOLByCode(code: string): string | undefined {
  return MARPOL_TYPE[code];
}

const MARPOL_TYPE: CodeDescriptions = {
  // MARPOL Annex I - related
  '101': 'Oily Bilge water',
  '102': 'Oily Residues (Sludge)',
  '103': 'Oily tank washings (Slops)',
  '104': 'Dirty ballast water',
  '105': 'Scale and sludge from tank cleaning',
  '999': 'Other (please specify)',

  // MARPOL Annex II - related
  '201': 'Category X substance - Indicate the proper shipping name of the NLS involved',
  '202': 'Category Y substance - Indicate the proper shipping name of the NLS involved ',
  '203': 'Category Z substance  - Indicate the proper shipping name of the NLS involved',
  '204': 'OS - other substances - Indicate the proper shipping name of the NLS involved',

  // MARPOL Annex IV – related
  '401': 'Sewage',

  // MARPOL Annex V – related
  '501': 'A. Plastics',
  '502': 'B. Food waste',
  '503': 'C. Domestic wastes',
  '504': 'D. Cooking oil',
  '505': 'E. Incinerator ashes',
  '506': 'F. Operational wastes',
  '507': 'G. Animal carcasses',
  '508': 'H. Fishing gear',
  '509': 'I. E-waste',
  '510': 'J. Cargo residues (Non-HME) - Indicate the proper shipping name of the dry cargo',
  '511': 'K. Cargo residues (HME) - Indicate the proper shipping name of the dry cargo',

  // MARPOL Annex VI – related
  '601': 'Ozone-depleting substances and equipment containing such substances | Exhaust gas-cleaning residues',
  '991': 'Passively fished waste',
};
