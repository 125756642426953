import React, {FC} from 'react';
import {Image} from 'native-base';

type Props = {
  uri: string;
};

export const BackgroundImage: FC<Props> = (props: Props) => {
  return (
    <Image
      source={{
        uri: props.uri,
      }}
      alt="background"
      width="100%"
      height="100%"
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    />
  );
};
