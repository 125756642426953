import {CertificationDetails, IProduct, Place, QuantitativeValue, ReferencedFileDetails} from './gs1';
import {IEPCISEvent} from './epcis';
import {DANGEROUS_INDICATOR_TYPE} from './types/dangerousIndicator';
import {PROCESS_IDENTIFICATION_TYPE} from './types/PI';

//* DPP -> FINAL
type DigitalProductPassport_ImmutableData = {
  name: string;
  description: string;
  identifier: string;
  URL: string;
  gtin?: string;
  image: string[];
  creation_date: Date;
  total_weight: number;
  origin: {
    countryOfOrigin?: string;
    organization: string;
    center: string;
    container: string;
  };
  additionalProperties: {
    [key: string]: any;
  };
  category: DPP_TYPE;
  components: DigitalProductPassport_ImmutableData['identifier'][];
  blockchain: {
    published: boolean;
    hash?: string;
    address?: string;
    avaliableERC20: {
      [key: string]: number;
    };
    owned: boolean;
  };
  [key: string]: any;
};

type DigitalProductPassport_SustainabilityIndicators = {
  circularityIndex: {
    recycledMaterialUsage: number; // material reciclado utilizado
    recyclabilityValoritzation: number; // parte reciclable del producto al fin del ciclo de vida previsto
    carbonFootprint: number; // Co2de/Tn
    carbonSavingsByCircularBuy: {
      generatedCarbonFootprint: number; // Co2 e generado por el producto
      otherInitiatives: number; // Co2 e generado por el producto
    };
  };
  tracedMaterial: number; // percentage of the traced components via dpp
  originOfMateiral: {type: ORIGIN_MATERIAL_TYPE; value: number}[]; // Material origin breakdown (pre -> never sold/problems | pos -> end of life it gets recycled)
  circularityIndicator: number; // parte reciclable del producto al fin del ciclo de vida real
  endOfLifeResidues: {type: string; value: number}[]; //TODO definir tipos de residuos
  materialsUsedInProduction: {category: STRATEGIC_UE_MATERIALS_TYPE; type: string; value: number}; // Materials used in the dpp production by category
  geographicalOriginOfMateiral: {country: string; value: number}[]; // Number of materials by country
  sustainabilityIndex: {
    energyUsedInProduction: {type: string; value: number}[]; //breakdown of energy used in the various production procceses
    productionCarbonFootprint: number; // Co2de/Tn generated only in transformation events
    productionCarbonSavingsByCircularBuy: {
      generatedCarbonFootprint: number; // generated Co2 by the product in production processes.
      otherInitiatives: number; // expected generated Co2 by the product in production processes.
    };
    productionGeneratedResiudes: {type: string; value: number}[]; // breakdown of generated residues in production processes
  };
  distributionImpact: {
    carbonFootprint: number; // generated carbon footprint caused by all the distribution events
    carbonSavingsByCircularBuy: {
      generatedCarbonFootprint: number; // generated Co2 by the product in the distribution proccesses.
      otherInitiatives: number; // generated Co2 by the product in distribution processes.
    };
    carbonFootprintByTransportationType: {type: TRANSPORTATION_TYPE; value: number};
    transportationTypeBreakdown: {type: TRANSPORTATION_TYPE; value: number};
    usedEnergyInDistribution: {type: ENERGY_TYPE; value: number};
  };
  [key: string]: any;
};

export enum TRANSPORTATION_TYPE {
  '01' = 'AERIAL',
  '02' = 'MARITIME',
  '03' = 'TERRESTRIAL',
  '04' = 'RAILWAY',
  '05' = 'TUBE',
}

export enum ENERGY_TYPE {
  RENEWABLE = 'renewable',
  NON_RENEWABLE = 'non-renewable',
}

export enum STRATEGIC_UE_MATERIALS_TYPE {
  CRITICAL = 'Critical',
  DANGEROUS = 'Dangerous',
}

export enum DPP_TYPE {
  WASTE = 'WASTE',
  BATTERIES = 'BATTERIES',
  CONTAINERS = 'CONTAINERS',
  ELECTRONIC_PRODUCTS = 'ELECTRONIC_PRODUCTS',
}

export enum ORIGIN_MATERIAL_TYPE {
  PRE_CONSUMPTION = 'Pre-Consumption',
  POS_CONSUMPTION = 'Pos-Consumption',
  LINEAL = 'Lineal',
}

export type DigitalProductPassport_Type_Waste = {
  NT?: string; // Codigo identificador previo
  DI: string; // Docunmento identificacion de resíduo (movimiento resiudo en españa)
  LER?: string; // Codigo residuo
  MARPOL?: string; // Codigo resiudo marítimo
  PI: PROCESS_IDENTIFICATION_TYPE; // Identificador del proceso https://www.miteco.gob.es/content/dam/miteco/es/calidad-y-evaluacion-ambiental/publicaciones/e-sir_esquemas_e3l_v12_tcm30-547750.pdf
  dangerousIndicator: DANGEROUS_INDICATOR_TYPE; // Características de los residuos peligrosos anexo III del REGLAMENTO (UE) 1357/2014.
  [key: string]: any;
};

export type DigitalProductPassport_Type_Batteries = {
  [key: string]: any; //TODO
};

export type DigitalProductPassport_Type_Containers = {
  [key: string]: any; //TODO
};

export type DigitalProductPassport_Type_Electronic_Products = {
  [key: string]: any; //TODO
};

export type IEPCISEventExtended = Partial<IEPCISEvent> & {
  organization: any;
  eventType: string;
  transformationID: string;
  epcList: string[];
  images: string[];
  imageGroup: string;
  description: string;
  productionWaste: {
    label: string;
    percentage: number;
  }[];
  carbonFootprint: QuantitativeValue;
  co2savingsByCircularPurchase: {
    savings: QuantitativeValue;
    breakdown: {
      data: KeyValue;
      percent: number;
    }[];
  };
  materialOrigin: {
    label: string;
    percentage: number;
  }[];
  productionEnergy: {
    label: string;
    units: string;
    value: number;
  }[];
  certificates: CertificationDetails[];
};

type KeyValue = {
  [key: string]: any;
};

type Locations = {
  type: string;
  properties: {
    Name: string;
  };
  geometry: {
    type: string;
    coordinates: number[][];
  };
};

export type IDigitalProductPassport = {
  '@context': string; // http://client.blueroominnovation.org/
  '@type': 'DigitalProductPassport' | 'Url';
} & DigitalProductPassport_ImmutableData &
  Partial<DigitalProductPassport_SustainabilityIndicators> &
  (
    | Partial<DigitalProductPassport_Type_Waste>
    | Partial<DigitalProductPassport_Type_Batteries>
    | Partial<DigitalProductPassport_Type_Containers>
    | Partial<DigitalProductPassport_Type_Electronic_Products>
  );
