export enum ColourCodeListCode {
  BUYER = 'Assigned by Buyer',
  IFPS = 'International Federation for Produce Standards',
  NCS = 'Natural Colour System',
  NRF = 'National Retail Federation.',
  PANTONE_HEXACHROME = 'Pantone Hexachrome',
  PANTONE_MATCHING = 'Pantone Matching System',
  PANTONE_PROCESS_COLOUR = 'Pantone Process Colour System',
  PANTONE_TEXTILE = 'Pantone Textile',
  RAL = 'Farbsystem RAL Colour System',
  SELLER = 'Assigned by Seller',
  WWS = 'Waren Wirtschafts System',
}

export type ColourCodeDetails = {
  colourCodeList: Partial<ColourCodeListCode>;
  colourCodeValue: string;
};
