import {InternalEventsTypes} from 'types';
import {INTERNAL_EVENTS} from '../../constants';

type ListenerType<E extends INTERNAL_EVENTS> = (data: InternalEventsTypes[E]) => void;

/**
 * Event bus class
 */
export class EventBus<TD extends InternalEventsTypes, E extends INTERNAL_EVENTS> {
  static instance: EventBus<InternalEventsTypes, INTERNAL_EVENTS>;

  eventListeners: any = {};

  static getInstance<E extends INTERNAL_EVENTS, TD extends InternalEventsTypes>(): EventBus<TD, E> {
    if (typeof EventBus.instance === 'object' && EventBus.instance) {
      return EventBus.instance;
    }
    return new EventBus<TD, E>();
  }

  constructor() {
    if (typeof EventBus.instance === 'object') {
      return EventBus.instance;
    }
    EventBus.instance = this;
  }

  fireEvent<E extends INTERNAL_EVENTS>(eventName: E, data?: TD[E]) {
    const listeners = this.eventListeners[eventName];
    if (Array.isArray(listeners)) {
      listeners.forEach(listener => {
        if (typeof listener === 'function') {
          listener(data);
        }
      });
    }
  }

  addListener<E extends INTERNAL_EVENTS>(eventName: E, listener: (data: InternalEventsTypes[E]) => void) {
    const listeners = this.eventListeners[eventName];
    if (Array.isArray(listeners)) {
      listeners.push(listener);
    } else {
      this.eventListeners[eventName] = [listener];
    }
  }

  removeListener(listener: ListenerType<any>) {
    Object.keys(this.eventListeners).forEach(eventName => {
      const listeners = this.eventListeners[eventName];
      this._remove(listeners, listener);
      if (listeners.length === 0) {
        delete this.eventListeners[eventName];
      }
    });
  }

  _remove(array: any[], item: any) {
    if (!array) return;
    for (let i = 0, l = array.length; i < l; i++) {
      if (item === array[i]) array.splice(i, 1);
    }
  }
}

export function useEventBus<TD extends InternalEventsTypes, E extends INTERNAL_EVENTS>() {
  return EventBus.getInstance<E, TD>();
}
