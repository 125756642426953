import {AnyURI} from '../xsd/anyURI';
import {LangString} from '../rdf/langString';
import {Organization} from './organization';
import {Thing} from '../owl/thing';

export enum CertificationStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export type CertificationDetails = {
  certificationAgency: Partial<LangString>;
  certificationAgencyURL: Partial<Organization>;
  certificationAuditDate: Date;
  certificationEndDate: Date;
  certificationIdentification: string;
  certificationStandard: Partial<LangString>;
  certificationStartDate: Date;
  certificationStatement: Partial<LangString>;
  certificationStatus: Partial<CertificationStatus>;
  certificationSubject: Partial<Thing>;
  certificationType: Partial<Thing>;
  certificationURI: Partial<AnyURI>;
  certificationValue: Partial<LangString>;
  initialCertificationDate: Date;
};
