type CodeDescriptions = Record<string, string>;

// Function to get a description by code
export function getLERByCode(code: string): string | undefined {
  return LER_TYPE[code];
}

export const LER_TYPE: CodeDescriptions = {
  '010101': 'Residuos de la extracción de minerales metálicos',
  '010102': 'Residuos de la extracción de minerales no metálicos',

  '010304': 'Estériles que generan ácido procedentes de la transformación de minerales sulfurados',
  '010305': 'Otros estériles que contienen sustancias peligrosas',
  '010306': 'Estériles distintos de los mencionados en los códigos 01 03 04 y 01 03 05',
  '010307': 'Otros residuos que contienen sustancias peligrosas procedentes de la transformación física y química de minerales metálicos',
  '010308': 'Residuos de polvo y arenilla distintos de los mencionados en el código 01 03 07',
  '010309': 'Lodos rojos de la producción de alúmina distintos de los mencionados en el código 01 03 10',
  '010310': 'Lodos rojos procedentes de la producción de alúmina que contienen sustancias peligrosas distintos de los residuos mencionados en el código 010307',
  '010399': 'Residuos no especificados en otra categoría',
  '010407': 'Residuos que contienen sustancias peligrosas procedentes de la transformación física y química de minerales no metálicos',
  '010408': 'Residuos de grava y rocas trituradas distintos de los mencionados en el código 01 04 07',
  '010409': 'Residuos de arena y arcillas',
  '010410': 'Residuos de polvo y arenilla distintos de los mencionados en el código 01 04 07',
  '010411': 'Residuos de la transformación de potasa y sal gema distintos de los mencionados en el código 01 04 07',
  '010412': 'Estériles y otros residuos del lavado y limpieza de minerales, distintos de los mencionados en los códigos 01 04 07 y 01 04 11',
  '010413': 'Residuos del corte y serrado de piedra distintos de los mencionados en el código 01 04 07',
  '010499': 'Residuos no especificados en otra categoría',
  '010504': 'Lodos y residuos de perforaciones que contienen agua dulce',
  '010505': 'Lodos y residuos de perforaciones que contienen hidrocarburos',
  '010506': 'Lodos y otros residuos de perforaciones que contienen sustancias peligrosas',

  '010507': 'Lodos y otros residuos de perforaciones que contienen barita distintos de los mencionados en los códigos 01 05 05 y 01 05 06',
  '010508': 'Lodos y otros residuos de perforaciones que contienen cloruros distintos de los mencionados en los códigos 01 05 05 y 01 05 06',
  '010599': 'Residuos no especificados en otra categoría',
  '020101': 'Lodos de lavado y limpieza',
  '020102': 'Residuos de tejidos de animales',
  '020103': 'Residuos de tejidos de vegetales',
  '020104': 'Residuos de plásticos (excepto embalajes)',
  '020106': 'Heces de animales, orina y estiércol (incluida paja podrida), efluentes, recogidos selectivamente y tratados fuera del lugar donde se generan',
  '020107': 'Residuos de la silvicultura',
  '020108': 'Residuos agroquímicos que contienen sustancias peligrosas',
  '020109': 'Residuos agroquímicos distintos de los mencionados en el código 02 01 08',
  '020110': 'Residuos metálicos',
  '020199': 'Residuos no especificados en otra categoría',
  '020201': 'Lodos de lavado y limpieza',
  '020202': 'Residuos de tejidos de animales',
  '020203': 'Materiales inadecuados para el consumo o la elaboración',
  '020204': 'Lodos del tratamiento in situ de efluentes',
  '020299': 'Residuos no especificados en otra categoría',
  '020301': 'Lodos de lavado, limpieza, pelado, centrifugado y separación',
  '020302': 'Residuos de conservantes',
  '020303': 'Residuos de la extracción con disolventes',
  '020304': 'Materiales inadecuados para el consumo o la elaboración',
  '020305': 'Lodos del tratamiento in situ de efluentes',

  '020399': 'Residuos no especificados en otra categoría',
  '020401': 'Tierra procedente de la limpieza y lavado de la remolacha',
  '020402': 'Carbonato cálcico fuera de especificación',
  '020403': 'Lodos del tratamiento in situ de efluentes',
  '020499': 'Residuos no especificados en otra categoría',
  '020501': 'Materiales inadecuados para el consumo o la elaboración',
  '020502': 'Lodos del tratamiento in situ de efluentes',
  '020599': 'Residuos no especificados en otra categoría',
  '020601': 'Materiales inadecuados para el consumo o la elaboración',
  '020602': 'Residuos de conservantes',
  '020603': 'Lodos del tratamiento in situ de efluentes',
  '020699': 'Residuos no especificados en otra categoría',
  '020701': 'Residuos de lavado, limpieza y reducción mecánica de materias primas',
  '020702': 'Residuos de la destilación de alcoholes',
  '020703': 'Residuos del tratamiento químico',
  '020704': 'Materiales inadecuados para el consumo o la elaboración',
  '020705': 'Lodos del tratamiento in situ de efluentes',
  '020799': 'Residuos no especificados en otra categoría',
  '030101': 'Residuos de corteza y corcho',
  '030104': 'Serrín, virutas, recortes, madera, tableros de partículas y chapas que contienen sustancias peligrosas',
  '030105': 'Serrín, virutas, recortes, madera, tableros de partículas y chapas distintos de los mencionados en el código 03 01 04',
  '030199': 'Residuos no especificados en otra categoría',
  '030201': 'Conservantes de la madera orgánicos no halogenados',
  '030202': 'Conservantes de la madera organoclorados',

  '030203': 'Conservantes de la madera organometálicos',
  '030204': 'Conservantes de la madera inorgánicos',
  '030205': 'Otros conservantes de la madera que contienen sustancias peligrosas',
  '030299': 'Conservantes de la madera no especificados en otra categoría',
  '030301': 'Residuos de corteza y madera',
  '030302': 'Lodos de lejías verdes (procedentes de la recuperación de lejías de cocción)',
  '030305': 'Lodos de destintado procedentes del reciclado de papel',
  '030307': 'Desechos, separados mecánicamente, de pasta elaborada a partir de residuos de papel y cartón',
  '030308': 'Residuos procedentes de la clasificación de papel y cartón destinados al reciclado',
  '030309': 'Residuos de lodos calizos',
  '030310': 'Desechos de fibras y lodos de fibras, de materiales de carga y de estucado, obtenidos por separación mecánica',
  '030311': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 03 03 10',
  '030399': 'Residuos no especificados en otra categoría',
  '040101': 'Residuos de descarnaduras y cuarteado de cal',
  '040102': 'Residuos de encalado',
  '040103': 'Residuos de desengrasado que contienen disolventes sin fase líquida',
  '040104': 'Residuos líquidos de curtición que contienen cromo',
  '040105': 'Residuos líquidos de curtición que no contienen cromo',
  '040106': 'Lodos, en particular los procedentes del tratamiento in situ de efluentes, que contienen cromo',
  '040107': 'Lodos, en particular los procedentes del tratamiento in situ de efluentes, que no contienen cromo',
  '040108': 'Residuos de piel curtida (serrajes, rebajaduras, recortes y polvo de esmerilado) que contienen cromo',
  '040109': 'Residuos de confección y acabado',

  '040199': 'Residuos no especificados en otra categoría',
  '040209': 'Residuos de materiales compuestos (textiles impregnados, elastómeros, plastómeros)',
  '040210': 'Materia orgánica de productos naturales (por ejemplo, grasa, cera)',
  '040214': 'Residuos del acabado que contienen disolventes orgánicos',
  '040215': 'Residuos del acabado distintos de los especificados en el código 04 02 14',
  '040216': 'Colorantes y pigmentos que contienen sustancias peligrosas',
  '040217': 'Colorantes y pigmentos distintos de los mencionados en el código 04 02 16',
  '040219': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '040220': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 04 02 19',
  '040221': 'Residuos de fibras textiles no transformadas',
  '040222': 'Residuos de fibras textiles transformadas',
  '040299': 'Residuos no especificados en otra categoría',
  '050102': 'Lodos de la desalación',
  '050103': 'Lodos de fondos de tanques',
  '050104': 'Lodos de ácidos alquilo',
  '050105': 'Derrames de hidrocarburos',
  '050106': 'Lodos oleosos procedentes de operaciones de mantenimiento de plantas o equipos',
  '050107': 'Alquitranes ácidos',
  '050108': 'Otros alquitranes',
  '050109': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '050110': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 05 01 09',
  '050111': 'Residuos procedentes de la limpieza de combustibles con bases',
  '050112': 'Hidrocarburos que contienen ácidos',

  '050113': 'Lodos procedentes del agua de alimentación de calderas',
  '050114': 'Residuos de columnas de refrigeración',
  '050115': 'Arcillas de filtración usadas',
  '050116': 'Residuos que contienen azufre procedentes de la desulfuración del petróleo',
  '050117': 'Betunes',
  '050199': 'Residuos no especificados en otra categoría',
  '050601': 'Alquitranes ácidos',
  '050603': 'Otros alquitranes',
  '050604': 'Residuos de columnas de refrigeración',
  '050699': 'Residuos no especificados en otra categoría',
  '050701': 'Residuos que contienen mercurio',
  '050702': 'Residuos que contienen azufre',
  '050799': 'Residuos no especificados en otra categoría',
  '060101': 'Ácido sulfúrico y ácido sulfuroso',
  '060102': 'Ácido clorhídrico',
  '060103': 'Ácido fluorhídrico',
  '060104': 'Ácido fosfórico y ácido fosforoso',
  '060105': 'Ácido nítrico y ácido nitroso',
  '060106': 'Otros ácidos',
  '060199': 'Residuos no especificados en otra categoría',
  '060201': 'Hidróxido cálcico',
  '060203': 'Hidróxido amónico',
  '060204': 'Bases',
  '060205': 'Otras bases',
  '060299': 'Residuos no especificados en otra categoría',
  '060311': 'Sales sólidas y soluciones que contienen cianuros',
  '060313': 'Sales sólidas y soluciones que contienen metales pesados',

  '060314': 'Sales sólidas y soluciones distintas de las mencionadas en los códigos 06 03 11 y 06 03 13',
  '060315': 'Óxidos metálicos que contienen metales pesados',
  '060316': 'Óxidos metálicos distintos de los mencionados en el código 06 03 15',
  '060399': 'Residuos no especificados en otra categoría',
  '060403': 'Residuos que contienen arsénico',
  '060404': 'Componentes con mercurio',
  '060405': 'Residuos que contienen otros metales pesados',
  '060499': 'Residuos no especificados en otra categoría',
  '060502': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '060503': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 06 05 02',
  '060602': 'Residuos que contienen sulfuros peligrosos',
  '060603': 'Residuos que contienen sulfuros distintos de los mencionados en el código 06 06 02',
  '060699': 'Residuos no especificados en otra categoría',
  '060701': 'Residuos de electrólisis que contienen amianto',
  '060702': 'Carbón activo procedente de la producción de cloro',
  '060703': 'Lodos de sulfato bárico que contienen mercurio',
  '060704': 'Ácidos',
  '060799': 'Residuos no especificados en otra categoría',
  '060802': 'Residuos que contienen clorosilanos peligrosos',
  '060899': 'Residuos no especificados en otra categoría',
  '060902': 'Escorias de fósforo',
  '060903': 'Residuos cálcicos de reacción que contienen o están contaminados con sustancias peligrosas',
  '060904': 'Residuos cálcicos de reacción distintos de los mencionados en el código 06 09 03',
  '060999': 'Residuos no especificados en otra categoría',
  '061002': 'Residuos que contienen sustancias peligrosas',

  '061099': 'Residuos no especificados en otra categoría',
  '061101': 'Residuos cálcicos de reacción procedentes de la producción de dióxido de titanio',
  '061199': 'Residuos no especificados en otra categoría',
  '061301': 'Productos fitosanitarios inorgánicos, conservantes de la madera y otros biocidas',
  '061302': 'Carbón activo usado (excepto la categoría 06 07 02)',
  '061303': 'Negro de carbón',
  '061304': 'Residuos procedentes de la transformación del amianto',
  '061305': 'Hollín',
  '061399': 'Residuos no especificados en otra categoría',
  '070101': 'Líquidos de limpieza y licores madre acuosos',
  '070103': 'Disolventes, líquidos de limpieza y licores madre organohalogenados',
  '070104': 'Otros disolventes, líquidos de limpieza y licores madre orgánicos',
  '070107': 'Residuos de reacción y de destilación halogenados',
  '070108': 'Otros residuos de reacción y de destilación',
  '070109': 'Tortas de filtración y absorbentes usados halogenados',
  '070110': 'Otras tortas de filtración y absorbentes usados',
  '070111': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '070112': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 07 01 11',
  '070199': 'Residuos no especificados en otra categoría',
  '070201': 'Líquidos de limpieza y licores madre acuosos',
  '070203': 'Disolventes, líquidos de limpieza y licores madre organohalogenados',
  '070204': 'Otros disolventes, líquidos de limpieza y licores madre orgánicos',
  '070207': 'Residuos de reacción y de destilación halogenados',
  '070208': 'Otros residuos de reacción y de destilación',

  '070209': 'Tortas de filtración y absorbentes usados halogenados',
  '070210': 'Otras tortas de filtración y absorbentes usados',
  '070211': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '070212': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 07 02 11',
  '070213': 'Residuos de plástico',
  '070214': 'Residuos procedentes de aditivos que contienen sustancias peligrosas',
  '070215': 'Residuos procedentes de aditivos distintos de los especificados en el código 07 02 14',
  '070216': 'Residuos que contienen siliconas',
  '070217': 'Residuos que contienen siliconas distintos de los mencionados en el código 07 02 16',
  '070299': 'Residuos no especificados en otra categoría',
  '070301': 'Líquidos de limpieza y licores madre acuosos',
  '070303': 'Disolventes, líquidos de limpieza y licores madre organohalogenados',
  '070304': 'Otros disolventes, líquidos de limpieza y licores madre orgánicos',
  '070307': 'Residuos de reacción y de destilación halogenados',
  '070308': 'Otros residuos de reacción y de destilación',
  '070309': 'Tortas de filtración y absorbentes usados halogenados',
  '070310': 'Otras tortas de filtración y absorbentes usados',
  '070311': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '070312': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 07 03 11',
  '070399': 'Residuos no especificados en otra categoría',
  '070401': 'Líquidos de limpieza y licores madre acuosos',
  '070403': 'Disolventes, líquidos de limpieza y licores madre organohalogenados',

  '070404': 'Otros disolventes, líquidos de limpieza y licores madre orgánicos',
  '070407': 'Residuos de reacción y de destilación halogenados',
  '070408': 'Otros residuos de reacción y de destilación',
  '070409': 'Tortas de filtración y absorbentes usados halogenados',
  '070410': 'Otras tortas de filtración y absorbentes usados',
  '070411': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '070412': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 07 04 11',
  '070413': 'Residuos sólidos que contienen sustancias peligrosas',
  '070499': 'Residuos no especificados en otra categoría',
  '070501': 'Líquidos de limpieza y licores madre acuosos',
  '070503': 'Disolventes, líquidos de limpieza y licores madre organohalogenados',
  '070504': 'Otros disolventes, líquidos de limpieza y licores madre orgánicos',
  '070507': 'Residuos de reacción y de destilación halogenados',
  '070508': 'Otros residuos de reacción y de destilación',
  '070509': 'Tortas de filtración y absorbentes usados halogenados',
  '070510': 'Otras tortas de filtración y absorbentes usados',
  '070511': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '070512': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 07 05 11',
  '070513': 'Residuos sólidos que contienen sustancias peligrosas',
  '070514': 'Residuos sólidos distintos de los especificados en el código 07 05 13',
  '070599': 'Residuos no especificados en otra categoría',
  '070601': 'Líquidos de limpieza y licores madre acuosos',

  '070603': 'Disolventes, líquidos de limpieza y licores madre organohalogenados',
  '070604': 'Otros disolventes, líquidos de limpieza y licores madre orgánicos',
  '070607': 'Residuos de reacción y de destilación halogenados',
  '070608': 'Otros residuos de reacción y de destilación',
  '070609': 'Tortas de filtración y absorbentes usados halogenados',
  '070610': 'Otras tortas de filtración y absorbentes usados',
  '070611': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '070612': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 07 06 11',
  '070699': 'Residuos no especificados en otra categoría',
  '070701': 'Líquidos de limpieza y licores madre acuosos',
  '070703': 'Disolventes, líquidos de limpieza y licores madre organohalogenados',
  '070704': 'Otros disolventes, líquidos de limpieza y licores madre orgánicos',
  '070707': 'Residuos de reacción y de destilación halogenados',
  '070708': 'Otros residuos de reacción y de destilación',
  '070709': 'Tortas de filtración y absorbentes usados halogenados',
  '070710': 'Otras tortas de filtración y absorbentes usados',
  '070711': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '070712': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 07 07 11',
  '070799': 'Residuos no especificados en otra categoría',
  '080111': 'Residuos de pintura y barniz que contienen disolventes orgánicos u otras sustancias peligrosas',
  '080112': 'Residuos de pintura y barniz, distintos de los especificados en el código 08 01 11',
  '080113': 'Lodos de pintura y barniz que contienen disolventes orgánicos u otras sustancias peligrosas',

  '080114': 'Lodos de pintura y barniz, distintos de los especificados en el código 08 01 13',
  '080115': 'Lodos acuosos que contienen pintura o barniz con disolventes orgánicos u otras sustancias peligrosas',
  '080116': 'Lodos acuosos que contienen pintura o barniz, distintos de los especificados en el código 08 01 15',
  '080117': 'Residuos del decapado o eliminación de pintura y barniz que contienen disolventes orgánicos u otras sustancias peligrosas',
  '080118': 'Residuos del decapado o eliminación de pintura y barniz, distintos de los especificados en el código 08 01 17',
  '080119': 'Suspensiones acuosas que contienen pintura o barniz con disolventes orgánicos u otras sustancias peligrosas',
  '080120': 'Suspensiones acuosas que contienen pintura o barniz, distintos de los especificados en el código 08 01 19',
  '080121': 'Residuos de decapantes o eliminadores de pintura y barniz',
  '080199': 'Residuos no especificados en otra categoría',
  '080201': 'Residuos de arenillas de revestimiento',
  '080202': 'Lodos acuosos que contienen materiales cerámicos',
  '080203': 'Suspensiones acuosas que contienen materiales cerámicos',
  '080299': 'Residuos no especificados en otra categoría',
  '080307': 'Lodos acuosos que contienen tinta',
  '080308': 'Residuos líquidos acuosos que contienen tinta',
  '080312': 'Residuos de tintas que contienen sustancias peligrosas',
  '080313': 'Residuos de tintas distintos de los especificados en el código 08 03 12',
  '080314': 'Lodos de tinta que contienen sustancias peligrosas',
  '080315': 'Lodos de tinta distintos de los especificados en el código 08 03 14',
  '080316': 'Residuos de soluciones corrosivas',

  '080317': 'Residuos de tóner y cintas de impresión que contienen sustancias peligrosas',
  '080318': 'Residuos de tóner de impresión, distintos a los especificados en el código 080317*',
  '080319': 'Aceites de dispersión',
  '080399': 'Residuos no especificados en otra categoría',
  '080409': 'Residuos de adhesivos y sellantes que contienen disolventes orgánicos u otras sustancias peligrosas',
  '080410': 'Residuos de adhesivos y sellantes, distintos de los especificados en el código 08 04 09',
  '080411': 'Lodos de adhesivos y sellantes que contienen disolventes orgánicos u otras sustancias peligrosas',
  '080412': 'Lodos de adhesivos y sellantes, distintos de los especificados en el código 08 04 11',
  '080413': 'Lodos acuosos que contienen adhesivos o sellantes con disolventes orgánicos u otras sustancias peligrosas',
  '080414': 'Lodos acuosos que contienen adhesivos o sellantes, distintos de los especificados en el código 08 04 13',
  '080415': 'Residuos líquidos acuosos que contienen adhesivos o sellantes con disolventes orgánicos u otras sustancias peligrosas',
  '080416': 'Residuos líquidos acuosos que contienen adhesivos o sellantes, distintos de los especificados en el código 08 04 15',
  '080417': 'Aceite de resina',
  '080499': 'Residuos no especificados en otra categoría',
  '080501': 'Isocianatos residuales',
  '090101': 'Soluciones de revelado y soluciones activadoras al agua',
  '090102': 'Soluciones de revelado de placas de impresión al agua',
  '090103': 'Soluciones de revelado con disolventes',
  '090104': 'Soluciones de fijado',
  '090105': 'Soluciones de blanqueo y soluciones de blanqueofijado',

  '090106': 'Residuos que contienen plata procedente del tratamiento in situ de residuos fotográficos',
  '090107': 'Películas y papel fotográfico que contienen plata o compuestos de plata',
  '090108': 'Películas y papel fotográfico que no contienen plata ni compuestos de plata',
  '090110': 'Cámaras de un solo uso sin pilas ni acumuladores',
  '090111': 'Cámaras de un solo uso con pilas o acumuladores incluidos en los códigos 16 06 01, 16 06 02 o 16 06 03',
  '090112': 'Cámaras de un solo uso con pilas o acumuladores distintas de las especificadas en el código 09 01 11',
  '090113': 'Residuos líquidos acuosos procedentes de la recuperación in situ de plata distintos de los especificados en el código 09 01 06',
  '090199': 'Residuos no especificados en otra categoría',
  '100101': 'Ceniza de fondo de horno, escorias y polvo de caldera (excepto el polvo de caldera especificado en el código 10 01 04)',
  '100102': 'Cenizas volantes de carbón',
  '100103': 'Cenizas volantes de turba y de madera (no tratada)',
  '100104': 'Cenizas volantes y polvo de caldera de hidrocarburos',
  '100105': 'Residuos cálcicos de reacción, en forma sólida, procedentes de la desulfuración de gases de combustión',
  '100107': 'Residuos cálcicos de reacción, en forma de lodos, procedentes de la desulfuración de gases de combustión',
  '100109': 'Ácido sulfúrico',
  '100113': 'Cenizas volantes de hidrocarburos emulsionados usados como combustibles',
  '100114': 'Ceniza de fondo de horno, escorias y polvo de caldera procedentes de la coincineración que contienen sustancias peligrosas',
  '100115': 'Ceniza de fondo de horno, escorias y polvo de caldera procedentes de la coincineración, distintos de los especificados en el código 10 01 14',

  '100116': 'Cenizas volantes procedentes de la co-incineración que contienen sustancias peligrosas',
  '100117': 'Cenizas volantes procedentes de la co-incineración distintas de las especificadas en el código 10 01 16',
  '100118': 'Residuos procedentes de la depuración de gases que contienen sustancias peligrosas',
  '100119': 'Residuos procedentes de la depuración de gases distintos de los especificados en los códigos 10 01 05, 10 01 07 y 10 01 18',
  '100120': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '100121': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 10 01 20',
  '100122': 'Lodos acuosos, procedentes de la limpieza de calderas, que contienen sustancias peligrosas',
  '100123': 'Lodos acuosos procedentes de la limpieza de calderas, distintos de los especificados en el código 10 01 22',
  '100124': 'Arenas de lechos fluidizados',
  '100125': 'Residuos procedentes del almacenamiento y preparación de combustible de centrales termoeléctricas de carbón',
  '100126': 'Residuos del tratamiento del agua de refrigeración',
  '100199': 'Residuos no especificados en otra categoría',
  '100201': 'Residuos del tratamiento de escorias',
  '100202': 'Escorias no tratadas',
  '100207': 'Residuos sólidos del tratamiento de gases que contienen sustancias peligrosas',
  '100208': 'Residuos sólidos del tratamiento de gases, distintos de los especificados en el código 10 02 07',
  '100210': 'Cascarilla de laminación',
  '100211': 'Residuos del tratamiento del agua de refrigeración que contienen aceites',
  '100212': 'Residuos del tratamiento del agua de refrigeración distintos de los especificados en el código 10 02 11',
  '100213': 'Lodos y tortas de filtración del tratamiento de gases que contienen sustancias peligrosas',

  '100214': 'Lodos y tortas de filtración del tratamiento de gases, distintos de los especificados en el código 10 02 13',
  '100215': 'Otros lodos y tortas de filtración',
  '100299': 'Residuos no especificados en otra categoría',
  '100302': 'Fragmentos de ánodos',
  '100304': 'Escorias de la producción primaria',
  '100305': 'Residuos de alúmina',
  '100308': 'Escorias salinas de la producción secundaria',
  '100309': 'Granzas negras de la producción secundaria',
  '100315': 'Espumas inflamables o que emiten, en contacto con el agua, gases inflamables en cantidades peligrosas',
  '100316': 'Espumas distintas de las especificadas en 10 03 15',
  '100317': 'Residuos que contienen alquitrán procedentes de la fabricación de ánodos',
  '100318': 'Residuos que contienen carbono procedentes de la fabricación de ánodos, distintos de los especificados en el código 10 03 17',
  '100319': 'Partículas procedentes de los efluentes gaseosos que contienen sustancias peligrosas',
  '100320': 'Partículas procedentes de los efluentes gaseosos distintas de las especificadas en el código 10 03 19',
  '100321': 'Otras partículas y polvo (incluido el polvo de molienda) que contienen sustancias peligrosas',
  '100322': 'Otras partículas y polvo (incluido el polvo de molienda) distintos de los especificados en el código 10 03 21',
  '100323': 'Residuos sólidos del tratamiento de gases que contienen sustancias peligrosas',
  '100324': 'Residuos sólidos del tratamiento de gases, distintos de los especificados en el código 10 03 23',
  '100325': 'Lodos y tortas de filtración del tratamiento de gases que contienen sustancias peligrosas',
  '100326': 'Lodos y tortas de filtración del tratamiento de gases, distintos de los especificados en el código 10 03 25',
  '100327': 'Residuos del tratamiento del agua de refrigeración que contienen aceites',

  '100599': 'Residuos no especificados en otra categoría',
  '100601': 'Escorias de la producción primaria y secundaria',
  '100602': 'Granzas y espumas de la producción primaria y secundaria',
  '100603': 'Partículas procedentes de los efluentes gaseosos',
  '100604': 'Otras partículas y polvo',
  '100606': 'Residuos sólidos del tratamiento de gases',
  '100607': 'Lodos y tortas de filtración del tratamiento de gases',
  '100609': 'Residuos del tratamiento del agua de refrigeración que contienen aceites',
  '100610': 'Residuos del tratamiento del agua de refrigeración, distintos de los especificados en el código 10 06 09',
  '100699': 'Residuos no especificados en otra categoría',
  '100701': 'Escorias de la producción primaria y secundaria',
  '100702': 'Granzas y espumas de la producción primaria y secundaria',
  '100703': 'Residuos sólidos del tratamiento de gases',
  '100704': 'Otras partículas y polvo',
  '100705': 'Lodos y tortas de filtración del tratamiento de gases',
  '100707': 'Residuos del tratamiento del agua de refrigeración que contienen aceites',
  '100708': 'Residuos del tratamiento del agua de refrigeración distintos de los especificados en el código 10 07 07',
  '100799': 'Residuos no especificados en otra categoría',
  '100804': 'Partículas y polvo',
  '100808': 'Escorias salinas de la producción primaria y secundaria',
  '100809': 'Otras escorias',
  '100810': 'Granzas y espumas inflamables o que emiten, en contacto con el agua, gases inflamables en cantidades peligrosas',
  '100811': 'Granzas y espumas distintas de las especificadas en el código 10 08 10',

  '100812': 'Residuos que contienen alquitrán procedentes de la fabricación de ánodos',
  '100813': 'Residuos que contienen carbono procedentes de la fabricación de ánodos, distintos de los especificados en el código 10 08 12',
  '100814': 'Fragmentos de ánodos',
  '100815': 'Partículas, procedentes de los efluentes gaseosos, que contienen sustancias peligrosas',
  '100816': 'Partículas procedentes de los efluentes gaseosos distintas de las especificadas en el código 10 08 15',
  '100817': 'Lodos y tortas de filtración del tratamiento de gases que contienen sustancias peligrosas',
  '100818': 'Lodos y tortas de filtración del tratamiento de gases, distintos de los especificados en el código 10 08 17',
  '100819': 'Residuos del tratamiento del agua de refrigeración que contienen aceites',
  '100820': 'Residuos del tratamiento del agua de refrigeración distintos de los especificados en el código 10 08 19',
  '100899': 'Residuos no especificados en otra categoría',
  '100903': 'Escorias de horno',
  '100905': 'Machos y moldes de fundición sin colada que contienen sustancias peligrosas',
  '100906': 'Machos y moldes de fundición sin colada distintos de los especificados en el código 10 09 05',
  '100907': 'Machos y moldes de fundición con colada que contienen sustancias peligrosas',
  '100908': 'Machos y moldes de fundición con colada distintos de los especificados en el código 10 09 07',
  '100909': 'Partículas, procedentes de los efluentes gaseosos, que contienen sustancias peligrosas',
  '100910': 'Partículas procedentes de los efluentes gaseosos distintas de las especificadas en el código 10 09 09',
  '100911': 'Otras partículas que contienen sustancias peligrosas',
  '100912': 'Otras partículas distintas de las especificadas en el código 10 09 11',
  '100913': 'Ligantes residuales que contienen sustancias peligrosas',

  '100914': 'Ligantes residuales distintos de los especificados en el código 10 09 13',
  '100915': 'Residuos de agentes indicadores de fisuración que contienen sustancias peligrosas',
  '100916': 'Residuos de agentes indicadores de fisuración distintos de los especificados en el código 10 09 15',
  '100999': 'Residuos no especificados en otra categoría',
  '101003': 'Escorias de horno',
  '101005': 'Machos y moldes de fundición sin colada que contienen sustancias peligrosas',
  '101006': 'Machos y moldes de fundición sin colada distintos de los especificados en el código 10 10 05',
  '101007': 'Machos y moldes de fundición con colada que contienen sustancias peligrosas',
  '101008': 'Machos y moldes de fundición con colada distintos de los especificados en el código 10 10 07',
  '101009': 'Partículas, procedentes de los efluentes gaseosos, que contienen sustancias peligrosas',
  '101010': 'Partículas procedentes de los efluentes gaseosos, distintas de las especificadas en el código 10 10 09',
  '101011': 'Otras partículas que contienen sustancias peligrosas',
  '101012': 'Otras partículas distintas de las especificadas en el código 10 10 11',
  '101013': 'Ligantes residuales que contienen sustancias peligrosas',
  '101014': 'Ligantes residuales distintos de los especificados en el código 10 10 13',
  '101015': 'Residuos de agentes indicadores de fisuración que contienen sustancias peligrosas',
  '101016': 'Residuos de agentes indicadores de fisuración distintos de los especificados en el código 10 10 15',
  '101099': 'Residuos no especificados en otra categoría',
  '101103': 'Residuos de materiales de fibra de vidrio',
  '101105': 'Partículas y polvo',

  '101109': 'Residuos de la preparación de mezclas antes del proceso de cocción que contienen sustancias peligrosas',
  '101110': 'Residuos de la preparación de mezclas antes del proceso de cocción distintos de los especificados en el código 10 11 09',
  '101111': 'Residuos de pequeñas partículas de vidrio y de polvo de vidrio que contienen metales pesados (por ejemplo, de tubos catódicos)',
  '101112': 'Residuos de vidrio distintos de los especificados en el código 10 11 11',
  '101113': 'Lodos procedentes del pulido y esmerilado del vidrio que contienen sustancias peligrosas',
  '101114': 'Lodos procedentes del pulido y esmerilado del vidrio, distintos de los especificados en el código 10 11 13',
  '101115': 'Residuos sólidos del tratamiento de gases de combustión que contienen sustancias peligrosas',
  '101116': 'Residuos sólidos del tratamiento de gases de combustión, distintos de los especificados en el código 10 11 15',
  '101117': 'Lodos y tortas de filtración del tratamiento de gases que contienen sustancias peligrosas',
  '101118': 'Lodos y tortas de filtración del tratamiento de gases, distintos de los especificados en el código 10 11 17',
  '101119': 'Residuos sólidos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '101120': 'Residuos sólidos del tratamiento in situ de efluentes, distintos de los especificados en el código 10 11 19',
  '101199': 'Residuos no especificados en otra categoría',
  '101201': 'Residuos de la preparación de mezclas antes del proceso de cocción',
  '101203': 'Partículas y polvo',
  '101205': 'Lodos y tortas de filtración del tratamiento de gases',
  '101206': 'Moldes desechados',
  '101208': 'Residuos de cerámica, ladrillos, tejas y materiales de construcción (después del proceso de cocción)',
  '101209': 'Residuos sólidos del tratamiento de gases que contienen sustancias peligrosas',

  '110110': 'Lodos y tortas de filtración distintos de los especificados en el código 11 01 09',
  '110111': 'Líquidos acuosos de enjuague que contienen sustancias peligrosas',
  '110112': 'Líquidos acuosos de enjuague distintos de los especificados en el código 11 01 11',
  '110113': 'Residuos de desengrasado que contienen sustancias peligrosas',
  '110114': 'Residuos de desengrasado distintos de los especificados en el código 11 01 13',
  '110115': 'Eluatos y lodos procedentes de sistemas de membranas o de intercambio iónico que contienen sustancias peligrosas',
  '110116': 'Resinas intercambiadoras de iones saturadas o usadas',
  '110198': 'Otros residuos que contienen sustancias peligrosas',
  '110199': 'Residuos no especificados en otra categoría',
  '110202': 'Lodos de la hidrometalurgia del zinc (incluida jarosita, goetita)',
  '110203': 'Residuos de la producción de ánodos para procesos de electrólisis acuosa',
  '110205': 'Residuos de procesos de la hidrometalurgia del cobre que contienen sustancias peligrosas',
  '110206': 'Residuos de procesos de la hidrometalurgia del cobre distintos de los especificados en el código 11 02 05',
  '110207': 'Otros residuos que contienen sustancias peligrosas',
  '110299': 'Residuos no especificados en otra categoría',
  '110301': 'Residuos que contienen cianuro',
  '110302': 'Otros residuos',
  '110501': 'Matas de galvanización',
  '110502': 'Cenizas de zinc',
  '110503': 'Residuos sólidos del tratamiento de gases',
  '110504': 'Fundentes usados',
  '110599': 'Residuos no especificados en otra categoría',

  '120101': 'Limaduras y virutas de metales férreos',
  '120102': 'Polvo y partículas de metales férreos',
  '120103': 'Limaduras y virutas de metales no férreos',
  '120104': 'Polvo y partículas de metales no férreos',
  '120105': 'Virutas y rebabas de plástico',
  '120106': 'Aceites minerales de mecanizado que contienen halógenos (excepto las emulsiones o disoluciones)',
  '120107': 'Aceites minerales de mecanizado sin halógenos (excepto las emulsiones o disoluciones)',
  '120108': 'Emulsiones y disoluciones de mecanizado que contienen halógenos',
  '120109': 'Emulsiones y disoluciones de mecanizado sin halógenos',
  '120110': 'Aceites sintéticos de mecanizado',
  '120112': 'Ceras y grasas usadas',
  '120113': 'Residuos de soldadura',
  '120114': 'Lodos de mecanizado que contienen sustancias peligrosas',
  '120115': 'Lodos de mecanizado distintos de los especificados en el código 12 01 14',
  '120116': 'Residuos de granallado o chorreado que contienen sustancias peligrosas',
  '120117': 'Residuos de granallado o chorreado distintos de los especificados en el código 12 01 16',
  '120118': 'Lodos metálicos (lodos de esmerilado, rectificado y lapeado) que contienen aceites',
  '120119': 'Aceites de mecanizado fácilmente biodegradables',
  '120120': 'Muelas y materiales de esmerilado usados que contienen sustancias peligrosas',
  '120121': 'Muelas y materiales de esmerilado usados distintos de los especificados en el código 12 01 20',
  '120199': 'Residuos no especificados en otra categoría',
  '120301': 'Líquidos acuosos de limpieza',
  '120302': 'Residuos de desengrase al vapor',

  '130101': 'Aceites hidráulicos que contienen PCB',
  '130104': 'Emulsiones cloradas',
  '130105': 'Emulsiones no cloradas',
  '130109': 'Aceites hidráulicos minerales clorados',
  '130110': 'Aceites hidráulicos minerales no clorados',
  '130111': 'Aceites hidráulicos sintéticos',
  '130112': 'Aceites hidráulicos fácilmente biodegradables',
  '130113': 'Otros aceites hidráulicos',
  '130204': 'Aceites minerales de motor, de transmisión mecánica y lubricantes, con cloro',
  '130205': 'Otros minerales de motor, de transmisión mecánica y lubricantes, sin contenido de cloro',
  '130206': 'Aceites sintéticos de motor, de transmisión mecánica y lubricantes',
  '130207': 'Aceites fácilmente biodegradables de motor, de transmisión mecánica y lubricantes',
  '130208': 'Otros aceites de motor, de transmisión mecánica y lubricantes',
  '130301': 'Aceites de aislamiento y transmisión de calor que contienen PCB',
  '130306': 'Aceites minerales clorados de aislamiento y transmisión de calor, distintos a los especificados en el código 130301*',
  '130307': 'Aceites minerales no clorados de aislamiento y transmisión de calor',
  '130308': 'Aceites sintéticos de aislamiento y transmisión de calor',
  '130309': 'Aceites fácilmente biodegradables de aislamiento y transmisión de calor',
  '130310': 'Otros aceites de aislamiento y transmisión de calor',
  '130401': 'Aceites de sentinas procedentes de la navegación en aguas continentales',
  '130402': 'Aceites de sentinas recogidos en muelles',
  '130403': 'Aceites de sentinas procedentes de otros tipos de navegación',

  '130501': 'Sólidos procedentes de desarenadores y de separadores de agua/sustancias aceitosas',
  '130502': 'Lodos de separadores de agua/sustancias aceitosas',
  '130503': 'Lodos de interceptores',
  '130506': 'Aceites procedentes de separadores de agua/sustancias aceitosas',
  '130507': 'Agua aceitosa procedente de separadores de agua/sustancias aceitosas',
  '130508': 'Mezcla de residuos procedentes de desarenadores y de separadores de agua/sustancias aceitosas',
  '130701': 'Fuelóleo y gasóleo',
  '130702': 'Gasolina',
  '130703': 'Otros combustibles (incluidas mezclas)',
  '130801': 'Lodos o emulsiones de desalación',
  '130802': 'Otras emulsiones',
  '130899': 'Residuos no especificados en otra categoría',
  '140601': 'Gases refrigerantes clorofluorados (CFC, HCFC, HFC)',
  '140602': 'Otros disolventes y mezclas de disolventes halogenados',
  '140603': 'Gases refrigerantes de hidrocarburos (HC)',
  '140604': 'Lodos o residuos sólidos que contienen disolventes halogenados',
  '140605': 'Lodos o residuos sólidos que contienen otros disolventes',
  '150101': 'Envases de papel y cartón',
  '150102': 'Envases de plástico',
  '150103': 'Envases de madera',
  '150104': 'Envases metálicos',
  '150105': 'Envases compuestos',
  '150106': 'Envases mezclados',
  '150107': 'Envases de vidrio',
  '150109': 'Envases textiles',

  '150110': 'Envases que contienen restos de sustancias peligrosas o están contaminados por ellas',
  '150111': 'Envases metálicos, incluidos los recipientes a presión vacíos, que contienen una matriz sólida y porosa peligrosa (por ejemplo, amianto)',
  '150202': 'Adsorbentes, filtros, materiales o fibras contaminadas con sustancias peligrosas',
  '150203': 'Absorbentes, materiales de filtración, trapos de limpieza y ropas protectoras distintos de los especificados en el código 15 02 02',
  '160103': 'Neumáticos al final de su vida útil',
  '160104': 'Vehículos al final de su vida útil',
  '160106': 'Vehículos al final de su vida útil que no contengan líquidos ni otros componentes peligrosos',
  '160107': 'Filtros de aceite',
  '160108': 'Componentes que contienen mercurio',
  '160109': 'Componentes que contienen PCB',
  '160110': 'Componentes explosivos (por ejemplo, colchones de aire)',
  '160111': 'Zapatas de freno que contienen amianto',
  '160112': 'Zapatas de freno distintas de las especificadas en el código 16 01 11',
  '160113': 'Líquidos de frenos',
  '160114': 'Anticongelantes que contienen sustancias peligrosas',
  '160115': 'Anticongelantes distintos de los especificados en el código 16 01 14',
  '160116': 'Depósitos para gases licuados',
  '160117': 'Metales férreos',
  '160118': 'Metales no férreos',
  '160119': 'Plástico',
  '160120': 'Vidrio',
  '160121': 'Componentes peligrosos distintos de los especificados en los códigos 16 01 07 a 16 01 11 y 16 01 13 y 16 01 14',

  '160122': 'Componentes no especificados en otra categoría',
  '160199': 'Residuos no especificados de otra forma',
  '160209': 'Transformadores y condensadores que contienen PCB',
  '160210': 'Equipos desechados que contienen PCB, o están contaminados por ellos, distintos de los especificados en el código 160209*',
  '160211': 'Equipos desechados que contienen CFC, HCFC, HFC',
  '160212': 'Equipos desechados que contienen amianto libre',
  '160213': 'Equipos desechados que contienen componentes peligrosos, distintos de los especificados en los códigos 16 02 09 a 16 02 12',
  '160214': 'Equipos desechados distintos de los especificados en los códigos 16 02 09 a 16 02 13',
  '160215':
    'Componentes peligrosos retirados de equipos desechados. Por ejemplo: cables y vidrio contaminados, plásticos con retardantes de llama bromados, condensadores peligrosos, compresores con aceite residual, pantallas LCD, etc.',
  '160216':
    'Componentes retirados de equipos desechados distintos de los especificados en el código 160215*. Por ejemplo: cables (no peligrosos), tarjetas de circuitos impresos, carcasas de metal tras Fase 1 sin componentes peligrosos que entrarían a Fase 2 de fragmentación, etc.',
  '160303': 'Residuos inorgánicos que contienen sustancias peligrosas',
  '160304': 'Residuos inorgánicos distintos de los especificados en el código 16 03 03',
  '160305': 'Residuos orgánicos que contienen sustancias peligrosas',
  '160306': 'Residuos orgánicos distintos de los especificados en el código 16 03 05',
  '160307': 'Mercurio metálico',
  '160401': 'Residuos de municiones',
  '160402': 'Residuos de fuegos artificiales',
  '160403': 'Otros residuos explosivos',

  '160504': 'Gases en recipientes a presión (incluidos los halones) que contienen sustancias peligrosas',
  '160505': 'Gases en recipientes a presión, distintos de los especificados en el código 16 05 04',
  '160506': 'Productos químicos de laboratorio que consisten en sustancias peligrosas, incluidas las mezclas de productos químicos de laboratorio, o las contienen',
  '160507':
    'Productos químicos inorgánicos desechados que consisten en sustancias peligrosas o las contienen Por ejemplo: tóner, óxido de berilio, tarjetas de soldadura de plomo',
  '160508': 'Productos químicos orgánicos desechados que consisten en sustancias peligrosas o las contienen',
  '160509': 'Productos químicos desechados distintos de los especificados en los códigos 16 05 06, 16 05 07 o 16 05 08',
  '160601': 'Baterías de plomo',
  '160602': 'Acumuladores de níquel-cadmio',
  '160603': 'Pilas que contienen mercurio',
  '160604': 'Pilas alcalinas (excepto 160603*)',
  '160605': 'Otras pilas y acumuladores',
  '160606': 'Electrolitos de pilas y acumuladores recogidos selectivamente',
  '160607': 'Acumuladores, pilas o baterías en cuya composición se encuentre el litio en cualquiera de sus formas, tales como las pilas de litio o los acumuladores ionlitio.',
  '160608':
    'Acumuladores, pilas o baterías en cuya composición se encuentre el níquel en cualquiera de sus formas, tales como los acumuladores de níquel metal hidruro (Ni-MH). Se excluyen de este código los acumuladores y baterías de níquel-cadmio.',
  '160609': 'Acumuladores, pilas o baterías en cuya composición se encuentren otras sustancias peligrosas.',
  '160708': 'Residuos que contienen hidrocarburos',
  '160709': 'Residuos que contienen otras sustancias peligrosas',
  '160799': 'Residuos no especificados en otra categoría',

  '160801': 'Catalizadores usados que contienen oro, plata, renio, rodio, paladio, iridio o platino (excepto el código 16 08 07)',
  '160802': 'Catalizadores usados que contienen metales de transición peligrosos o compuestos de metales de transición peligrosos',
  '160803': 'Catalizadores usados que contienen metales de transición o compuestos de metales de transición no especificados en otra categoría',
  '160804': 'Catalizadores usados procedentes del craqueo catalítico fluido (excepto los del código 16 08 07)',
  '160805': 'Catalizadores usados que contienen ácido fosfórico',
  '160806': 'Líquidos usados utilizados como catalizadores',
  '160807': 'Catalizadores usados contaminados con sustancias peligrosas',
  '160901': 'Permanganatos, por ejemplo permanganato potásico',
  '160902': 'Cromatos, por ejemplo, cromato potásico, dicromato sódico o potásico',
  '160903': 'Peróxidos, por ejemplo, peróxido de hidrógeno',
  '160904': 'Sustancias oxidantes no especificadas en otra categoría',
  '161001': 'Residuos líquidos acuosos que contienen sustancias peligrosas',
  '161002': 'Residuos líquidos acuosos distintos de los especificados en el código 16 10 01',
  '161003': 'Concentrados acuosos que contienen sustancias peligrosas',
  '161004': 'Concentrados acuosos distintos de los especificados en el código 16 10 03',
  '161101': 'Revestimientos y refractarios a base de carbono, procedentes de procesos metalúrgicos, que contienen sustancias peligrosas',
  '161102': 'Revestimientos y refractarios a base de carbono, procedentes de procesos metalúrgicos distintos de los especificados en el código 16 11 01',
  '161103': 'Otros revestimientos y refractarios procedentes de procesos metalúrgicos que contienen sustancias peligrosas',

  '161104': 'Otros revestimientos y refractarios procedentes de procesos metalúrgicos, distintos de los especificados en el código 16 11 03',
  '161105': 'Revestimientos y refractarios, procedentes de procesos no metalúrgicos, que contienen sustancias peligrosas',
  '161106': 'Revestimientos y refractarios procedentes de procesos no metalúrgicos, distintos de los especificados en el código 16 11 05',
  '170101': 'Hormigón',
  '170102': 'Ladrillos',
  '170103': 'Tejas y materiales cerámicos',
  '170106': 'Mezclas, o fracciones separadas, de hormigón, ladrillos, tejas y materiales cerámicos que contienen sustancias peligrosas',
  '170107': 'Mezclas de hormigón, ladrillos, tejas y materiales cerámicos, distintas de las especificadas en el código 17 01 06',
  '170201': 'Madera',
  '170202': 'Vidrio',
  '170203': 'Plástico',
  '170204': 'Vidrio, plástico y madera que contienen sustancias peligrosas o están contaminados por ellas',
  '170301': 'Mezclas bituminosas que contienen alquitrán de hulla',
  '170302': 'Mezclas bituminosas distintas de las especificadas en el código 17 03 01',
  '170303': 'Alquitrán de hulla y productos alquitranados',
  '170401': 'Cobre, bronce, latón',
  '170402': 'Aluminio',
  '170403': 'Plomo',
  '170404': 'Zinc',
  '170405': 'Hierro y acero',
  '170406': 'Estaño',
  '170407': 'Metales mezclados',

  '170409': 'Residuos metálicos contaminados con sustancias peligrosas',
  '170410': 'Cables que contienen hidrocarburos, alquitrán de hulla y otras sustancias peligrosas',
  '170411': 'Cables distintos de los especificados en el código 17 04 10',
  '170503': 'Tierra y piedras que contienen sustancias peligrosas',
  '170504': 'Tierra y piedras distintas de las especificadas en las especificadas 17 05 03',
  '170505': 'Lodos de drenaje que contienen sustancias peligrosas',
  '170506': 'Lodos de drenaje distintos de los especificados en el código 17 05 05',
  '170507': 'Balasto de vías férreas que contiene sustancias peligrosas',
  '170508': 'Balasto de vías férreas distinto del especificado en el código 17 05 07',
  '170601': 'Materiales de asilamiento que contienen amianto',
  '170603': 'Otros materiales de aislamiento que consisten en sustancias peligrosas o las contienen. Por ejemplo: fibras cerámicas',
  '170604': 'Materiales de aislamiento distintos de los especificados en los códigos 17 06 01 y 17 06 03',
  '170605': 'Materiales de construcción que contienen amianto',
  '170801': 'Materiales de construcción a base de yeso contaminados con sustancias peligrosas',
  '170802': 'Materiales de construcción a base de yeso distintos de los especificados en el código 17 08 01',
  '170901': 'Residuos de construcción y demolición que contienen mercurio',
  '170902':
    'Residuos de construcción y demolición que contienen PCB (por ejemplo, sellantes que contienen PCB, revestimientos de suelo a base de resinas que contienen PCB, acristalamientos dobles que contienen PCB, condensadores que contienen PCB)',
  '170903': 'Otros residuos de construcción y demolición (incluidos los residuos mezclados) que contienen sustancias peligrosas',

  '170904': 'Residuos mezclados de construcción y demolición distintos de los especificados en los códigos 17 09 01, 17 09 02 y 17 09 03',
  '180101': 'Objetos cortantes y punzantes (excepto el código 18 01 03)',
  '180102': 'Restos anatómicos y órganos, incluidos bolsas y bancos de sangre (excepto el código 18 01 03)',
  '180103': 'Residuos cuya recogida y eliminación son objeto de requisitos especiales para prevenir infecciones',
  '180104':
    'Residuos cuya recogida y eliminación no son objeto de requisitos especiales para prevenir infecciones (por ejemplo, vendajes, vaciados de yeso, ropa blanca, ropa desechable, pañales)',
  '180106': 'Productos químicos que consisten en sustancias peligrosas o contienen dichas sustancias',
  '180107': 'Productos químicos distintos de los especificados en el código 18 01 06',
  '180108': 'Medicamentos citotóxicos y citostáticos',
  '180109': 'Medicamentos distintos de los especificados en el código 18 01 08',
  '180110': 'Residuos de amalgamas procedentes de cuidados dentales',
  '180201': 'Objetos cortantes y punzantes (excepto el código 18 02 02)',
  '180202': 'Residuos cuya recogida y eliminación son objeto de requisitos especiales para prevenir infecciones',
  '180203': 'Residuos cuya recogida y eliminación no son objeto de requisitos especiales para prevenir infecciones',
  '180205': 'Productos químicos que consisten en, o contienen, sustancias peligrosas',
  '180206': 'Productos químicos distintos de los especificados en el código 18 02 05',
  '180207': 'Medicamentos citotóxicos y citostáticos',
  '180208': 'Medicamentos distintos de los especificados en el código 18 02 07',
  '190102': 'Materiales férreos separados de la ceniza de fondo de horno',
  '190105': 'Torta de filtración del tratamiento de gases',

  '190106': 'Residuos líquidos acuosos del tratamiento de gases y otros residuos líquidos acuosos. Por ejemplo: aguas contaminadas',
  '190107': 'Residuos sólidos del tratamiento de gases',
  '190110': 'Carbón activo usado procedente del tratamiento de gases de combustión',
  '190111': 'Cenizas de fondo de horno y escorias que contienen sustancias peligrosas',
  '190112': 'Cenizas de fondo de horno y escorias distintas de las especificadas en el código 19 01 11',
  '190113': 'Cenizas volantes que contienen sustancias peligrosas',
  '190114': 'Cenizas volantes distintas de las especificadas en el código 19 01 13',
  '190115': 'Polvo de caldera que contiene sustancias peligrosas',
  '190116': 'Polvo de caldera distinto del especificado en el código 19 01 15',
  '190117': 'Residuos de pirólisis que contienen sustancias peligrosas',
  '190118': 'Residuos de pirólisis distintos de los especificados en el código 19 01 17',
  '190119': 'Arenas de lechos fluidizados',
  '190199': 'Residuos no especificados en otra categoría',
  '190203': 'Residuos mezclados previamente, compuestos exclusivamente por residuos no peligrosos',
  '190204': 'Residuos mezclados previamente, compuestos por al menos un residuo peligroso',
  '190205': 'Lodos de tratamientos físico-químicos que contienen sustancias peligrosas',
  '190206': 'Lodos de tratamientos físico-químicos, distintos de los especificados en el código 190205*',
  '190207': 'Aceite y concentrados procedentes del proceso de separación',
  '190208': 'Residuos combustibles líquidos que contienen sustancias peligrosas',
  '190209': 'Residuos combustibles sólidos que contienen sustancias peligrosas',

  '190210': 'Aceites no peligrosos',
  '190211': 'Otros residuos que contienen sustancias peligrosas',
  '190299': 'Residuos no especificados en otra categoría',
  '190304': 'Residuos peligrosos, parcialmente estabilizados, distintos de los especificados en el código 19 03 08',
  '190305': 'Residuos estabilizados distintos de los especificados en el código 19 03 04',
  '190306': 'Residuos peligrosos solidificados',
  '190307': 'Residuos solidificados distintos de los especificados en el código 19 03 06',
  '190308': 'Mercurio parcialmente estabilizado',
  '190401': 'Residuos vitrificados»',
  '190402': 'Cenizas volantes y otros residuos del tratamiento de gases de combustión',
  '190403': 'Fase sólida no vitrificada',
  '190404': 'Residuos líquidos acuosos del templado de residuos vitrificados',
  '190501': 'Fracción no compostada de residuos municipales y asimilados',
  '190502': 'Fracción no compostada de residuos de procedencia animal o vegetal',
  '190503': 'Compost fuera de especificación',
  '190599': 'Residuos no especificados en otra categoría',
  '190603': 'Licor del tratamiento anaeróbico de residuos municipales',
  '190604': 'Lodos de digestión del tratamiento anaeróbico de residuos municipales',
  '190605': 'Licor del tratamiento anaeróbico de residuos animales y vegetales',
  '190606': 'Lodos de digestión del tratamiento anaeróbico de residuos animales y vegetales',
  '190699': 'Residuos no especificados en otra categoría',
  '190702': 'Lixiviados de vertedero que contienen sustancias peligrosas',

  '190703': 'Lixiviados de vertedero distintos de los especificados en el código 19 07 02',
  '190801': 'Residuos de cribado',
  '190802': 'Residuos de desarenado',
  '190805': 'Lodos del tratamiento de aguas residuales urbanas',
  '190806': 'Resinas intercambiadoras de iones saturadas o usadas',
  '190807': 'Soluciones y lodos de la regeneración de intercambiadores de iones',
  '190808': 'Residuos procedentes de sistemas de membranas que contienen metales pesados',
  '190809': 'Mezclas de grasas y aceites procedentes de la separación de agua/sustancias aceitosas que contienen solamente aceites y grasas comestibles',
  '190810': 'Mezclas de grasas y aceites procedentes de la separación de agua/sustancias aceitosas distintas de las especificadas en el código 19 08 09',
  '190811': 'Lodos que contienen sustancias peligrosas procedentes del tratamiento biológico de aguas residuales industriales',
  '190812': 'Lodos procedentes del tratamiento biológico de aguas residuales industriales distintos de los especificados en el código 19 08 11',
  '190813': 'Lodos que contienen sustancias peligrosas procedentes de otros tratamientos de aguas residuales industriales',
  '190814': 'Lodos procedentes de otros tratamientos de aguas residuales industriales, distintos de los especificados en el código 19 08 13',
  '190899': 'Residuos no especificados en otra categoría',
  '190901': 'Residuos sólidos de la filtración primaria y cribado',
  '190902': 'Lodos de la clarificación del agua',
  '190903': 'Lodos de descarbonatación',
  '190904': 'Carbón activo usado',
  '190905': 'Resinas intercambiadoras de iones saturadas o usadas',
  '190906': 'Soluciones y lodos de la regeneración de intercambiadores de iones',

  '190999': 'Residuos no especificados en otra categoría',
  '191001': 'Residuos de hierro y acero',
  '191002': 'Residuos no férreos',
  '191003': 'Fracciones ligeras de fragmentación (fluff-light) y polvo que contienen fracciones peligrosas. Por ejemplo: polvos de filtros',
  '191004': 'Fracciones ligeras de fragmentación (fluff-light) y polvo distintas de las especificadas en el código 191003*',
  '191005': 'Otras fracciones que contienen sustancias peligrosas',
  '191006': 'Otras fracciones distintas de las especificadas en el código 191005',
  '191101': 'Arcillas de filtración usadas',
  '191102': 'Alquitranes ácidos',
  '191103': 'Residuos de líquidos acuosos',
  '191104': 'Residuos de la limpieza de combustibles con bases',
  '191105': 'Lodos del tratamiento in situ de efluentes que contienen sustancias peligrosas',
  '191106': 'Lodos del tratamiento in situ de efluentes, distintos de los especificados en el código 19 11 05',
  '191107': 'Residuos de la depuración de gases de combustión',
  '191199': 'Residuos no especificados en otra categoría',
  '191201': 'Papel y cartón',
  '191202': 'Metales férreos',
  '191203': 'Metales no férreos',
  '191204': 'Plásticos no bromados',
  '191205': 'Vidrio',
  '191206': 'Madera que contiene sustancias peligrosas',
  '191207': 'Madera distinta de la especificada en el código 191206*',
  '191208': 'Materias textiles',
  '191209': 'Minerales. Por ejemplo: hormigón',

  '191210': 'Pellets, polvo y otros formatos procedentes de la espuma de poliuretano sin contenido de gas refrigerante',
  '191211':
    'Otros residuos (incluidas mezclas de materiales) procedentes del tratamiento mecánico de residuos que contienen sustancias peligrosas. Por ejemplo: espumas de poliuretano sin extraer el gas, vidrio procedente de la aspiración en la máquina de corte en la separación del vidrio de pantalla, vidrio de cono, revestimiento fluorescente, polvo con contenido en mercurio y fósforo, etc.',
  '191212': 'Otros residuos (incluidas mezclas de materiales) procedentes del tratamiento mecánico de residuos, distintos a los especificados en el código 191211*',
  '191301': 'Residuos sólidos de la recuperación de suelos que contienen sustancias peligrosas',
  '191302': 'Residuos sólidos de la recuperación de suelos distintos de los especificados en el código 19 13 01',
  '191303': 'Lodos de la recuperación de suelos que contienen sustancias peligrosas',
  '191304': 'Lodos de la recuperación de suelos distintos de los especificados en el código 19 13 03',
  '191305': 'Lodos de la recuperación de aguas subterráneas que contienen sustancias peligrosas',
  '191306': 'Lodos de la recuperación de aguas subterráneas distintos de los especificados en el código 19 13 05',
  '191307': 'Residuos líquidos acuosos y concentrados acuosos, procedentes de la recuperación de aguas subterráneas, que contienen sustancias peligrosas',
  '191308': 'Residuos de líquidos acuosos y concentrados acuosos procedentes de la recuperación de aguas subterráneas, distintos de los especificados en el código 19 13 07',
  '200101': 'Papel y cartón',
  '200102': 'Vidrio',
  '200108': 'Residuos biodegradables de cocinas y restaurantes',
  '200110': 'Ropa',
  '200111': 'Materias textiles',
  '200113': 'Disolventes',
  '200114': 'Ácidos',

  '200115': 'Álcalis',
  '200117': 'Productos fotoquímicos',
  '200119': 'Plaguicidas',
  '200121': 'Tubos fluorescentes y otros residuos que contienen mercurio. Por ejemplo: pantallas LCD, tubos fluorescentes, lámparas de descarga, relés de mercurio, etc.',
  '200123': 'Equipos desechados que contienen clorofluorocarburos',
  '200125': 'Aceites y grasas comestibles',
  '200126': 'Aceites y grasas distintos de los especificados en el código 20 01 25',
  '200127': 'Pinturas, tintas, adhesivos y resinas que contienen sustancias peligrosas',
  '200128': 'Pinturas, tintas, adhesivos y resinas distintos de los especificados en el código 20 01 27',
  '200129': 'Detergentes que contienen sustancias peligrosas',
  '200130': 'Detergentes distintos de los especificados en el código 20 01 29',
  '200131': 'Medicamentos citotóxicos y citostáticos',
  '200132': 'Medicamentos distintos de los especificados en el código 20 01 31',
  '200133': 'Baterías y acumuladores especificados en los códigos 160601, 160602 o 160603 y baterías y acumuladores sin clasificar que contienen esas baterías.',
  '200134': 'Baterías y acumuladores distintos de los especificados en el código 200133*',
  '200135': 'Equipos eléctricos y electrónicos desechados, distintos de los especificados en los códigos 20 01 21 y 20 01 23, que contienen componentes peligrosos',
  '200136': 'Equipos eléctricos y electrónicos desechados distintos de los especificados en los códigos 20 01 21, 20 01 23 y 20 01 35',
  '200137': 'Madera que contiene sustancias peligrosas',
  '200138': 'Madera distinta de la especificada en el código 20 01 37',
  '200139': 'Plásticos',

  '200140': 'Metales',
  '200141': 'Residuos del deshollinado de chimeneas',
  '200142': 'Acumuladores, pilas o baterías en cuya composición se encuentre el litio en cualquiera de sus formas, tales como las pilas de litio o los acumuladores ionlitio.',
  '200143':
    'Acumuladores, pilas o baterías en cuya composición se encuentre el níquel en cualquiera de sus formas, tales como los acumuladores de níquel metal hidruro (Ni-MH). Se excluyen de este código los acumuladores y baterías de níquel-cadmio.',
  '200144': 'Acumuladores, pilas o baterías en cuya composición se encuentren otras sustancias peligrosas.',
  '200199': 'Otras fracciones no especificadas en otra categoría',
  '200201': 'Papel y cartón',
  '200202': 'Tierra y piedras',
  '200203': 'Otros residuos no biodegradables',
  '200301': 'Mezclas de residuos municipales',
  '200302': 'Residuos de mercados',
  '200303': 'Residuos de limpieza viaria',
  '200304': 'Lodos de fosas sépticas',
  '200306': 'Residuos de la limpieza de alcantarillas',
  '200307': 'Residuos voluminosos',
  '200399': 'Residuos municipales no especificados en otra categoría.',
};
