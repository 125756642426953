import React, {FC, useState} from 'react';
import {Button, FormControl, Icon, Image, Input, VStack, Text, ScrollView, Stack} from 'native-base';
import {useAuthService} from 'services';
import {useTranslation} from 'react-i18next';
import {CookiesService} from '../cookies';
import {COOKIES_TYPES} from '../../constants';
import * as Yup from 'yup';
import {Message} from 'yup';
import {Formik} from 'formik';
import {useConfig} from '../../core';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '../../components/SharedToast';
import {AntDesign} from '@native-base/icons';

type Props = {
  onPasswordChanged: () => void;
  view: 'client' | 'backoffice';
  buttonText?: string;
  labelText1?: string;
  labelText2?: string;
  buttonStyle?: Record<string, unknown>;
  buttonStyleText?: Record<string, unknown>;
};

export const ChangePassword: FC<Props> = props => {
  const {t} = useTranslation();
  const authService = useAuthService();
  const [isLoading, setIsLoading] = useState(false);
  const [config] = useConfig();
  const sharedToast = useSharedToast();

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('Too Short') as Message)
      .required(t('Required') as Message),
    repeatPassword: Yup.string().oneOf([Yup.ref('password'), ''], t('Passwords must match').toString()),
  });

  const handlePasswordChange = (password: string) => {
    if (!config.oauth || !config.oauth.clientId || !config.oauth.clientSecret) {
      console.error('OAuth Module not configured properly.');
      return;
    }

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const token = params.get('token');
    if (token) {
      CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'recovery_token', token);
    }
    setIsLoading(true);
    authService
      .changePassword(
        {
          newPassword: password,
        },
        token
      )
      .response(() => {
        setIsLoading(false);
        sharedToast({
          title: t('Password updated successfully!'),
          description: '',
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
        props.onPasswordChanged();
      })
      .error(err => {
        setIsLoading(false);
        if ((err.type as string) === 'UNAUTHORIZED') {
          sharedToast({
            title: t('Something Went Wrong'),
            description: t('The link is no longer valid'),
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        } else if ((err.type as string) === 'ACCESS_TOKEN_EXPIRED') {
          sharedToast({
            title: t('Something Went Wrong'),
            description: t('The link has expired'),
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        }
      });
  };

  return props.view === 'backoffice' ? (
    <Formik
      initialValues={{
        password: '',
        repeatPassword: '',
      }}
      onSubmit={values => {
        handlePasswordChange(values.password);
      }}
      validationSchema={changePasswordSchema}>
      {({handleBlur, handleChange, handleSubmit, values, errors}) => (
        <>
          <FormControl justifyContent="center" alignContent="center" isRequired isInvalid={'password' in errors} key="password" mt={2}>
            <FormControl.Label variant="register">{props.labelText1 || t('New password')}</FormControl.Label>
            <Input
              onBlur={handleBlur('password')}
              onChangeText={handleChange('password')}
              type={'password'}
              value={values.password}
              variant="register"
              InputRightElement={
                values.password !== null && values.password !== '' ? (
                  <Icon
                    as={AntDesign}
                    name="closecircleo"
                    size={5}
                    color="secondary.500"
                    marginRight={2}
                    onPress={() => {
                      values.password = '';
                    }}
                  />
                ) : undefined
              }
            />
            {'repeatPassword' in errors && <FormControl.ErrorMessage>{errors.repeatPassword}</FormControl.ErrorMessage>}
          </FormControl>
          <FormControl justifyContent="center" alignContent="center" isRequired isInvalid={'repeatPassword' in errors} key="repeatPassword" mt={2}>
            <FormControl.Label variant="register">{props.labelText2 || t('Repeat password')}</FormControl.Label>
            <Input
              onBlur={handleBlur('repeatPassword')}
              onChangeText={handleChange('repeatPassword')}
              value={values.repeatPassword}
              type={'password'}
              variant="register"
              InputRightElement={
                values.repeatPassword !== null && values.repeatPassword !== '' ? (
                  <Icon
                    as={AntDesign}
                    name="closecircleo"
                    size={5}
                    color="secondary.500"
                    marginRight={2}
                    onPress={() => {
                      values.repeatPassword = '';
                    }}
                  />
                ) : undefined
              }
            />
            {'repeatPassword' in errors && <FormControl.ErrorMessage>{errors.repeatPassword}</FormControl.ErrorMessage>}
          </FormControl>
          <Button
            variant="register"
            mt={5}
            px={4}
            borderRadius={7}
            {...props.buttonStyle}
            onPress={() => handleSubmit()}
            isLoading={isLoading}
            isLoadingText={t('Submitting')!}>
            <Text color="primary.500" {...props.buttonStyleText}>
              {props.buttonText || t('Change Password')}
            </Text>
          </Button>
        </>
      )}
    </Formik>
  ) : (
    <Formik
      initialValues={{
        password: '',
        repeatPassword: '',
      }}
      onSubmit={values => {
        handlePasswordChange(values.password);
      }}
      validationSchema={changePasswordSchema}>
      {({handleBlur, handleChange, handleSubmit, values, errors}) => (
        <>
          <FormControl justifyContent="center" alignContent="center" isRequired isInvalid={'password' in errors} key="password">
            <FormControl.Label variant="register">{t('Nueva Contraseña')}</FormControl.Label>
            <Input
              onBlur={handleBlur('password')}
              onChangeText={handleChange('password')}
              type={'password'}
              value={values.password}
              variant="register"
              InputRightElement={
                values.password !== null && values.password !== '' ? (
                  <Icon
                    as={AntDesign}
                    name="closecircleo"
                    size={5}
                    color="secondary.500"
                    marginRight={2}
                    onPress={() => {
                      values.password = '';
                    }}
                  />
                ) : undefined
              }
            />
            {'repeatPassword' in errors && <FormControl.ErrorMessage>{errors.repeatPassword}</FormControl.ErrorMessage>}
          </FormControl>
          <FormControl justifyContent="center" alignContent="center" isRequired isInvalid={'repeatPassword' in errors} key="repeatPassword">
            <FormControl.Label variant="register">{t('Repetir contraseña')}</FormControl.Label>
            <Input
              onBlur={handleBlur('repeatPassword')}
              onChangeText={handleChange('repeatPassword')}
              value={values.repeatPassword}
              type={'password'}
              variant="register"
              InputRightElement={
                values.repeatPassword !== null && values.repeatPassword !== '' ? (
                  <Icon
                    as={AntDesign}
                    name="closecircleo"
                    size={5}
                    color="secondary.500"
                    marginRight={2}
                    onPress={() => {
                      values.repeatPassword = '';
                    }}
                  />
                ) : undefined
              }
            />
            {'repeatPassword' in errors && <FormControl.ErrorMessage>{errors.repeatPassword}</FormControl.ErrorMessage>}
          </FormControl>
          <VStack alignItems="end">
            <Button variant="register" mt={5} px={4} onPress={() => handleSubmit()} isLoading={isLoading} isLoadingText={t('Submitting')!}>
              <Text color="primary.500">{t('Change Password')} </Text>
            </Button>
          </VStack>
        </>
      )}
    </Formik>
  );
};
