import {SourceorDestination} from './sourceOrDestination';
export type IEPCISEvent = {
  //* required props
  eventTime: string | Date;
  eventTimeZoneOffset: string;
  sensorElementList?: SensorElement[];
  //? optional props
  bizLocation?: string;
  bizStep?: BIZSTEP;
  bizTransactionList?: string[];
  certificationInfo?: any;
  destinationList?: string[];
  disposition?: string;
  errorDeclaration?: any; //! https://ref.gs1.org/epcis/ErrorDeclaration
  persistentDisposition?: PersistentDisposition;
  readPoint?: string;
  sourceList?: SourceorDestination[];
};
/**
 * PersistentDisposition
 */
type PersistentDisposition = {
  set: string; //! https://ref.gs1.org/cbv/Disp
  unset: string; //! https://ref.gs1.org/cbv/Disp
};
/**
 * SensorElement
 */
type SensorElement = {
  sensorReport: SensorReport;
  sensorMetadata?: SensorMetadata;
};
type SensorReport = {
  //? optional props
  booleanValue?: boolean;
  chemicalSubstance?: string | URL;
  component?: string | COMPONENT;
  coordinateReferenceSystem?: string | URL;
  dataProcessingMethod?: string | URL;
  deviceID?: string | URL;
  deviceMetadata?: string | URL;
  exception?: string;
  hexBinaryValue?: string;
  maxValue?: number;
  meanValue?: number;
  measurementType?: string;
  microorganism?: string | URL;
  minValue?: number;
  percRank?: number;
  percValue?: number;
  rawData?: string | URL;
  sDev?: number;
  stringValue?: string;
  time?: string | Date;
  uom?: string;
  uriValue?: string | URL;
  value?: number;
};
type SensorMetadata = {
  //? optional props
  bizRules?: string | URL;
  dataProcessingMethod?: string | URL;
  deviceID?: string | URL;
  deviceMetadata?: string | URL;
  endTime?: Date | string;
  rawData?: string | URL;
  startTime?: Date | string;
  time?: Date | string;
};
/**
 * ENUMs
 */
enum COMPONENT {
  'comp-x' = 'x coordinate',
  'comp-y' = 'y coordinate',
  'comp-z' = 'z coordinate',
  'comp-axial_distance' = 'axial distance',
  'comp-azimuth' = 'azimuth',
  'comp-height' = 'height',
  'comp-spherical_radius' = 'spherical radius',
  'comp-polar_angle' = 'polar angle',
  'comp-elevation-angle' = 'elevation angle',
  'comp-easting' = 'easting',
  'comp-northing' = 'nothing',
  'comp-latitude' = 'latitude',
  'comp-longitude' = 'longitude',
  'comp-altitude' = 'altitude',
}
enum BIZSTEP {
  'BizStep-accepting' = 'accepting',
  'BizStep-arriving' = 'arriving',
  'BizStep-assembling' = 'assembling',
  'BizStep-collecting' = 'collecting',
  'BizStep-commissioning' = 'commissioning',
  'BizStep-consigning' = 'consigning',
  'BizStep-creating_class_instance' = 'creating class instance',
  'BizStep-cycle_counting' = 'cycle counting',
  'BizStep-decommissioning' = 'decommissioning',
  'BizStep-departing' = 'departing',
  'BizStep-destroying' = 'destroying',
  'BizStep-disassembling' = 'disassembling',
  'BizStep-dispensing' = 'dispensing',
  'BizStep-encoding' = 'encoding',
  'BizStep-entering_exiting' = 'entering exiting',
  'BizStep-holding' = 'holding',
  'BizStep-inspecting' = 'installing',
  'BizStep-killing' = 'killing',
  'BizStep-loading' = 'loading',
  'BizStep-other' = 'other',
  'BizStep-packing' = 'packing',
  'BizStep-picking' = 'picking',
  'BizStep-receiving' = 'receiving',
  'BizStep-removing' = 'removing',
  'BizStep-repackaging' = 'repackaging',
  'BizStep-repairing' = 'repairing',
  'BizStep-replacing' = 'replacing',
  'BizStep-reserving' = 'reserving',
  'BizStep-retail_selling' = 'retail selling',
  'BizStep-sampling' = 'sampling',
  'BizStep-sensor_reporting' = 'sensor reporting',
  'BizStep-shipping' = 'shipping',
  'BizStep-staging_outbound' = 'staging outbound',
  'BizStep-stock_taking' = 'stock taking',
  'BizStep-stocking' = 'stocking',
  'BizStep-storing' = 'storing',
  'BizStep-transporting' = 'transporting',
  'BizStep-unloading' = 'unloading',
  'BizStep-unpacking' = 'unpacking',
  'BizStep-void_shipping' = 'void shipping',
}
