import {LangString} from '../rdf/langString';
import {StatusType} from './statusType';
import {PostalAddress} from './postalAddress';
import {ContactPoint} from './contactPoint';
import {AnyURI} from '../xsd/anyURI';
import {CertificationDetails} from './certificationDetails';
import {Place} from './place';
import {Offer} from './offer';
import {GLN_TypeCode} from './gln_TypeCode';

export type OrganizationStatusHistory = {
  organizationStatus: StatusType;
  statusTimestamp: Date;
};

export enum OrganizationRoleType {
  ACCEPTING_PARTY = 'Accepting Organization',
  BILL_OF_LADING_RECIPIENT = 'Bill of Lading Recipient',
  BILL_TO = 'Bill To',
  BRAND_OWNER = 'Brand Owner',
  BREEDER = 'Breeder',
  BROKER_AGENT = 'Broker Agent',
  BUYER = 'Buyer',
  BUYERS_AGENT_REPRESENTATIVE = 'Buyers Agent Representative',
  CARRIER = 'Carrier',
  CENTRAL_PAYMENT_SERVICE = 'Central Payment Service',
  CHECKING_PARTY = 'Checking Organization',
  CHEQUE_ORDER = 'Cheque Order',
  CONSIGNEE = 'Consignee.',
  CONSOLIDATOR = 'Consolidator',
  CONSUMER = 'Consumer',
  CORPORATE_IDENTITY = 'Corporate Identity',
  CUSTOMS = 'Customs',
  CUSTOMS_BROKER = 'Customs Broker',
  CUTTER = 'Cutter',
  DECLARANTS_AGENT_REPRESENTATIVE = 'Declarants Agent Representative',
  DELIVERY_PARTY = 'Delivery Organization',
  DESIGNER = 'Designer',
  DESPATCH_PARTY = 'Despatch Organization',
  DISTRIBUTOR = 'Distributor',
  DOCK_DOOR = 'Dock Door',
  EMPTY_EQUIPMENT_DESPATCH_PARTY = 'Empty Equipment Despatch Organization',
  EMPTY_EQUIPMENT_RETURN_PARTY = 'Empty Equipment Return Organization',
  EQUIPMENT_OWNER = 'Equipment Owner',
  EXPORTER = 'Exporter',
  FACTOR = 'Factor',
  FATTENER = 'Fattener',
  FREIGHT_FORWARDER = 'Freight Forwarder',
  GOODS_OWNER = 'Goods Owner',
  GROWER = 'Grower',
  HEAD_OFFICE = 'Head Office',
  IMPORTER = 'Importer',
  INFORMATION_PROVIDER = 'Information Provider',
  INSURER = 'Insurer',
  INTERMEDIARY_BANK_1 = 'Intermediary Bank 1',
  INTERMEDIARY_BANK_2 = 'Intermediary Bank 2',
  INVENTORY_CONTROLLER = 'Inventory Controller',
  INVENTORY_REPORTING_PARTY = 'Inventory Reporting Organization',
  INVOICEE = 'Invoicee',
  ISSUER_OF_INVOICE = 'Issuer of Invoice',
  LOGISTICS_SERVICE_PROVIDER = 'Logistics Service Provider',
  MANUFACTURER_OF_GOODS = 'Manufacturer of Goods',
  MANUFACTURING_PLANT = 'Manufacturing Plant',
  MARK_FOR = 'Mark For',
  MESSAGE_FROM = 'Message From.',
  MESSAGE_RECIPIENT = 'Message Recipient',
  MINCER = 'Mincer',
  OPERATING_DIVISION = 'Operating Division',
  OPERATOR = 'Operator',
  ORDERING_PARTY = 'Ordering Organization',
  OWNER_OF_EQUIPMENT = 'Owner of equipment',
  OWNER_OF_MEANS_OF_TRANSPORT = 'Owner of Means of Transport',
  PARTY_DECLARING_THE_VALUE_ADDED_TAX = 'Organization Declaring the Value Added Tax',
  PARTY_FOR_WHOM_ITEM_IS_ULTIMATELY_INTENDED = 'Organization For Whom Item is Ultimately Intended',
  PARTY_RECEIVING_PRIVATE_DATA = 'Organization Receiving Private Data',
  PARTY_RECOVERING_THE_VALUE_ADDED_TAX = 'Organization Recovering the Value Added Tax',
  PARTY_TO_RECEIVE_ALL_DOCUMENTS = 'Organization to Receive All Documents',
  PARTY_TO_RECEIVE_COMMERCIAL_INVOICE = 'Organization to Receive Commercial Invoice',
  PARTY_TO_RECEIVE_ELECTRONIC_MEMO_OF_INVOICE = 'Organization to Receive Electronic Memo of Invoice',
  PARTY_TO_RECEIVE_FREIGHT_BILL = 'Organization to Receive Freight Bill',
  PARTY_TO_RECEIVE_REFUND = 'Organization to Receive Refund',
  PAYEE = 'Payee',
  PAYER = 'Payer',
  POINT_OF_SALE = 'Point Of Sale',
  PRICE_LOCATION_PARTY = 'Price Location Organization',
  PROXY = 'Proxy',
  PUBLIC_WAREHOUSE = 'Public Warehouse',
  PURCHASE_ORDER_RECEIVER = 'Purchase Order Receiver',
  REGISTERED_AGENT = 'Registered Agent',
  REMIT_TO = 'Remit To',
  RETURNABLE_ASSET_SERVICE_CENTRE = 'Returnable Asset Service Centre',
  SELLER = 'Seller',
  SERVICE_PROVIDER = 'Service Provider',
  SHIP_FROM = 'Ship From',
  SHIP_TO = 'Ship To',
  SLAUGHTERER = 'Slaughterer',
  STORE = 'Store',
  SUBSTITUTE_SUPPLIER = 'Substitute Supplier',
  SUPPLIER = 'Supplier',
  TRANSPORTATION_CARRIER = 'Transportation Carrier',
  VISITING_ADDRESS = 'Visiting Address',
  WAREHOUSE_AND_OR_DEPOT = 'Warehouse and or Depot',
  WAREHOUSE_KEEPER = 'Warehouse Keeper',
  WHOLESALER = 'WholeSaler',
}

export enum OrganizationID_Type {
  AU_ABN = 'Australian Business Number',
  AU_ACN = 'Australian Company Number',
  CRD_PARTNER_CODE = 'Central Reference File Database (CRD) covers both Locations and Organisations. The Partner Reference File uniquely identifies all rail actors who exchange information (Company Codes).  Rail context',
  CRN = 'Company Registration Number (CRN)',
  DID = 'Decentralized identifier',
  DUNS = 'DUNS',
  DUNS_PLUS_FOUR = 'DUNS+4',
  EORI = 'Economic Operators Registration and Identification number',
  EO_ID = 'Economic Operator Identifier',
  EU_VAT_IDENTIFICATION_NUMBER = 'EU VAT Identification Number',
  FR_RCS = 'Registre du commerce et des sociÃ©tÃ©s',
  FR_SIREN = "SystÃ¨me d'identification du rÃ©pertoire des entreprises",
  IMO_COMPANY_NUMBER = 'International Maritime Organisation (IMO) Company Number',
  LEI = ' Legal Entity Identifier (LEI)',
  NL_KVK_NUMBER = 'Kamer van Koophandel (KVK) number',
  NL_OIN_NUMBER = 'Organisatie-identificatienummer (OIN number)',
  NZ_GST_NUMBER = 'New Zealand GST Number',
  NZ_IRD_NUMBER = 'New Zealand Inland Revenue Identification Number',
  ORG_FOR_INTERNAL_USE_1 = 'Organization For Internal Use 1',
  ORG_FOR_INTERNAL_USE_10 = 'Organization For Internal Use 10',
  ORG_FOR_INTERNAL_USE_2 = 'Organization For Internal Use 2',
  ORG_FOR_INTERNAL_USE_3 = 'Organization For Internal Use 3',
  ORG_FOR_INTERNAL_USE_4 = 'Organization For Internal Use 4',
  ORG_FOR_INTERNAL_USE_5 = 'Organization For Internal Use 5',
  ORG_FOR_INTERNAL_USE_6 = 'Organization For Internal Use 6',
  ORG_FOR_INTERNAL_USE_7 = 'Organization For Internal Use 7',
  ORG_FOR_INTERNAL_USE_8 = 'Organization For Internal Use 8',
  ORG_FOR_INTERNAL_USE_9 = 'Organization For Internal Use 9',
  PHYTOSANITARY_REGISTRATION_NUMBER = 'Phytosanitary registration number ',
  TIN = 'Taxpayer Identification Number',
  UK_CRN = 'U.K. Company Registration Number ',
  US_498_ID = 'Service Provider and Billed Entity Identification Number (FCC Form 498 ID)',
  US_499_FILER_ID = '499 Filer ID',
  US_BEA_ID = 'BEA Foreign Direct Investment Identification Number',
  US_CAGE = 'Commercial and Government Entity',
  US_CID = 'Company Identifier (CID)',
  US_CRD = 'Central Registration Depository (CRD) Numbers',
  US_DEA = 'Drug Enforcement Administration (DEA) Number',
  US_DODAAC = 'Department of Defense Activity Address Code',
  US_EIN = 'Employer Identification Number (EIN)',
  US_EPA_ID = 'EPA Identification Number',
  US_FCC_FRN = 'FCC Registration Number (FRN)',
  US_FDIC = 'Federal Deposit Insurance Corporation (FDIC) Certificate Number',
  US_FHFA_ID = 'FHFA ID Number',
  US_FMC_ID = 'FMC License Number ',
  US_GIIN = 'Global Intermediary Identification Number (GIIN)',
  US_LAB_ID = 'U.S. Lab ID',
  US_LIC = 'Labeler Identification code (LIC) ',
  US_MFG_ID = 'Manufacturer ID ',
  US_MIC = "Manufacturer's Identification Code (MIC)",
  US_NPI = 'National Provider Identifier (NPI)',
  US_RN = 'Registered Identification Number (RN)',
  US_RSSD_ID = 'Replication Server System Database ID (RSSD ID)',
  US_SCAC = 'Standard Carrier Alpha Code (SCAC)',
  US_UEI = 'Unique Entity Identifier (UEI)',
}

export type OrganizationID_Details = {
  organizationID: string;
  organizationID_Qualifier: string;
  organizationID_Type: Partial<OrganizationID_Type>;
  organizationID_URI: Partial<AnyURI>;
};

export type Organization = {
  additionalOrganizationID: any;
  additionalOrganizationIdentificationTypeValue: string;
  address: Partial<PostalAddress>;
  afterHoursContact: Partial<ContactPoint>;
  certification: Partial<CertificationDetails>;
  contactPoint: Partial<ContactPoint>;
  department: Partial<LangString>;
  glnType: Partial<GLN_TypeCode>;
  globalLocationNumber: string;
  hasPrimaryLocation: Partial<Place>[];
  lesseeOf: Partial<Place>[];
  lessorFor: Partial<Place>[];
  location: Partial<Place>[];
  makesOffer: Partial<Offer>;
  managedBy: Partial<Organization>;
  manages: Partial<Organization | Place>[];
  occupies: Partial<Place>[];
  organizationHistory: Partial<OrganizationStatusHistory>;
  organizationName: Partial<LangString>;
  organizationRole: Partial<OrganizationRoleType>;
  ownedBy: Partial<Organization>[];
  owns: Partial<Organization | Place>[];
  parentOrganization: Partial<Organization>;
  partyGLN: string;
  replacedByOrganization: Partial<Organization>;
  replacedOrganization: Partial<Organization>;
  responsibleForLocation: Partial<Place>[];
  subOrganization: Partial<Organization>;
  usesManagedLocation: Partial<Place>[];
};
