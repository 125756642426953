import React, {FC, PropsWithChildren, useEffect, useReducer} from 'react';
import {configReducer, SharedContext} from './ConfigHook';
import {SharedComponentsSettings} from '../types';

type Props = PropsWithChildren<{
  config: SharedComponentsSettings;
}>;

export const SharedComponentsProvider: FC<Props> = (props: Props) => {
  const [state, dispatch] = useReducer(configReducer, []);

  useEffect(() => {
    if (props.config) {
      dispatch({type: 'set', config: props.config});
    }
  }, []);
  if (!state.config) return null;

  return <SharedContext.Provider value={[state, dispatch] as any}>{props.children}</SharedContext.Provider>;
};
