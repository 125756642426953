// created from 'create-ts-index'

export * from './digital_product_passport';
export * from './IDIDdocument';
export * from './iCertification';
export * from './iTypesCredentials';
export * from './ibase';
export * from './iblockchain.transaction';
export * from './inotification';
export * from './ioffer';
export * from './iproduct';
export * from './iquantitative.value';
export * from './ithing';
export * from './iuser';
export * from './organizationDpp';
export * from './placeDpp';
