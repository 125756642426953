import {MultilanguageStringGeneric as SC_MultilanguageStringGeneric} from '@bri/shared-core';

// List of available languages for this app.
export const AVAILABLE_LANGUAGES = (<T extends string[]>(...fields: T) => fields)('es', 'ca', 'en', 'fr');

export type AVAILABLE_LANGUAGES_TYPE = typeof AVAILABLE_LANGUAGES[number];

// All multilanguage data must be in this language (at least).
export const DEFAULT_LANGUAGE: AVAILABLE_LANGUAGES_TYPE = 'es';

export type MultilanguageStringGeneric = SC_MultilanguageStringGeneric;

export type MultilanguageString = {
  [key in typeof AVAILABLE_LANGUAGES[number]]?: string;
};
