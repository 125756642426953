import {AnyURI} from '../xsd/anyURI';
import {LangString} from '../rdf/langString';
import {ContactPoint} from './contactPoint';
import {Organization} from './organization';
import {PostalAddress} from './postalAddress';
import {CertificationDetails} from './certificationDetails';
import {GLN_TypeCode} from './gln_TypeCode';
import {StatusType} from './statusType';

export enum LocationID_Type {
  BIC_FACILITY = 'BIC Facility Code (BFC)',
  CRD_LOCATION_CODE = 'Central Reference File Database (CRD) covers both Locations and Organisations. The Location Reference File uniquely identifies physical rail points (e.g. stations, customer sidings, loading places)',
  ENI_NUMBER = 'An ENI number (European Number of Identification or European Vessel Identification Number).',
  FR_SIRET = "SystÃ¨me d'identification du rÃ©pertoire des Ã©tablissements",
  IATA_CODE = 'International Air Transport Association (IATA) code',
  IMO_NUMBER = 'International Maritime Organization Number',
  ISPS_CODE = 'International Ship and Port Facility Security Code (ISPS Code)',
  ISRS_LOCATION_CODE = 'International Ship Reporting Standard or ISRS location code ',
  LOCATION_FOR_INTERNAL_USE_1 = 'Location For Internal Use 1',
  LOCATION_FOR_INTERNAL_USE_10 = 'Location For Internal Use 10',
  LOCATION_FOR_INTERNAL_USE_2 = 'Location For Internal Use 2',
  LOCATION_FOR_INTERNAL_USE_3 = 'Location For Internal Use 3',
  LOCATION_FOR_INTERNAL_USE_4 = 'Location For Internal Use 4',
  LOCATION_FOR_INTERNAL_USE_5 = 'Location For Internal Use 5',
  LOCATION_FOR_INTERNAL_USE_6 = 'Location For Internal Use 6',
  LOCATION_FOR_INTERNAL_USE_7 = 'Location For Internal Use 7',
  LOCATION_FOR_INTERNAL_USE_8 = 'Location For Internal Use 8',
  LOCATION_FOR_INTERNAL_USE_9 = 'Location For Internal Use 9',
  MMSI_NUMBER = 'Maritime Mobile Service Identity number',
  OAR_ID = 'Open Apparel Registry ID',
  OPEN_LOCATION_CODE = 'Open Location Code',
  SMDG_CODE = 'SMDG Container terminal code',
  UN_LOCODE = 'UN/LOCODE',
  US_CIF_CODE = 'Customer Identification File (CIF) for RAIL ',
  US_EST_NUMBER = 'USDA Establishment Number',
  US_FEI = 'FDA Establishment Identifier (Facility FEI)',
  US_HIN_IDENTIFICATION = 'Hull Identification Number ',
  US_MINE_ID = 'U.S. Mine ID',
  US_TCN_CODE = 'Terminal Control Number (TCN) / Terminal Locations Directory. USA â€“ IRS.',
}

export type LocationID_Details = {
  locationID: string;
  locationID_Qualifier: string;
  locationID_Type: Partial<LocationID_Type>;
  locationID_URI: Partial<AnyURI>;
};

export type GeoCoordinates = {
  coordinateReferenceSystem: Partial<AnyURI>;
  elevation: string;
  latitude: string;
  longitude: string;
};

export type GeoShape = {
  circle: string;
  line: string;
  polygon: string;
};

export type LocationStatusHistory = {
  locationStatus: Partial<StatusType>;
  statusTimestamp: Date;
};

export type Place = {
  additionalLocationID: Partial<LocationID_Details>;
  address: Partial<PostalAddress>;
  afterHoursContact: Partial<ContactPoint>;
  baseLocation: Partial<Place>;
  certification: Partial<CertificationDetails>;
  contactPoint: Partial<ContactPoint>;
  containedInPlace: Partial<Place>;
  containsPlace: Partial<Place>;
  digitalAddress: string;
  digitalLocationName: Partial<LangString>;
  geo: Partial<GeoCoordinates | GeoShape>;
  glnType: Partial<GLN_TypeCode>;
  globalLocationNumber: string;
  leasedFrom: Partial<Organization>;
  leasedTo: Partial<Organization>;
  locationGLN: string;
  locationHistory: Partial<LocationStatusHistory>;
  locationRole: any; // https://www.gs1.org/voc/LocationRoleType lanza error 404
  managedBy: Partial<Organization>;
  managedFor: Partial<Organization>;
  occupiedBy: Partial<Organization>;
  ownedBy: Partial<Organization>;
  physicalLocationName: Partial<LangString>;
  primaryLocationOf: Partial<Organization>;
  relatedOrganization: Partial<Organization>;
  replacedByPlace: Partial<Place>;
  replacedPlace: Partial<Place>;
  responsibleOrganization: Partial<Organization>;
};
