import React, {useEffect, useState} from 'react';
import {GoogleMap, GroundOverlay, GroundOverlayProps, InfoWindow, Marker, Polyline, useJsApiLoader} from '@react-google-maps/api';
import {View} from 'native-base';

type Props = {
  locations?: Location[];
  polylines?: {
    options: google.maps.PolylineOptions;
    index: string;
  }[];
  zoom?: number;
  center?: {coords: {lat: number; lng: number}};
  rounded?: number;
  apikey: string;
  iconsHidden?: boolean;
  containerStyle?: any;
  groundOverlay?: GroundOverlayProps;
  restriction?: {
    latLngBounds: {
      north: number;
      south: number;
      east: number;
      west: number;
    };
    strictBounds: boolean;
  };
};

export type Location = {
  coords: {lat: number; lng: number};
  icon?: {symbol: google.maps.Symbol; anchor: {x: number; y: number}};
  iconImage?: google.maps.Icon;
  info?: {title: string; description: string};
  onClick?: () => void;
};

const styles: Record<string, google.maps.MapTypeStyle[]> = {
  default: [],
  hide: [
    {
      featureType: 'poi',
      stylers: [{visibility: 'off'}],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}],
    },
  ],
};

export const MyNewMapComponent = (props: Props) => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: props.apikey,
  });

  const [map, setMap] = React.useState(null);
  const [selectedMarker, setSelectedMarker] = useState<any>(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      if (!props.zoom) {
        const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
        props.locations?.forEach(location => bounds.extend(location.coords));
        (map as any).fitBounds(bounds);
      }
    }
  }, [map]);

  const handleMarkerClick = (location: any) => {
    if (location.onClick) {
      location.onClick();
    } else {
      setSelectedMarker(location);
    }
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  function setIcon(location: any) {
    let returnIcon: string | google.maps.Symbol | google.maps.Icon | undefined;
    if (location?.icon) {
      returnIcon = {...location?.icon.symbol, anchor: new google.maps.Point(location.icon.anchor.x, location.icon.anchor.y)};
    } else if (location?.iconImage) {
      returnIcon = location.iconImage;
    }
    return returnIcon;
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{width: '100%', height: '100%', borderRadius: props.rounded ? props.rounded.toString() + 'px' : '0px', ...props.containerStyle}}
      center={
        props.center
          ? props.center.coords
          : props.locations
          ? props.locations.length > 0
            ? props.locations[0].coords
              ? props.locations[0].coords
              : undefined
            : undefined
          : undefined
      }
      options={{
        disableDefaultUI: true,
        styles: props.iconsHidden ? styles.hide : [],
        restriction: props.restriction,
      }}
      zoom={props.zoom ? props.zoom : 3}
      onLoad={onLoad}
      onUnmount={onUnmount}>
      {props.locations?.map((location, index) => (
        // todo: Spiderfy
        <Marker key={index} position={location?.coords} icon={setIcon(location)} onClick={() => handleMarkerClick(location)}>
          {selectedMarker && selectedMarker.info && selectedMarker === location && (
            <InfoWindow position={selectedMarker.position} onCloseClick={handleInfoWindowClose}>
              <div style={{color: 'rgb(0, 89, 167)'}}>
                <h3>{selectedMarker.info.title}</h3>
                <p>{selectedMarker.info.description}</p>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}

      {props.polylines?.map(polyline => (
        <Polyline key={polyline.index} options={{...polyline.options, clickable: false}} />
      ))}
      {props.groundOverlay && <GroundOverlay {...props.groundOverlay} />}
    </GoogleMap>
  ) : (
    <View flex={1} />
  );
};
