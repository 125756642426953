export type DIDDocumentCircularTrustV1 = {
  '@context'?: string | string[];
  id: string;
  controller?: string | string[];
  authentication?: Iden3StateInfo2023[];
  service?: Service[];
  info?: string;
  active: boolean;
};

export interface Authentication {
  id: string;
  type: string;
  controller?: string;
}

export interface Service {
  id: string;
  type: string;
  serviceEndpoint: string;
}

// Authentication used by iden3
export type Iden3StateInfo2023 = Authentication & {
  published: boolean;
  info: {
    id: string;
    state: string;
    replacedByState: string;
    createdAtTimestamp: string;
    replacedAtTimestamp: string;
    createdAtBlock: string;
    replacedAtBlock: string;
  };
  global?: {
    id: string;
    state: string;
    replacedByState: string;
    createdAtTimestamp: string;
    replacedAtTimestamp: string;
    createdAtBlock: string;
    replacedAtBlock: string;
  };
};

export type Credential = {
  id: string;
  '@context': string[];
  '@type': string[] | string;
  issuer: string;
  issuanceDate: string;
  expirationDate: string;
  credentialSubject: any;
  rev_nonce: string;
  proof: {
    type: string;
    created: string;
    jws: string;
    proofPurpose: string;
    verificationMethod: string;
  };
};

export enum CREDENTIAL_STATUS {
  VALID = 'VALID',
  PENDING_VALIDATION = 'PENDING_VALIDATION',
  PENDING_REVOKATION = 'PENDING_REVOCATION',
  REVOCATED = 'REVOCATED',
}
