import React from 'react';
import {AlertDialog, Button} from 'native-base';
import {useTranslation} from 'react-i18next';
import {CookiesService} from './CookiesService';
import {COOKIES_TYPES} from '../../constants';

// Typing

type Props = {
  technicalCookieEnabled?: () => void;
  personalizationCookieEnabled?: () => void;
  analysisCookieEnabled?: () => void;
  advertisingCookieEnabled?: () => void;
  behaviourAdvertisingCookieEnabled?: () => void;
  onAcceptPres?: () => void;
  maxWidth: number;
};

export function CookiesBanner(props: Props) {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef(null);
  // let iOSPermissionsRequests = 0;

  React.useEffect(() => {
    const fetchData = async () => {
      setIsOpen(!(await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, COOKIES_TYPES.TECHNICAL)));
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
    // this.props.loadCookiesInfo();
    // if (this.props.show && !isExpo && isiOSApp) {
    //   _requestIOSPermissions();
    // } else if (!isExpo && isiOSApp) {
    //   this.watch('show', async (newValue, oldValue) => {
    //     if (!newValue) return;
    //     this._requestIOSPermissions();
    //   });
    // }
  }, []);

  // const _requestIOSPermissions = async () => {
  //   if (iOSPermissionsRequests >= 5 || !isOpen) return;
  //   iOSPermissionsRequests += 1;
  //
  //   const {status} = await requestTrackingPermissionsAsync();
  //   if (status === PermissionStatus.GRANTED) {
  //     await _createCookies(true);
  //   } else if (status === PermissionStatus.DENIED) {
  //     await _createCookies(false);
  //   } else {
  //     _requestIOSPermissions();
  //   }
  // };

  async function _createCookies() {
    await CookiesService.setType(COOKIES_TYPES.TECHNICAL, COOKIES_TYPES.TECHNICAL, 'true');
    await CookiesService.setType(COOKIES_TYPES.PERSONALISATION, COOKIES_TYPES.PERSONALISATION, 'true');
    await CookiesService.setType(COOKIES_TYPES.ADVERTISING, COOKIES_TYPES.ADVERTISING, 'true');
    await CookiesService.setType(COOKIES_TYPES.ANALYSIS, COOKIES_TYPES.ANALYSIS, 'true');
    await CookiesService.setType(COOKIES_TYPES.BEHAVIOURAL_ADVERTISING, COOKIES_TYPES.BEHAVIOURAL_ADVERTISING, 'true');
    // this.props.loadCookiesInfo();
    props.technicalCookieEnabled && props.technicalCookieEnabled();
    props.personalizationCookieEnabled && props.personalizationCookieEnabled();
    props.advertisingCookieEnabled && props.advertisingCookieEnabled();
    props.analysisCookieEnabled && props.analysisCookieEnabled();
    props.behaviourAdvertisingCookieEnabled && props.behaviourAdvertisingCookieEnabled();
  }

  const onButtonPress = async () => {
    await _createCookies();
    onClose();
  };

  // if (isiOSApp) {
  //   return <></>;
  // }

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose} size="full" alignSelf="center" justifyContent="flex-end">
      <AlertDialog.Content position={'sticky'} bottom={3} maxW={props.maxWidth}>
        {/* <AlertDialog.Header>Usamos cookies</AlertDialog.Header> */}
        <AlertDialog.Body>Cookies informations</AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button
              variant="unstyled"
              colorScheme="coolGray"
              onPress={async () => {
                await onButtonPress();
                if (props.onAcceptPres) props.onAcceptPres();
              }}
              ref={cancelRef}>
              {t('Configure')}
            </Button>
            <Button colorScheme="primary" onPress={onButtonPress}>
              {t('I accept')}
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
}
