import {BaseServerError} from '../BaseServerError';

export class ElasticSearchError extends BaseServerError {
  code: number;
  type: string;
  data: any;

  constructor(message: string, data?: any) {
    super(message, data);
    this.code = 307;
    this.type = 'ELASTIC';
  }
}
