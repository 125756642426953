import {PermissionEndpointsType} from '@bri/shared-core';

export enum USER_ACTIONS {
  // OAuth actions
  OAUTH = 'OAUTH',

  // Auth required
  USE_APP = 'USE_APP',
  USE_APP_ADMIN = 'USE_APP_ADMIN',
  USER_CRUDL = 'USER_CRUDL',
  NOTIFICATIONS = 'NOTIFICATIONS',
  PRODUCT_CRUDL = 'PRODUCT_CRUDL',
  CUSTOMER_CRUDL = 'CUSTOMER_CRUDL',
  GROUPS_CRUDL = 'GROUPS_CRUDL',
  PRICES_CRUDL = 'PRICES_CRUDL',
  CHALLENGES_CRUDL = 'CHALLENGES_CRUDL',
  ACCESS_CODE_CRUDL = 'ACCESS_CODE_CRUDL',
  RIDES_CRUDL = 'RIDES_CRUDL',
}

export const PermissionEndpoints: PermissionEndpointsType<USER_ACTIONS> = {
  /**
   * No Auth
   */
  [USER_ACTIONS.OAUTH]: ['v1.oauth.authorize', 'v1.oauth.login', 'v1.oauth.token', 'v1.oauth.refresh', 'v1.oauth.register'],
  [USER_ACTIONS.GROUPS_CRUDL]: [
    'v1.groups.register',
    'v1.groups.login',
    'v1.groups.challangeStart',
    'v1.groups.huntProgress',
    'v1.groups.challengersSeeding',
    'v1.groups.challangerEnd',
    'v1.groups.ranking',
    'v1.groups.getQrUrl',
    'v1.groups.getUsername',
    'v1.groups.loginVisitor',
  ],
  [USER_ACTIONS.ACCESS_CODE_CRUDL]: ['v1.accessCode.classRegistration'],
  [USER_ACTIONS.RIDES_CRUDL]: ['v1.rides.getRide', 'v1.rides.ridesSeeding', 'v1.rides.incCounter'],

  [USER_ACTIONS.PRICES_CRUDL]: ['v1.prices.get', 'v1.prices.getAvailablePrices', 'v1.prices.getGroupPrices', 'v1.prices.playRoulette', 'v1.prices.checkRoulette'],
  [USER_ACTIONS.CHALLENGES_CRUDL]: ['v1.challenges.get', 'v1.challenges.getAll'],
  /**
   * Auth
   */
  [USER_ACTIONS.USE_APP]: ['v1.oauth.me'],
  [USER_ACTIONS.USE_APP_ADMIN]: ['v1.oauth.me'],

  [USER_ACTIONS.USER_CRUDL]: ['v1.users.create', 'v1.users.get', 'v1.users.list', 'v1.users.edit', 'v1.users.remove'],
  [USER_ACTIONS.NOTIFICATIONS]: ['v1.notifications.registerToken', 'v1.notifications.removeToken', 'v1.notifications.list'],
  [USER_ACTIONS.PRODUCT_CRUDL]: ['v1.products.list'],
  [USER_ACTIONS.CUSTOMER_CRUDL]: ['v1.customers.list'],
};
