import {View} from 'native-base';
import React, {FC, useEffect, useMemo, useState} from 'react';
// eslint-disable-next-line import/no-named-default
import {default as RectApexChart} from 'react-apexcharts';
import {useTranslation} from 'react-i18next';
import {ApexOptions} from 'apexcharts';
import './Graph.css';
import {getChartOption} from 'components/Graph/GraphOptions';

export enum STATISTIC_PERIODIC {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

type Props = {
  id: string;
  type: ApexChart['type'];
  data: ApexAxisChartSeries;
  labels: ApexOptions['labels'];
  height: number;
  showAxis: boolean;
  period: STATISTIC_PERIODIC;
  onReady?: () => void;
  fontFamily?: string;
  title?: string;
  yUnit?: string;
  export?: boolean;
  readonly?: boolean;
};

export const Chart: FC<Props> = props => {
  const {t} = useTranslation();

  const [options, setOptions] = useState<ApexCharts.ApexOptions>({
    chart: {
      id: props.id,
      fontFamily: 'apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    },
    theme: {
      mode: 'light',
    },
    tooltip: {
      theme: 'dark',
    },
    xaxis: {
      categories: props.labels,
    },
  });

  const [series, setSeries] = useState<ApexAxisChartSeries>(props.data);

  const layoutProps = useMemo(
    () => ({
      fontFamily: props.fontFamily,
      height: props.height || 200,
    }),
    [props.height, props.fontFamily]
  );

  useEffect(() => {
    if (!props.data || props.data.length === 0) {
      return;
    }

    setSeries(props.data);
    const options = getChartOption(
      t,
      layoutProps,
      props.type,
      props.data,
      props.labels,
      props.showAxis,
      props.period,
      props.yUnit,
      props.readonly,
      props.export,
      props.title,
      props.onReady,
      false
    );
    setOptions(options);
  }, [props.data, props.labels]);

  return (
    <View>
      <RectApexChart options={options} series={series} type="line" width="100%" height={layoutProps.height} />
    </View>
  );
};
