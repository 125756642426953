import React, {FC, useEffect, useState} from 'react';
import {Button, FormControl, Heading, Image, Input, Link, VStack} from 'native-base';
import {useAuthService} from 'services';
import {useSetRecoilState} from 'recoil';
import {loggedUser} from '../recoil';
import {useTranslation} from 'react-i18next';
import * as randomstring from 'randomstring';
import sha256 from 'sha256';
import {CookiesService} from '../cookies';
import {COOKIES_TYPES} from '../../constants';
import * as Yup from 'yup';
import {Message} from 'yup';
import {Formik} from 'formik';
import {useConfig} from '../../core';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '../../components/SharedToast';
// @ts-ignore
import logo from '../../assets/images/logo.png';

type Props = {
  logo?: {
    file: string;
    width: number;
    height: number;
  };
  // At this point you can enter here "ROLE:4" or "ROLE:4 ROLE:5" "ACTION:XXX" or even undefined
  scope?: string;
  onForgotPasswordClick?: () => void;
  hidePasswordRecovery?: boolean;
};

export const Login: FC<Props> = ({hidePasswordRecovery = false, ...props}) => {
  const {t} = useTranslation();
  const authService = useAuthService();
  const setUser = useSetRecoilState(loggedUser);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [config] = useConfig();
  const sharedToast = useSharedToast();

  useEffect(() => {
    const fetchData = async () => {
      if (await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, 'access_token')) {
        authService
          .me()
          .apiResponse(() => {
            setLoading(false);
          })
          .response(resp => {
            setUser(resp.user);
          })
          .error(err => console.log(err));
      } else {
        setLoading(false);
      }
    };
    fetchData().catch(console.error);
  }, []);

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      // .email(t('Invalid email') as Message)
      .required(t('Required') as Message),
    password: Yup.string()
      .min(8, t('Too Short') as Message)
      .required(t('Required') as Message),
  });

  if (!config.oauth || !config.oauth.clientId || !config.oauth.clientSecret) {
    return null;
  }

  const submitLogin = (username: string, password: string) => {
    if (!config.oauth || !config.oauth.clientId || !config.oauth.clientSecret) {
      console.error('OAuth Module not configured properly.');
      return;
    }
    setIsLoading(true);
    const codeVerifier = randomstring.generate();
    const state = randomstring.generate();
    const codeChallenge = sha256(codeVerifier);
    authService
      .login({
        username,
        password,
        client_id: config.oauth.clientId,
        client_secret: config.oauth.clientSecret,
        response_type: 'code',
        redirect_uri: 'localhost',
        state,
        scope: props.scope,
        code_challenge_method: 'S256',
        code_challenge: codeChallenge,
      })
      .apiResponse(apiResponse => {
        if (!apiResponse.response) {
          setIsLoading(false);
        }
      })
      .response(response => {
        if (response.state === state) {
          authService
            .token({
              grant_type: 'authorization_code',
              redirect_uri: 'localhost',
              code: response.code,
              code_verifier: codeVerifier,
            })

            .response(async res => {
              await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'access_token', res.access_token);
              await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'refresh_token', res.refresh_token);
              authService
                .me()
                .response(resp => {
                  setUser(resp.user);
                })
                .error(err => {
                  setIsLoading(false);
                  sharedToast({
                    title: t('Something Went Wrong'),
                    description: err.type as string,
                    status: TOAST_STATUS.ERROR,
                    variant: TOAST_VARIANT.SUBTLE,
                  });
                });
            })
            .error(err => {
              setIsLoading(false);
              sharedToast({
                title: t('Something Went Wrong'),
                description: err.type as string,
                status: TOAST_STATUS.ERROR,
                variant: TOAST_VARIANT.SUBTLE,
              });
            });
        } else {
          setIsLoading(false);
          sharedToast({
            title: t('Something Went Wrong'),
            description: t('Server Error [OAUTH STATE]'),
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        }
      })
      .error(err => {
        setIsLoading(false);
        if (err.code === 307) {
          sharedToast({title: t('Invalid Credentials'), description: t('Incorrect Username or Password')});
        } else {
          sharedToast({
            title: t('Something Went Wrong'),
            description: err.type as string,
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        }
      });
  };

  if (loading) return null;

  return (
    <VStack space={4} alignItems="center" backgroundColor="white" px={10} py={8} borderRadius={20} width={450}>
      <Image source={{uri: props.logo?.file || logo}} alt={t('Icon text').toString()} size="lg" width={props.logo?.width || 100} height={props.logo?.height || 100} />
      <Heading fontSize="lg" alignSelf={'flex-start'}>
        {t('Log in')}
      </Heading>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        // validateOnBlur={false}
        // validateOnChange={false}
        onSubmit={values => {
          submitLogin(values.username, values.password);
        }}
        validationSchema={LoginSchema}>
        {({handleBlur, handleChange, handleSubmit, values, errors}) => (
          <>
            <FormControl isRequired isInvalid={'username' in errors}>
              <FormControl.Label _text={{fontSize: 'md'}}>{t('Username')}</FormControl.Label>
              <Input
                // InputLeftElement={<Icon as={FontAwesome} name="user" size={5} ml="2" color="muted.400" />}
                // placeholder={t('Email') as string}
                onBlur={handleBlur('username')}
                onChangeText={handleChange('username')}
                value={values.username}
              />
              {'username' in errors && <FormControl.ErrorMessage>{errors.username}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'password' in errors} mt={2}>
              <FormControl.Label _text={{fontSize: 'md'}}>{t('Password')}</FormControl.Label>
              <Input
                // variant="login"
                // InputLeftElement={<Icon as={FontAwesome} name="lock" size={5} ml="2" color="muted.400" />}
                // placeholder={t('Password') as string}
                onBlur={handleBlur('password')}
                onChangeText={handleChange('password')}
                value={values.password}
                type={'password'}
              />
              {'password' in errors ? (
                <FormControl.ErrorMessage>{errors.password}</FormControl.ErrorMessage>
              ) : (
                <></>
                // <FormControl.HelperText>{t('Password should contain at least 8 character.')}</FormControl.HelperText>
              )}
            </FormControl>
            {!hidePasswordRecovery && (
              <Link alignSelf={'flex-end'} isUnderlined={true} fontSize={'xl'} mt={2} onPress={() => props.onForgotPasswordClick && props.onForgotPasswordClick()}>
                {t('Forgot your Password?')}
              </Link>
            )}
            <Button variant="login" onPress={() => handleSubmit()} mt={5} size={'lg'} isLoading={isLoading} isLoadingText={t('Submitting')!}>
              {t('Log in')}
            </Button>
          </>
        )}
      </Formik>
    </VStack>
  );
};
