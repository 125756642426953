import CryptoJS from 'crypto-js';
import {ec as EC} from 'elliptic';
import {keccak256} from 'js-sha3';
import {CookiesService} from '../cookies';
import {COOKIES_TYPES} from '../../constants';
import {Buffer} from 'buffer';

const ec = new EC('secp256k1');

export const WALLET_STORAGE_KEY = 'wallet';

type Wallet = {
  address: string;
  privateKey: string;
};

export const generatePrivateKey = (): string => {
  const randomString = Math.random().toString();
  return CryptoJS.SHA256(randomString).toString();
};

export const privateKeyToPublicKey = (privKey: string): string => {
  if (privKey.startsWith('0x')) {
    privKey = privKey.slice(2);
  }
  const privKeyBuffer = Buffer.from(privKey, 'hex');
  const keyPair = ec.keyFromPrivate(privKeyBuffer);
  const pubKey = keyPair.getPublic(false, 'array');
  return Buffer.from(pubKey.slice(1)).toString('hex');
};

export const publicKeyToAddress = (pubKey: string) => {
  const pubKeyBuffer = Buffer.from(pubKey, 'hex');
  const keyHash = keccak256.array(pubKeyBuffer);
  const keyHashBuffer = Buffer.from(keyHash);
  return '0x' + keyHashBuffer.slice(-20).toString('hex');
};

export const privateKeyToAddress = (privKey: string) => publicKeyToAddress(privateKeyToPublicKey(privKey));

export function isValidAddress(address: string) {
  return /^0x[0-9a-fA-F]{40}$/.test(address);
}

export async function getWallet(): Promise<Wallet> {
  let privateKey = await CookiesService.getType(COOKIES_TYPES.TECHNICAL, WALLET_STORAGE_KEY);
  if (!privateKey) {
    privateKey = generatePrivateKey();
    await CookiesService.setType(COOKIES_TYPES.TECHNICAL, WALLET_STORAGE_KEY, privateKey);
  }
  return {privateKey, address: privateKeyToAddress(privateKey)};
}
