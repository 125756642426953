/**
 * Error Codes
 */
export enum CLIENT_ERROR_CODES {
  BASE = 301,
  VALIDATION = 301,
  CREDENTIALS = 302,
  CIRCULAR_NODE_DOWN = 303,
  NOT_FOUND = 304,
  INTERNAL = 305,
  UNEXPECTED = 306,
  OAUTH2 = 307,
  UNAUTHORIZED = 401,
  LOGOUT = 403,
  ALREADY_EXISTS = 307,
}

/**
 * Error Types
 */
export enum CLIENT_ERROR_TYPES {
  BASE = 'BASE',
  VALIDATION = 'VALIDATION',
  FIELD_ALREADY_EXISTS = 'FIELD_ALREADY_EXISTS',
  CREDENTIALS = 'CREDENTIALS',
  CIRCULAR_NODE_DOWN = 'CIRCULAR_NODE_DOWN',
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL = 'INTERNAL',
  UNEXPECTED = 'UNEXPECTED',
  OAUTH2 = 'OAUTH2',
  OAUTH2_INVALID_INPUT = 'OAUTH2_INVALID_INPUT',
  OAUTH2_INVALID_CREDENTIALS = 'OAUTH2_INVALID_CREDENTIALS',
  OAUTH2_SECURITY_ERROR = 'OAUTH2_SECURITY_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED',
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
}
