import {Button, Icon, Stack, View} from 'native-base';
import React, {FC, useState} from 'react';
import {Animated} from 'react-native';
import {FontAwesome} from '@native-base/icons';

type Props = {
  toggler: {
    label: string;
    showIcon?: JSX.Element;
    hideIcon?: JSX.Element;
  };
};

const Collapsible: FC<React.PropsWithChildren<Props>> = props => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const displayContent = useState(new Animated.Value(0))[0];

  const toggleCollapse = () => {
    const toValue = isCollapsed ? 1 : 0;

    Animated.timing(displayContent, {
      toValue,
      duration: 300,
      useNativeDriver: false,
    }).start();

    setIsCollapsed(!isCollapsed);
  };

  const contentOpacity = displayContent.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  const showIcon = props.toggler.showIcon || <Icon as={FontAwesome} name="angle-right" />;
  const hideIcon = props.toggler.hideIcon || <Icon as={FontAwesome} name="angle-down" />;

  return (
    <Stack w={'100%'} direction={'column'}>
      <View mb={5} alignSelf={'flex-start'}>
        <Button leftIcon={isCollapsed ? showIcon : hideIcon} onPress={toggleCollapse}>
          {props.toggler.label}
        </Button>
      </View>
      <Animated.View style={{opacity: contentOpacity, display: isCollapsed ? 'none' : 'flex'}}>{props.children}</Animated.View>
    </Stack>
  );
};

export default Collapsible;
