import {Heading, Link, Text, VStack} from 'native-base';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

// IMPORTANT: Canviar aquest valor quan es facin modificacions al text!
const defaultLastUpdated = new Date(2023, 7, 28);

type Props = {
  company: {
    name: string;
    id: string;
    address: string;
    contactEmail: string;
  };
  projectName: string;
  appOrUrl: string;
  lastUpdated?: Date;
  titleFontSize?: string;
};

export function Cookies(props: Props) {
  const {t} = useTranslation();

  return (
    <>
      <Text mb={2} italic>
        {t('This cookie policy was last updated on {{lastUpdated}} and applies to citizens and permanent legal residents of the European Economic Area.', {
          lastUpdated: moment(props.lastUpdated || defaultLastUpdated).format('DD/MM/YYYY'),
        })}
      </Text>

      <Heading mb={2} fontSize={props.titleFontSize || '3xl'}>
        {t('Cookie policy')}
      </Heading>
      <Text mb={2}>
        {t('{{companyName}} informs about the use of cookies in {{projectName}}.', {
          companyName: props.company.name,
          projectName: props.projectName,
        })}
      </Text>
      <Text mb={2}>
        {t(
          '{{appOrUrl}} uses cookies and other related technologies (for convenience, all technologies are called "cookies"). Cookies are also placed by third parties we have contracted. In the following document, we inform you about the use of cookies in {{projectName}}.',
          {
            appOrUrl: props.appOrUrl,
            projectName: props.projectName,
          }
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('What are cookies?')}
      </Heading>
      <Text mb={2}>
        {t(
          "Cookies are files that can be downloaded to your computer through web pages or applications. They are tools that play an essential role in the provision of numerous information society services. Among others, they allow a website to store and retrieve information about a user's browsing habits or his/her computer, and depending on the information obtained, they can be used to recognize the user and improve the service offered."
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('What are scripts?')}
      </Heading>
      <Text mb={2}>
        {t('A script is a piece of software code used to make {{appOrUrl}} work correctly and interactively. This code runs on our server or on your device.', {
          appOrUrl: props.appOrUrl,
        })}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('What is a web beacon?')}
      </Heading>
      <Text mb={2}>
        {t(
          'A web beacon (or a pixel tag) is a small, invisible piece of text or image on a site used to monitor site traffic. Therefore, various data are stored using web beacons.'
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('Types of cookies')}
      </Heading>
      <Text mb={2}>
        {t('Depending on who is the entity that manages the domain from which the cookies are sent and treats the data obtained, two types can be distinguished:')}
      </Text>
      <Text ml={5}>
        {'\u2022 '}
        {t(
          "First-party cookies: those that are sent to the user's terminal equipment from a machine or domain managed by the publisher itself and from which the service requested by the user is provided."
        )}
      </Text>
      <Text ml={5} mb={3}>
        {'\u2022 '}
        {t(
          "Third-party cookies: those that are sent to the user's terminal equipment from a machine or domain that is not managed by the publisher, but by another entity that processes the data obtained through the cookies."
        )}
      </Text>
      <Text mb={2}>
        {t(
          'In the event that cookies are installed from a machine or domain managed by the publisher itself, but the information collected through these is managed by a third party, they cannot be considered as first-party cookies.'
        )}
      </Text>
      <Text mb={2}>{t("There is also a second classification according to the period of time they remain stored in the client's browser, they may be:")}</Text>
      <Text ml={5}>
        {'\u2022 '}
        {t(
          'Session cookies: designed to collect and store data while the user accesses a web page. They are usually used to store information that is only of interest for the provision of the service requested by the user on a single occasion (e.g. a list of purchased products).'
        )}
      </Text>
      <Text ml={5} mb={3}>
        {'\u2022 '}
        {t(
          'Persistent cookies: the data remain stored in the terminal and can be accessed and processed over a period defined by the person responsible for the cookie, which can range from a few minutes to several years.'
        )}
      </Text>
      <Text mb={2}>{t('Lastly, there is another classification with five types of cookies according to the purpose for which the data obtained is processed: ')}</Text>
      <Text ml={5}>
        {'\u2022 '}
        {t(
          'Technical cookies: these allow the user to browse through a website, platform or application and use the different options or services that exist on it, such as controlling traffic and data communication, identifying the session, accessing restricted access parts, remembering the elements that make up an order, carrying out the purchase process of an order, applying for registration or participation in an event, using security elements during browsing, storing content for the dissemination of videos or sound or sharing content through social networks.'
        )}
      </Text>
      <Text ml={5}>
        {'\u2022 '}
        {t(
          "Personalization cookies: these allow the user to access the service with some predefined general characteristics based on a series of criteria in the user's terminal, such as the language, the type of browser through which the user accesses the service, the regional configuration from where the user accesses the service, etc."
        )}
      </Text>
      <Text ml={5}>
        {'\u2022 '}
        {t(
          'Analysis cookies: these allow the person responsible for them to monitor and analyze the behavior of the users of the websites to which they are linked. The information collected through this type of cookie is used in the measurement of the activity of the websites, application or platform, and for the elaboration of user browsing profiles of said sites, applications and platforms, with the aim of introducing improvements based on the analysis of the usage data made by the users of the service.'
        )}
      </Text>
      <Text ml={5}>
        {'\u2022 '}
        {t('Advertising cookies: these allow the most efficient management of the advertising spaces.')}
      </Text>
      <Text ml={5}>
        {'\u2022 '}
        {t(
          'Behavioral advertising cookies: these store information on user behavior obtained through continuous observation of their browsing habits, which allows the development of a specific profile to display advertising based on it.'
        )}
      </Text>
      <Text ml={5} mb={3}>
        {'\u2022 '}
        {t(
          'External social network cookies: these are used so that visitors can interact with the content of different social platforms (Facebook, YouTube, Twitter, LinkedIn, etc.) and are only generated for the users of these social networks.'
        )}
      </Text>
      <Text mb={2}>{t('The conditions of use of these cookies and the information collected are regulated by the privacy policy of the corresponding social platform.')}</Text>

      <Heading mt={5} mb={2} size="md">
        {t('Acceptance of the Cookie Policy')}
      </Heading>
      <Text mb={2}>{t('When you visit our website for the first time, we will show you a pop-up message with an explanation about cookies.')}</Text>
      <Text mb={2}>
        {t(
          'When you click on "Accept", you give us consent to use the categories of cookies and plugins that you have selected in the "Settings" button, as described in this Cookie Policy. You can deactivate the use of cookies through your browser, but please note that our website may not work properly.'
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('Your rights regarding personal data')}
      </Heading>
      <Text mb={2}>{t('You have the following rights regarding your personal data:')}</Text>
      <Text ml={5}>
        {'\u2022 '}
        {t('You have the right to know why your personal data is needed, what will happen to it, and how long it will be retained.')}
      </Text>
      <Text ml={5}>
        {'\u2022 '}
        {t('Right of access: You have the right to access your personal data known to us.')}
      </Text>
      <Text ml={5}>
        {'\u2022 '}
        {t('Right to rectification: You have the right to supplement, correct, delete, or block your personal data whenever you wish.')}
      </Text>
      <Text ml={5}>
        {'\u2022 '}
        {t('If you give us your consent to process your data, you have the right to revoke that consent and delete your personal data.')}
      </Text>
      <Text ml={5}>
        {'\u2022 '}
        {t('Right to transfer your data: You have the right to request all your personal data from the controller and transfer them in full to another controller.')}
      </Text>
      <Text ml={5} mb={3}>
        {'\u2022 '}
        {t('Right to object: You can object to the processing of your data. We comply with this, unless there are justified reasons for the processing.')}
      </Text>
      <Text mb={2}>
        {t(
          'To exercise these rights, contact us. Please see the contact details at the bottom of this Cookie Policy. If you have a complaint about how we manage your data, we would like to hear from you, but you also have the right to submit a complaint to the supervisory authority (the Data Protection Agency).'
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('Activation, deactivation and deletion of cookies')}
      </Heading>
      <Text mb={2}>
        {t(
          'You can use your browser to delete cookies automatically or manually. Also, you can specify that certain cookies may not be placed. Another option is to change the settings of your browser so that you receive a message each time a cookie is placed. For more information about these options, please refer to the instructions in the Help section of your browser.'
        )}
      </Text>
      <Text mb={2}>
        {t(
          'Please note that our website may not work properly if all cookies are disabled. If you delete cookies from your browser, they will be placed again after your consent when you visit our website again.'
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('Tools or Options')}
      </Heading>
      <Text mb={2}>
        {t(
          'You can also consult the Help menu of the browser where you can find instructions. The user can at any time choose which cookies he wants to work on this website.'
        )}
      </Text>
      <Text mb={2}>{t('You can allow, block or delete the cookies installed on your computer by configuring the options of the browser installed on your computer:')}</Text>
      <VStack mb={2} ml={5}>
        <Text>
          {'\u2022 '}
          {t('Microsoft Internet Explorer or Microsoft Edge:')}
        </Text>
        <Link href={t('https://windows.microsoft.com/en-us/windows-vista/Block-or-allow-cookies', {nsSeparator: false})!} isExternal _text={{wordBreak: 'break-word'} as any}>
          {t('https://windows.microsoft.com/en-us/windows-vista/Block-or-allow-cookies', {nsSeparator: false})}
        </Link>
      </VStack>
      <VStack mb={2} ml={5}>
        <Text>
          {'\u2022 '}
          {t('Mozilla Firefox:')}
        </Text>
        <Link
          href={t('https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox', {nsSeparator: false})!}
          isExternal
          _text={{wordBreak: 'break-word'} as any}>
          {t('https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox', {nsSeparator: false})}
        </Link>
      </VStack>
      <VStack mb={2} ml={5}>
        <Text>
          {'\u2022 '}
          {t('Chrome:', {nsSeparator: false})}
        </Text>
        <Link href={t('https://support.google.com/accounts/answer/61416', {nsSeparator: false})!} isExternal _text={{wordBreak: 'break-word'} as any}>
          {t('https://support.google.com/accounts/answer/61416', {nsSeparator: false})}
        </Link>
      </VStack>
      <VStack mb={2} ml={5}>
        <Text>
          {'\u2022 '}
          {t('Safari:', {nsSeparator: false})}
        </Text>
        <Link href={t('https://support.apple.com/en-gb/guide/safari/sfri11471/mac', {nsSeparator: false})!} isExternal _text={{wordBreak: 'break-word'} as any}>
          {t('https://support.apple.com/en-gb/guide/safari/sfri11471/mac', {nsSeparator: false})}
        </Link>
      </VStack>
      <VStack mb={2} ml={5}>
        <Text>
          {'\u2022 '}
          {t('Opera:', {nsSeparator: false})}
        </Text>
        <Link href={t('https://help.opera.com/en/latest/web-preferences/#cookies', {nsSeparator: false})!} isExternal _text={{wordBreak: 'break-word'} as any}>
          {t('https://help.opera.com/en/latest/web-preferences/#cookies', {nsSeparator: false})}
        </Link>
      </VStack>
      <Text mb={2}>{t('In addition, you can also manage the cookie storage in your browser through tools such as the following:')}</Text>
      <VStack mb={2} ml={5}>
        <Text>
          {'\u2022 '}
          {t('Ghostery:', {nsSeparator: false})}
        </Text>
        <Link href={t('https://ghostery.com', {nsSeparator: false})!} isExternal _text={{wordBreak: 'break-word'} as any}>
          {t('https://ghostery.com', {nsSeparator: false})}
        </Link>
      </VStack>
      <VStack mb={2} ml={5}>
        <Text>
          {'\u2022 '}
          {t('Your online choices:')}
        </Text>
        <Link href={t('https://youronlinechoices.com', {nsSeparator: false})!} isExternal _text={{wordBreak: 'break-word'} as any}>
          {t('https://youronlinechoices.com', {nsSeparator: false})}
        </Link>
      </VStack>

      <Heading mt={5} mb={2} size="md">
        {t('Used Cookies')}
      </Heading>
      <Text mb={2}>{t('Next, the cookies that are being used on this website are identified, along with their type and function:')}</Text>
      <Text mb={2}>{t('(cookie: domain // description // duration // type.)')}</Text>
      <Text mb={2}>
        {t(
          "ADVERTISING: Own and permanent cookie that stores the user's settings regarding the use of advertising cookies, as well as all the own advertised cookies used on the site."
        )}
      </Text>
      <Text mb={2}>
        {t(
          "FUNCTIONAL: Own and permanent cookie that stores the user's settings regarding the use of functional cookies, as well as all the own functional cookies used on the site."
        )}
      </Text>
      <Text mb={2}>
        {t(
          "USER_EXPERIENCE: Own and permanent cookie that stores the user's settings regarding the use of cookies to enhance the user experience, as well as all the own cookies used for improving the user experience on the site."
        )}
      </Text>
      <Text mb={2}>{t('EXPO_CONSTANTS_INSTALLATION_ID: Own and permanent cookie that stores a unique application identifier.')}</Text>
      <Text mb={2}>
        {t(
          "SOCIAL_NETWORK_AND_PROMO: Own and persistent cookie that stores the user's settings regarding the use of behavioral advertising cookies, as well as all the own behavioral advertising cookies used on the site."
        )}
      </Text>

      <Heading mt={5} mb={2} size="md">
        {t('Contact Information')}
      </Heading>
      <Text mb={2}>{t('For questions and/or comments about our cookie policy and this statement, please contact us using the following information:')}</Text>
      <Text mb={2}>{props.company.name}</Text>
      <Text mb={2}>{props.company.id}</Text>
      <Text mb={2}>{props.company.address}</Text>
      <Text mb={2}>{props.company.contactEmail}</Text>
      <Text mb={2}>
        {t(
          'Our cookie policy can be modified at any time to adapt to regulatory requirements, or changes in our activities, being the one published at any time on the {{appOrUrl}}.',
          {
            appOrUrl: props.appOrUrl,
          }
        )}
      </Text>
    </>
  );
}
