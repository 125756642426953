import {useBaseService} from '../modules';
import {AuthServiceTypes, ServiceFunctionsType} from '@bri/shared-core';

export function useAuthService() {
  const newBaseService = useBaseService();

  const authServiceFunctions: ServiceFunctionsType<AuthServiceTypes> = {
    login: params => newBaseService().post('/v1/oauth/login', params),
    token: params => newBaseService().post('/v1/oauth/token', params),
    resetPassword: params => newBaseService().post('/v1/oauth/resetPassword', params),
    changePassword: (params, token) => newBaseService().auth().setCustomToken(token).post('/v1/oauth/changePassword', params),
    me: () => newBaseService().auth().get('/v1/oauth/me'),
  };

  return authServiceFunctions;
}
