const rn = require('react-native');

let _isWebApp = true;
let _isiOSApp = false;
let _isAndroidApp = false;
if (rn) {
  const Platform = rn.Platform;
  _isWebApp = Platform.OS === 'web';
  _isiOSApp = Platform.OS === 'ios';
  _isAndroidApp = Platform.OS === 'android';
}

export const isWebApp = _isWebApp;
export const isiOSApp = _isiOSApp;
export const isAndroidApp = _isAndroidApp;
export const isApp = isiOSApp || isAndroidApp;

function convertStringToArray(input: string | string[]): string[] {
  return Array.isArray(input) ? input : [input as string];
}

export function isFilterForFields(filterFields: string | string[], fields: string | string[]): boolean {
  const fieldsArray: string[] = convertStringToArray(fields);
  filterFields = convertStringToArray(filterFields);

  if (fieldsArray.length > filterFields.length) {
    return false;
  }

  for (const field of fieldsArray) {
    if (!filterFields.includes(field)) {
      return false;
    }
  }

  return true;
}

export function encodeJSONParams(input: any): string {
  if (!input || (typeof input !== 'object' && !Array.isArray(input))) {
    return '';
  }

  if (typeof input === 'object' && !Object.keys(input).length) {
    return '';
  }

  if (Array.isArray(input) && !input.length) {
    return '';
  }

  return JSON.stringify(input);
}
