import React, {FC, useState} from 'react';
import {Button, Checkbox, FormControl, Icon, Input, Select, useContrastText, VStack} from 'native-base';
import {FontAwesome} from '@native-base/icons';
import {useAuthService, useUserService} from 'services';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {Message} from 'yup';
import {useSharedToast} from '../../components';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {loggedUser} from '../recoil';
import {useSetRecoilState} from 'recoil';

type Props = {};

type Inputs = {
  name: string;
  surname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phone: string;
  language: string;
  policy: boolean;
  terms: boolean;
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const RegisterFormUser: FC<Props> = props => {
  const {t} = useTranslation();
  const userService = useUserService();
  const authService = useAuthService();
  const setUser = useSetRecoilState(loggedUser);
  const [passwordInputType, setPasswordInputType] = useState<'password' | 'text' | undefined>('password');
  const [passwordConfirmationInputType, setPasswordConfirmationInputType] = useState<'password' | 'text' | undefined>('password');
  const [passwordIcon, setPasswordIcon] = useState('eye-slash');
  const [passwordConfirmationIcon, setPasswordConfirmationIcon] = useState('eye-slash');
  const sharedToast = useSharedToast();
  const colorContrast = useContrastText('primary.500');
  const [isLoading, setIsLoading] = useState(false);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')!),
    surname: Yup.string().required(t('Required')!),
    email: Yup.string()
      .email()
      .required(t('Required') as Message),
    password: Yup.string().min(8).required(t('Required')!),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), undefined], t('Passwords must match')!)
      .required(t('Required')!),
    phone: Yup.string().length(9).matches(phoneRegExp, t('Phone number is not valid')!).optional(),
    language: Yup.string().optional(),
    policy: Yup.boolean().oneOf([true], t('Privacy policy is required')!),
    terms: Yup.boolean().oneOf([true], t('Terms is required')!),
  });

  const {
    control,
    formState: {errors},
    handleSubmit,
    setValue,
  } = useForm<Inputs>({
    defaultValues: {policy: false, terms: false},
    resolver: yupResolver(RegisterSchema),
  });

  const submitForm: SubmitHandler<Inputs> = data => {
    console.log(data);
    // const codeVerifier = randomstring.generate();
    // const state = randomstring.generate();
    // const codeChallenge = sha256(codeVerifier);
    //
    // const user: any = {
    //   ...data,
    //   code_challenge_method: 'S256',
    //   redirect_uri: 'localhost',
    //   state,
    //   code_challenge: codeChallenge,
    // };
    // userService
    //   .register(user)
    //   .response(registerResp => {
    //     if (registerResp.authCode && registerResp.authState === state) {
    //       authService
    //         .token({
    //           grant_type: 'authorization_code',
    //           redirect_uri: 'localhost',
    //           code: registerResp.authCode,
    //           code_verifier: codeVerifier,
    //         })
    //         .response(async tokenResp => {
    //           await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'access_token', tokenResp.access_token);
    //           await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'refresh_token', tokenResp.refresh_token);
    //           setUser(registerResp.user);
    //         })
    //         .error(err => {
    //           setIsLoading(false);
    //           sharedToast({
    //             title: t('Something Went Wrong'),
    //             description: err.type as string,
    //             status: TOAST_STATUS.ERROR,
    //             variant: TOAST_VARIANT.SUBTLE,
    //           });
    //         });
    //     } else {
    //       setIsLoading(false);
    //       sharedToast({
    //         title: t('Something Went Wrong'),
    //         description: t('Server Error [OAUTH STATE]'),
    //         status: TOAST_STATUS.ERROR,
    //         variant: TOAST_VARIANT.SUBTLE,
    //       });
    //     }
    //   })
    //   .error(err => sharedToast({title: t('Something Went Wrong'), description: err.type as string, status: TOAST_STATUS.ERROR, variant: TOAST_VARIANT.SUBTLE}))
    //   .finally(() => setIsLoading(false));
  };

  const handlePassword = () => {
    if (passwordInputType === 'text') {
      setPasswordInputType('password');
      setPasswordIcon('eye');
    } else {
      setPasswordInputType('text');
      setPasswordIcon('eye-slash');
    }
  };

  const handlePasswordConfirmation = () => {
    if (passwordConfirmationInputType === 'text') {
      setPasswordConfirmationInputType('password');
      setPasswordConfirmationIcon('eye');
    } else {
      setPasswordConfirmationInputType('text');
      setPasswordConfirmationIcon('eye-slash');
    }
  };

  return (
    <VStack space={4}>
      <FormControl isRequired isInvalid={'name' in errors} key="name" flex={1}>
        <FormControl.Label _text={{bold: true}} variant="register">
          {t('Name')}
        </FormControl.Label>
        <Controller name="name" control={control} render={({field}) => <Input type="text" variant={'register'} color={colorContrast} placeholder={t('Name')!} {...field} />} />
        {errors.name && <FormControl.ErrorMessage>{errors.name?.message}</FormControl.ErrorMessage>}
      </FormControl>

      <FormControl isRequired isInvalid={'surname' in errors} key="surname" flex={1}>
        <FormControl.Label _text={{bold: true}} variant="register">
          {t('Surname')}
        </FormControl.Label>
        <Controller
          name="surname"
          control={control}
          render={({field}) => <Input type="text" variant={'register'} color={colorContrast} placeholder={t('Surname')!} {...field} />}
        />
        {errors.surname && <FormControl.ErrorMessage>{errors.surname?.message}</FormControl.ErrorMessage>}
      </FormControl>

      <FormControl isRequired isInvalid={'email' in errors} key="email" flex={1}>
        <FormControl.Label _text={{bold: true}} variant="register">
          {t('Email')}
        </FormControl.Label>
        <Controller
          name="email"
          control={control}
          render={({field}) => <Input type="text" keyboardType={'email-address'} variant={'register'} color={colorContrast} placeholder={t('Email')!} {...field} />}
        />
        {errors.email && <FormControl.ErrorMessage>{errors.email?.message}</FormControl.ErrorMessage>}
      </FormControl>

      <FormControl isRequired isInvalid={'password' in errors} key="password" flex={1}>
        <FormControl.Label _text={{bold: true}} variant="register">
          {t('Password')}
        </FormControl.Label>
        <Controller
          name="password"
          control={control}
          render={({field}) => (
            <Input
              type={passwordInputType}
              variant={'register'}
              color={colorContrast}
              InputRightElement={
                <Icon
                  as={FontAwesome}
                  name={passwordIcon}
                  size={5}
                  mr={2}
                  color="muted.50"
                  onPress={() => {
                    handlePassword();
                  }}
                />
              }
              pl={2}
              {...field}
            />
          )}
        />
        {errors.password && <FormControl.ErrorMessage>{errors.password?.message}</FormControl.ErrorMessage>}
      </FormControl>

      <FormControl isRequired isInvalid={'passwordConfirmation' in errors} key="passwordConfirmation" flex={1}>
        <FormControl.Label _text={{bold: true}} variant="register">
          {t('Repeat Password')}
        </FormControl.Label>
        <Controller
          name="passwordConfirmation"
          control={control}
          render={({field}) => (
            <Input
              type={passwordConfirmationInputType}
              variant={'register'}
              color={colorContrast}
              InputRightElement={
                <Icon
                  as={FontAwesome}
                  name={passwordConfirmationIcon}
                  size={5}
                  mr={2}
                  color="muted.50"
                  onPress={() => {
                    handlePasswordConfirmation();
                  }}
                />
              }
              {...field}
            />
          )}
        />
        {errors.passwordConfirmation && <FormControl.ErrorMessage>{errors.passwordConfirmation.message}</FormControl.ErrorMessage>}
      </FormControl>

      <FormControl isInvalid={'phone' in errors} key="phone" flex={1}>
        <FormControl.Label _text={{bold: true}} variant="register">
          {t('Phone')}
        </FormControl.Label>
        <Controller
          name="phone"
          control={control}
          render={({field}) => <Input type="text" keyboardType={'phone-pad'} variant={'register'} color={colorContrast} placeholder={t('Phone')!} {...field} />}
        />
        {errors.phone && <FormControl.ErrorMessage>{errors.phone?.message}</FormControl.ErrorMessage>}
      </FormControl>

      <FormControl isInvalid={'language' in errors} key="language" flex={1}>
        <FormControl.Label _text={{bold: true}} variant="register">
          {t('Language')}
        </FormControl.Label>
        <Controller
          name="language"
          control={control}
          render={({field}) => (
            <Select color={colorContrast} {...(field as any)} onValueChange={itemValue => setValue('language', itemValue)}>
              {[{title: t('English'), value: 'en'}].map(item => (
                <Select.Item key={item.value} label={item.title} value={item.value} variant={'register'} />
              ))}
            </Select>
          )}
        />
        {errors.language && <FormControl.ErrorMessage>{errors.language.message}</FormControl.ErrorMessage>}
      </FormControl>

      <VStack px={5} space={4} mt={4}>
        <FormControl isRequired isInvalid={'policy' in errors} key="policy">
          <Controller
            name="policy"
            control={control}
            render={({field}) => (
              <Checkbox
                {...(field as any)}
                bgColor={'primary.500'}
                borderColor={'secondary.500'}
                _text={{color: colorContrast, bold: false, fontSize: 14, fontWeight: 'light'}}
                size={'sm'}
                _checked={{bgColor: 'secondary.500'}}
                _icon={{color: 'primary.500'}}>
                {t('I have read and accept the privacy policy.')}
              </Checkbox>
            )}
          />
          {errors.policy && <FormControl.ErrorMessage>{errors.policy.message}</FormControl.ErrorMessage>}
        </FormControl>

        <FormControl isRequired isInvalid={'terms' in errors} key="terms">
          <Controller
            name="terms"
            control={control}
            render={({field}) => (
              <Checkbox
                {...(field as any)}
                bgColor={'primary.500'}
                borderColor={'secondary.500'}
                _text={{color: colorContrast, bold: false, fontSize: 14, fontWeight: 'light'}}
                size={'sm'}
                _checked={{bgColor: 'secondary.500'}}
                _icon={{color: 'primary.500'}}>
                {t('I have read and accept the terms of use.')}
              </Checkbox>
            )}
          />
          {errors.terms && <FormControl.ErrorMessage>{errors.terms.message}</FormControl.ErrorMessage>}
        </FormControl>
      </VStack>

      <Button
        onPress={handleSubmit(submitForm)}
        w="fit-content"
        shadow={'2'}
        style={{marginTop: 20}}
        alignSelf={'center'}
        px={8}
        bgColor={'secondary.500'}
        _text={{color: 'primary.500'}}
        isLoading={isLoading}
        isLoadingText={t('Submitting')!}>
        {t('Register')}
      </Button>
    </VStack>
  );
};
