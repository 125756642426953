import React, {FC} from 'react';
import {Button, Heading, Icon, Input, VStack} from 'native-base';
import {FontAwesome} from '@native-base/icons';

export type ComponentWithCustomViewViewProps = Omit<ComponentWithCustomViewProps, 'render'> & {
  handleLogin: () => void;
};

export const ComponentWithCustomViewView: FC<ComponentWithCustomViewViewProps> = props => {
  return (
    <VStack space={4} alignItems="center">
      <Heading size="lg">Login</Heading>
      <Input InputLeftElement={<Icon as={FontAwesome} name="user" size={5} ml="2" color="muted.400" />} placeholder="Email" />
      <Input InputLeftElement={<Icon as={FontAwesome} name="lock" size={5} ml="2" color="muted.400" />} placeholder="Password" />
      <Button colorScheme={props.color} onPress={props.handleLogin}>
        Login
      </Button>
    </VStack>
  );
};

export type ComponentWithCustomViewProps = {
  render?: FC<ComponentWithCustomViewViewProps>;
  color: 'primary' | 'secondary';
};

export const ComponentWithCustomView: FC<ComponentWithCustomViewProps> = props => {
  const handleLogin = () => {
    console.log('handle login');
  };

  const Render = props.render || ComponentWithCustomViewView;

  return <Render handleLogin={handleLogin} {...{...props, render: undefined}} />;
};
