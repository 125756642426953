import {useBaseService} from '../modules';
import {NotificationsServiceTypes, ServiceFunctionsType} from '@bri/shared-core';

export function useNotificationService() {
  const newBaseService = useBaseService();

  const notificationsServiceFunction: ServiceFunctionsType<NotificationsServiceTypes> = {
    get: params => newBaseService().auth().get('/v1/notifications/get', params),
    list: params => newBaseService().auth().get('/v1/notifications/list', params),
    read: params => newBaseService().auth().put('/v1/notifications/read', params),
    registerToken: params => newBaseService().auth().post('/v1/notifications/registerToken', params),
    removeToken: params => newBaseService().auth().post('/v1/notifications/removeToken', params),
    removeAllTokens: () => newBaseService().auth().post('/v1/notifications/removeAllTokens'),
  };

  return notificationsServiceFunction;
}
