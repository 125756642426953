import {PermissionRulesType} from '@bri/shared-core';
import {USER_ROLE} from '../../users/models';
import {USER_ACTIONS} from './endpoints';

export const PermissionRules: PermissionRulesType<USER_ROLE, USER_ACTIONS> = {
  /**
   * No role
   */
  [USER_ROLE.NO]: {
    actions: [
      USER_ACTIONS.OAUTH,
      USER_ACTIONS.GROUPS_CRUDL,
      USER_ACTIONS.PRICES_CRUDL,
      USER_ACTIONS.CHALLENGES_CRUDL,
      USER_ACTIONS.ACCESS_CODE_CRUDL,
      USER_ACTIONS.RIDES_CRUDL,
    ],
  },

  /**
   * User Role
   */
  [USER_ROLE.USER]: {
    actions: [USER_ACTIONS.USE_APP, USER_ACTIONS.PRODUCT_CRUDL, USER_ACTIONS.NOTIFICATIONS],
  },

  /**
   * Admin Role
   */
  [USER_ROLE.ADMIN]: {
    actions: [USER_ACTIONS.USE_APP_ADMIN, USER_ACTIONS.USER_CRUDL, USER_ACTIONS.PRODUCT_CRUDL],
  },
};
