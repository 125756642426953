import React, {FC, useEffect, useReducer, useState} from 'react';
import {VStack} from 'native-base';
import {useUserService} from 'services';
import {useSetRecoilState} from 'recoil';
import {loggedUser} from '../recoil';
import {useTranslation} from 'react-i18next';
import {CookiesService} from '../cookies';
import {COOKIES_TYPES} from '../../constants';
import {IUserBase} from '@bri/shared-core';
import {CustomDataTable, DataRowRequiredData} from '../../components';
import {TableColumn, TableStyles} from 'react-data-table-component';

type Props = {
  editHandler?: (id: string) => void;
  columns?: {label: string; field: string}[];
};

type DataRow = DataRowRequiredData & IUserBase;

export const ListUsers: FC<Props> = props => {
  const {t} = useTranslation();
  const userService = useUserService();
  const [data, setData] = useState<DataRow[]>([]);

  const setUser = useSetRecoilState(loggedUser);
  const [loading, setLoading] = useState(true);
  const [dataForce, forceUpdate] = useReducer(x => x + 1, 0);

  async function fetchData() {
    if (await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, 'access_token')) {
      userService
        .list()
        .apiResponse(() => {
          setLoading(false);
        })
        .response(resp => {
          setData(resp.users as any);
        })
        .error(err => console.log(err));
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData().catch(console.error);
  }, [dataForce]);

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  const customStyles: TableStyles = {
    tableWrapper: {
      style: {
        padding: 4,
      },
    },
    table: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
    header: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
    pagination: {
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      },
    },
  };

  let columns: TableColumn<DataRow>[] = [
    {
      name: '_id',
      selector: (row: DataRow) => row._id,
    },
    {
      name: 'Username',
      selector: (row: DataRow) => row.local.username,
    },
    {
      name: 'Role',
      selector: (row: DataRow) => row.role,
    },
  ];
  if (props.columns) {
    columns = props.columns.map(column => ({
      name: column.label,
      selector: (row: DataRow) => {
        if (column.field.indexOf('.') > -1) {
          return column.field.split('.').reduce((obj: any, key) => obj[key], row);
        } else {
          return row[column.field];
        }
      },
    }));
  }

  return (
    <VStack space={4} alignItems="center">
      <CustomDataTable
        // title={t('List Users') as string}
        data={data}
        columns={columns}
        export
        pagination
        style={{marginTop: 100}}
        defaultActions
        showExtensions={false}
        customStyles={customStyles}
        editHandler={props.editHandler}
        deleteHandler={forceUpdate}
      />
    </VStack>
  );
};
