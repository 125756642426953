import {atom} from 'recoil';

export type WebServiceRecoilState<T = any> = {
  loading: boolean;
  refreshing: boolean;
  data: null | T;
};

export const webServiceAtom = <T = any>(key: string) =>
  atom<WebServiceRecoilState<T>>({
    key,
    default: {
      loading: true,
      refreshing: false,
      data: null,
    },
  });
